<template>
  <RenterDashboardTopNav />

  <div class="renter-dashboard notifications-dashboard">
    <div class="main-dashboard container flex-container">
      <RenterDashboardSidebar />

      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-2">Notifications</h1>
        </header>

        <div class="cards-container flex-container">
          <div v-for="(item, index) in list" :key="index" class="card notification">
            <div class="notification-container">
              <div class="notification-content">
                <img :src="item.icon" alt="happipad" />
                <p>{{ item.text }}</p>
              </div>

              <div v-if="item.unread" class="notifications-available">
                <span class="notification-dot"></span>
              </div>
            </div>

            <div class="time">
              <p>{{ item.time }}</p>
              <img v-if="item.seen" src="@/assets/svgs/messages-double-checkmark.svg" alt="checkmark" class="icon" />
            </div>
          </div>
        </div>
      </div>

      <RenterDashboardBottomNav />
    </div>
  </div>
</template>

<script>
import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

import { useDashboardNotifications } from "@/stores/shared/useDashboardNotifications";
import { mapState } from "pinia";

export default {
  name: "RenterNotifications",
  components: {
    RenterDashboardSidebar,
    RenterDashboardBottomNav,
    RenterDashboardTopNav,
  },
  computed: {
    ...mapState(useDashboardNotifications, ['list'])
  },
};
</script>
