<template>
 <div class="dashboard-search">
   <div class="flex-container hidden-mobile">
    <div class="search-bar-container">
      <!-- Search Bar -->
      <div class="search-bar">
          <div class="search-bar-field">
            <label for="location"><strong>Location</strong></label>
            <input type="text" id="location" placeholder="Ottawa, ON">
          </div>

          <button class="search-button">
              <img src="@/assets/svgs/search-white.svg" alt="Search">
          </button>
      </div>

      <div class="filters-container">
        <!-- Filters Section -->
        <div class="filters">
            <button class="filter-button">
                <img src="@/assets/svgs/filter.svg" alt="More Filters">
                All filters
                <span class="dropdown-icon"><img src="@/assets/svgs/chevron-down-dark.svg" alt="dropdown"></span>
            </button>

            <button class="filter-button">
                Price
                <span class="dropdown-icon"><img src="@/assets/svgs/chevron-down-dark.svg" alt="dropdown"></span>
            </button>

            <button class="filter-button">
                Gender
                <span class="dropdown-icon"><img src="@/assets/svgs/chevron-down-dark.svg" alt="dropdown"></span>
            </button>

            <button class="filter-button">
              Renter’s type
                <span class="dropdown-icon"><img src="@/assets/svgs/chevron-down-dark.svg" alt="dropdown"></span>
            </button>
        </div>

        <div class="filters-bubbles">
          <div class="filter-bubble">
            Applied (N)

            <button>
              <img src="@/assets/svgs/close-light-blue.svg" alt="close" class="close-btn">
            </button>
          </div>

          <div class="filter-bubble">
            Applied (N)

            <button>
              <img src="@/assets/svgs/close-light-blue.svg" alt="close" class="close-btn">
            </button>
          </div>

          <div class="filter-bubble">
            Applied (N)

            <button>
              <img src="@/assets/svgs/close-light-blue.svg" alt="close" class="close-btn">
            </button>
          </div>
        </div>
      </div>

      <!-- Price Dropdown -->
      <div class="modal-overlay filters-dropdown-modal">
        <div class="modal-container">
          <div class="modal-header">
            <p>Price</p>
            <button class="close-btn">
              <img src="@/assets/svgs/close-big.svg" alt="close" />
            </button>
          </div>

          <!-- Modal content -->
          <div class="modal-content-container">
            <p>Price range per month</p>

            <!-- range slider here. My understanding is that we will use a third party tool -->

            <div class="range-inputs">
              <div class="range-input form-group">
                <label for="min-value">From</label>
                <div class="input-box">
                  <input
                    type="text"
                    id="min-value"
                    name="min-value"
                    placeholder="500"
                  />
                  <span class="separator"></span>
                  <span class="currency">$</span>
                </div>
              </div>

              <div class="range-input">
                <label for="max-value">To</label>
                <div class="input-box">
                  <input
                    type="text"
                    id="max-value"
                    name="max-value"
                    placeholder="5500"
                  />
                  <span class="separator"></span>
                  <span class="currency">$</span>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-content-container__btns mt-3">
              <button class="primary-btn mt-3">Show 45 listings</button>
            </div>
        </div>
      </div>

      <!-- More filters Dropdown -->
      <div class="modal-overlay filters-dropdown-modal">
          <div class="modal-container">
            <div class="modal-header">
              <p>More filters</p>
              <button class="close-btn">
                <img src="@/assets/svgs/close-big.svg" alt="close" />
              </button>
            </div>

            <!-- Modal content -->
            <div class="modal-content-container">
              <p>More filters</p>

              <form action="">
                <div class="form-group">
                  <div class="custom-checkbox-container" role="group">
                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="non-smoker"
                        id="non-smoker"
                      />
                      <label for="non-smoker">

                        <div class="label-content">Non-smoker</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="cannabis-friendly"
                        id="cannabis-friendly"
                      />
                      <label for="cannabis-friendly">

                        <div class="label-content">Cannabis friendly</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="pet-friendly"
                        id="pet-friendly"
                      />
                      <label for="pet-friendly">

                        <div class="label-content">Pet friendly</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="pet-free"
                        id="pet-free"
                      />
                      <label for="pet-free">

                        <div class="label-content">Pet free</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="lgbt-friendly"
                        id="lgbt-friendly"
                      />
                      <label for="lgbt-friendly">

                        <div class="label-content">LGBT friendly</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="student"
                        id="student"
                      />
                      <label for="student">

                        <div class="label-content">Student</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="senior"
                        id="senior"
                      />
                      <label for="senior">

                        <div class="label-content">Senior</div>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="modal-content-container__btns">
              <button class="primary-btn mt-3">Show 45 listings</button>
            </div>
          </div>
      </div>

      <!-- Gender filters Dropdown -->
      <div class="modal-overlay filters-dropdown-modal">
          <div class="modal-container">
            <div class="modal-header">
              <p>Gender</p>
              <button class="close-btn">
                <img src="@/assets/svgs/close-big.svg" alt="close" />
              </button>
            </div>

            <!-- Modal content -->
            <div class="modal-content-container">
              <p>Gender</p>

              <form action="">
                <div class="form-group">
                  <div class="custom-checkbox-container" role="group">
                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="female"
                        id="female"
                      />
                      <label for="female">

                        <div class="label-content">Female</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="male"
                        id="male"
                      />
                      <label for="male">

                        <div class="label-content">Male</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="non-binary"
                        id="non-binary"
                      />
                      <label for="non-binary">

                        <div class="label-content">Non-binary</div>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="modal-content-container__btns">
              <button class="primary-btn mt-3">Show 45 listings</button>
            </div>
          </div>
      </div>

      <!-- Renter's type filters Dropdown -->
      <div class="modal-overlay filters-dropdown-modal">
          <div class="modal-container">
            <div class="modal-header">
              <p>Renter’s type</p>
              <button class="close-btn">
                <img src="@/assets/svgs/close-big.svg" alt="close" />
              </button>
            </div>

            <!-- Modal content -->
            <div class="modal-content-container">
              <p>Renter’s type</p>

              <form action="">
                <div class="form-group">
                  <div class="custom-checkbox-container" role="group">
                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="student"
                        id="student"
                      />
                      <label for="student">

                        <div class="label-content">Students</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="senior"
                        id="senior"
                      />
                      <label for="senior">

                        <div class="label-content">Senior</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="refugees"
                        id="refugees"
                      />
                      <label for="refugees">

                        <div class="label-content">Refugees</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="people-with-disabilities"
                        id="people-with-disabilities"
                      />
                      <label for="people-with-disabilities">

                        <div class="label-content">People with disabilities</div>
                      </label>
                    </div>

                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        value="working-pro"
                        id="working-pro"
                      />
                      <label for="working-pro">

                        <div class="label-content">Working professionals</div>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="modal-content-container__btns">
              <button class="primary-btn mt-3">Show 45 listings</button>
            </div>
          </div>
      </div>
    </div>
   </div>

   <div class="cards-container search-bar-container hidden-desktop">
    <div class="card">
      <!-- Search Bar -->
      <div class="search-bar">
        <div class="search-bar-field">
          <label for="location"><img src="@/assets/svgs/address-outline.svg" alt="location"></label>
          <input type="text" id="location" placeholder="Ottawa, ON">
        </div>

        <button class="primary-btn">
            <img src="@/assets/svgs/search-white.svg" alt="Search">
            Search
        </button>
      </div>

      <!-- Filters Section -->
      <button class="filter-button tertiary-btn">
        <img src="@/assets/svgs/filter.svg" alt="More Filters">
        All filters
      </button>
    </div>
   </div>

    <!-- mobile filters Dropdown -->
    <div class="modal-overlay filters-dropdown-modal hidden-desktop">
      <div class="modal-container">
        <div class="modal-header">
          <a href="#">Clear all</a>

          <p>Search</p>

          <button class="close-btn">
            <img src="@/assets/svgs/close-big.svg" alt="close" />
          </button>
        </div>

        <!-- Modal content -->
        <div class="modal-content-container">
          <div class="cards-container search-bar-container">
            <div class="card">
              <!-- Search Bar -->
              <div class="search-bar">
                <p>Where</p>
                <div class="search-bar-field">
                  <label for="location"><img src="@/assets/svgs/address-outline.svg" alt="location"></label>
                  <input type="text" id="location" placeholder="Ottawa, ON">
                </div>

                <button class="primary-btn">
                    <img src="@/assets/svgs/search-white.svg" alt="Search">
                    Search
                </button>
              </div>
            </div>

            <!-- Filters Section -->
            <div class="card mobile-filters">
              <div class="flex-container">
                <p>Filters</p>
                <img src="@/assets/svgs/plus.svg" alt="Add icon">
              </div>

              <div class="card">
                <form action="">
                  <p>Renter's Type</p>

                  <div class="form-group">
                    <div class="custom-checkbox-container" role="group">
                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="student"
                          id="student"
                        />
                        <label for="student">

                          <div class="label-content">Students</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="senior"
                          id="senior"
                        />
                        <label for="senior">

                          <div class="label-content">Senior</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="refugees"
                          id="refugees"
                        />
                        <label for="refugees">

                          <div class="label-content">Refugees</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="people-with-disabilities"
                          id="people-with-disabilities"
                        />
                        <label for="people-with-disabilities">

                          <div class="label-content">People with disabilities</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="working-pro"
                          id="working-pro"
                        />
                        <label for="working-pro">

                          <div class="label-content">Working professionals</div>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="card">
                <form action="">
                  <p>More filters</p>

                  <div class="form-group">
                    <div class="custom-checkbox-container" role="group">
                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="non-smoker"
                          id="non-smoker"
                        />
                        <label for="non-smoker">

                          <div class="label-content">Non-smoker</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="cannabis-friendly"
                          id="cannabis-friendly"
                        />
                        <label for="cannabis-friendly">

                          <div class="label-content">Cannabis friendly</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="pet-friendly"
                          id="pet-friendly"
                        />
                        <label for="pet-friendly">

                          <div class="label-content">Pet friendly</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="pet-free"
                          id="pet-free"
                        />
                        <label for="pet-free">

                          <div class="label-content">Pet free</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="lgbt-friendly"
                          id="lgbt-friendly"
                        />
                        <label for="lgbt-friendly">

                          <div class="label-content">LGBT friendly</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="student"
                          id="more-filter-student"
                        />
                        <label for="more-filter-student">

                          <div class="label-content">Student</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="senior"
                          id="more-filter-senior"
                        />
                        <label for="more-filter-senior">

                          <div class="label-content">Senior</div>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="card">
                <form action="">
                  <p>Gender</p>

                  <div class="form-group">
                    <div class="custom-checkbox-container" role="group">
                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="female"
                          id="female"
                        />
                        <label for="female">

                          <div class="label-content">Female</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="male"
                          id="male"
                        />
                        <label for="male">

                          <div class="label-content">Male</div>
                        </label>
                      </div>

                      <div class="checkbox-container">
                        <input
                          type="checkbox"
                          value="non-binary"
                          id="non-binary"
                        />
                        <label for="non-binary">

                          <div class="label-content">Non-binary</div>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-content-container__btns">
          <button class="primary-btn mt-3">Show 45 listings</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 export default {
   name: "FindRenterSearch",

   components: {
   },
 };
</script>
