<template>
 <RenterDashboardTopNav />

 <div class="renter-dashboard notification-dashboard">
   <div class="main-dashboard container flex-container">
     <RenterDashboardSidebar />

     <div class="dashboard-content">
       <header class="mb-2">
         <h1 class="heading-2">Notifications control</h1>
       </header>

       <div class="cards-container flex-container">
        <div class="card">
            <h2 class="heading-6">Preferred contact method</h2>
            <p>Check how you want  to be in touch with our customer support.</p>

            <form action="">
              <div class="form-group">
                <div class="form-group default-checkbox mt-4" tabindex="0">
                  <input
                      v-model="formData.contact.email"
                      type="checkbox"
                      id="email"
                      name="email"
                  />
                  <label for="email">
                    Email
                  </label>
                </div>

                <div class="form-group default-checkbox" tabindex="0">
                  <input
                      v-model="formData.contact.phone"
                      type="checkbox"
                      id="phone"
                      name="phone"
                  />
                  <label for="phone">
                    Phone
                  </label>
                </div>
              </div>
            </form>
        </div>

        <div class="card">
          <h2 class="heading-6">Notifications preferences</h2>
          <p>Check how you want to receive notifications related to your search.</p>

          <form action="">
            <div class="form-group">
              <div class="form-group default-checkbox" tabindex="0">
                <input
                    v-model="formData.notifications.phone"
                    type="checkbox"
                    id="phone-notification"
                    name="phone-notification"
                />
                <label for="phone-notification">
                  Phone notifications
                </label>
              </div>

              <div class="form-group default-checkbox" tabindex="0">
                <input
                    v-model="formData.notifications.whatsapp"
                    type="checkbox"
                    id="whatsapp"
                    name="whatsapp"
                />
                <label for="whatsapp">
                  WhatsApp
                </label>
              </div>

              <div class="form-group default-checkbox" tabindex="0">
                <input
                    v-model="formData.notifications.email"
                    type="checkbox"
                    id="email"
                    name="email"
                />
                <label for="email">
                  Email
                </label>
              </div>
            </div>
          </form>

          <div class="info-note mt-3 mb-2">
            <img src="@/assets/svgs/info.svg" alt="info" />
            <p>Email notifications are sent by default. To opt out, contact support.</p>
          </div>
        </div>

        <div class="card">
          <h2 class="heading-6">Marketing emails</h2>
          <p>Check how you want to receive marketing emails from us. </p>

          <form action="">
            <div class="form-group">
              <div class="form-group default-checkbox mt-4" tabindex="0">
                <input
                    v-model="formData.marketing.email"
                    type="checkbox"
                    id="email"
                    name="email"
                />
                <label for="email">
                  Email
                </label>
              </div>
            </div>
          </form>
        </div>
       </div>
     </div>

     <RenterDashboardBottomNav />
   </div>
 </div>
</template>

<script>
import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";
import { useDashboardNotificationControl } from "@/stores/shared/useDashboardNotificationControl";
import { mapState } from "pinia";

export default {
  name: "RenterNotificationControl",

  components: {
    RenterDashboardSidebar,
    RenterDashboardBottomNav,
    RenterDashboardTopNav
  },

  computed: {
    ...mapState(useDashboardNotificationControl, ['formData'])
  },
};
</script>