<template>
  <HostDashboardTopNav />

  <div class="renter-dashboard settings-dashboard">
    <div class="main-dashboard container flex-container">
      <HostDashboardSidebar />

      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-2">Settings</h1>
        </header>

        <div class="cards-container flex-container">
          <router-link to="/renter/security" class="card">
            <div class="card-left-col">
              <img src="@/assets/svgs/security.svg" alt="security" />

              <div class="card-content">
                <p><strong>Security</strong></p>
                <p class="mt-2">Set your password, delete your account.</p>
              </div>
            </div>

            <div class="card-right-col">
              <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" />
            </div>
          </router-link>

          <router-link to="/host/dashboard/notification-control" class="card">
            <div class="card-left-col">
              <img src="@/assets/svgs/notifications-control.svg" alt="notifications" />

              <div class="card-content">
                <p><strong>Notifications control</strong></p>
                <p class="mt-2">Select what applies.</p>
              </div>
            </div>

            <div class="card-right-col">
              <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" />
            </div>
          </router-link>
        </div>
      </div>

      <HostDashboardBottomNav />
    </div>
  </div>
</template>


<script>
import HostDashboardSidebar from "@/components/HostDashboardSidebar.vue";
import HostDashboardBottomNav from "@/components/HostDashboardBottomNav.vue";
import HostDashboardTopNav from "@/components/HostDashboardTopNav.vue";

export default {
  name: "RenterSettings",

  components: {
    HostDashboardSidebar,
    HostDashboardBottomNav,
    HostDashboardTopNav
  },
};
</script>