<template>
<HostDashboardTopNav />

<div class="host-dashboard">
  <div class="container flex-container">
    <HostDashboardSidebar />

    <div class="dashboard-content">
      <header class="mb-4">
        <h1 class="heading-2">Listings</h1>

        <router-link class="primary-btn" to="/host/listing/create">Create new listing</router-link>
      </header>

      <div class="cards-container">
        <div v-if="listings_list.length > 0">
          <router-link v-for="(item, index) in listings_list" :key="item.listing_id" :to="`/host/listing/properties/${item.listing_id}/edit`">
            <div class="listing-card" role="article" aria-labelledby="room-title-1">
              <!-- Listing title -->
              <header>
                <h2 class="heading-4 hidden-mobile" id="room-title-1">Room {{ index + 1 }}</h2>
              </header>
              <div class="listing-content">
                <!-- Listing image -->
                <img :src="item.thumbnail" alt="">
                <!-- Listing details -->
                <div class="listing-details">
                  <p class="listing-title"><strong>{{ item.title }}</strong></p>
                  <div class="listing-features">
                    <ul>
                      <li>
                        <img :src="getAccommodationIcon(item.accommodation_type)" :alt="capitalize(item.accommodation_type)">
                        {{ capitalize(item.accommodation_type) }}
                      </li>
                      <li>
                        <img :src="getSharedWithIcon(item.shared_with)" :alt="capitalize(item.shared_with)">
                        {{ capitalize(item.shared_with) }}
                      </li>
                    </ul>
                    <ul class="hidden-mobile">
                      <li>
                        <img :src="getAsset('bath-tub.svg')" alt="bathtub">
                        {{ capitalize(item.bath_type) }} bath
                      </li>
                      <li v-if="item.pet_friendly">
                        <img :src="getAsset('pet.svg')" alt="pet">
                        Animal friendly
                      </li>
                    </ul>
                  </div>
                  <p class="hidden-mobile">{{ item.details }}</p>
                  <!-- Listing status & price -->
                  <div class="listing-status-price">
                    <span class="status-badge" :class="getStatusClass(item.status)" :aria-label="`Listing status: ${capitalize(item.status)}`" role="status">{{ capitalize(item.status) }}</span>
                    <p class="listing-price">
                      <strong>${{ item.price }}</strong>
                      <span>/m</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div v-if="listings_list.length === 0" class="listing-card listing-card--empty">
          <p>Hey {{ host_name }}! No rooms yet</p>

          <img :src="getAsset('no-listings.svg')" alt="">

          <router-link to="/host/listing/create" class="primary-btn">
            <img :src="getAsset('building.svg')" alt="building">
            Create new listing
          </router-link>
        </div>
      </div>

      <HostDashboardBottomNav />
    </div>
  </div>
</div>
</template>

<script>

import HostDashboardTopNav from "@/components/HostDashboardTopNav.vue";
import HostDashboardSidebar from "@/components/HostDashboardSidebar.vue";
import HostDashboardBottomNav from "@/components/HostDashboardBottomNav.vue";
import { useHostListings } from "@/stores/host/dashboard/useHostListings";
import { mapState } from "pinia";

export default {
  name: "HostListings",

  components: {
    HostDashboardTopNav,
    HostDashboardSidebar,
    HostDashboardBottomNav
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState(useHostListings, ['listings_list', 'host_name'])
  },
  methods: {
    getAsset(filename) {
      return require(`@/assets/svgs/${filename}`);
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    getStatusClass(status) {
      const classNames = {
        listed: 'status-badge--green',
        draft: 'status-badge--red',
      };

      return classNames[status];
    },

    getAccommodationIcon(type) {
      const images = {
        house: require('@/assets/svgs/house.svg'),
      };

      return images[type];
    },
    getSharedWithIcon(type) {
      const images = {
        couple: require('@/assets/svgs/couple.svg'),
      };

      return images[type];
    },
  }
};
</script>