import { defineStore } from 'pinia';

export const useHostInvoices = defineStore('invoices', {
    state: () => ({
        show_full_list: false,
        select_all: false,
        host_name: "Jay",
        new_count: 1,
        invoice_list: [
            {
                invoice_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                date: "2021-04-23",
                payment: 1300,
                purpose: "rent",
                status: "paid",
                selected: false
            },
            {
                invoice_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                date: "2021-04-23",
                payment: 1300,
                purpose: "rent",
                status: "unpaid",
                selected: false
            },
            {
                invoice_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                date: "2021-04-23",
                payment: 1300,
                purpose: "rent",
                status: "cancelled",
                selected: false
            },
            {
                invoice_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                date: "2021-04-23",
                payment: 1300,
                purpose: "rent",
                status: "paid",
                selected: false
            },
            {
                invoice_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                date: "2021-04-23",
                payment: 1300,
                purpose: "rent",
                status: "paid",
                selected: false
            },
            {
                invoice_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                date: "2021-04-23",
                payment: 1300,
                purpose: "rent",
                status: "paid",
                selected: false
            },
            {
                invoice_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                date: "2021-04-23",
                payment: 1300,
                purpose: "rent",
                status: "paid",
                selected: false
            },
            {
                invoice_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                date: "2021-04-23",
                payment: 1300,
                purpose: "rent",
                status: "paid",
                selected: false
            },
            {
                invoice_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                date: "2021-04-23",
                payment: 1300,
                purpose: "rent",
                status: "paid",
                selected: false
            },
            {
                invoice_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                date: "2021-04-23",
                payment: 1300,
                purpose: "rent",
                status: "paid",
                selected: false
            },

        ]
    }),
    getters: {
        getinvoices() {
            const sortedList = this.invoice_list.sort((a, b) => {
                return (a.date > b.date) ? -1 : ((a.date < b.date) ? 1 : 0);
            });

            if (this.show_full_list) return sortedList;

            const count = sortedList.length;
            const max = count > 8 ? 8 : count;

            return sortedList.slice(0, max);
        },
        getMoreCount() {
            if (this.show_full_list) return 0;

            return this.invoice_list.length - this.getinvoices.length;
        },
    },
    actions: {
        toggleShowFullList() {
            this.show_full_list = !this.show_full_list;
        },
        toggleSelectAll() {
            this.select_all = !this.select_all;

            this.invoice_list.forEach((recept) => {
                recept.selected = this.select_all;
            });
        },
        toggleIndividualSelect() {
            if (this.select_all) this.select_all = !this.select_all;
        }
    },
});