<template>
  <div class="renter-verification-container renter-verification-payment-step-4-details">
    <div class="breadcrumbs">
      <a href="javascript:void(0)" @click="backClicked">Back</a>
      <img src="@/assets/svgs/chevron-dark-right.svg" alt="chevron">
      <p>Payment set up</p>
    </div>

    <div class="container flex-container">
      <div class="verification-container mb-4">
        <div class="payment-card">
          <h1 class="heading-2">Payment set up.</h1>
          <p>Pay one-time verification fee.</p>
        </div>

        <div class="verification-content">
          <div class="verification-steps">
            <p><strong>Verification includes:</strong></p>

            <hr>

            <div v-for="(item, index) in line_items" :key="index" class="steps-details">
              <div class="step-info">
                <p><strong>{{ item.title }}</strong></p>
                <p>{{ item.description }}</p>
              </div>

              <p><strong>${{ financial(item.amount) }}</strong></p>
            </div>
          </div>
        </div>

        <div class="payment-btn-container hidden-desktop">
          <a class="payment-btn mt-3" href="javascript:void(0)" @click="nextStep">
            <button type="button" class="primary-btn">Continue to payment</button>
          </a>

          <p class="text-align-center">Happipad <a href="https://happipad.com/privacy/" target="_blank">Privacy&nbsp;Policy</a> and <a href="https://happipad.com/termsofuse/" target="_blank">Terms of use</a> apply.</p>
        </div>
      </div>

      <div class="payment-summary">
        <div class="payment-summary-container">
          <h3 class="summary-title heading-6">
            Summary

            <svg class="hidden-desktop" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C-0.0976311 0.683417 -0.0976312 1.31658 0.292893 1.70711L6.29289 7.70711C6.68342 8.09763 7.31658 8.09763 7.70711 7.70711L13.7071 1.70711C14.0976 1.31658 14.0976 0.683418 13.7071 0.292894C13.3166 -0.0976305 12.6834 -0.0976305 12.2929 0.292894L7 5.58579L1.70711 0.292893C1.31658 -0.097631 0.683418 -0.0976311 0.292893 0.292893Z" fill="white" />
            </svg>
          </h3>

          <div class="summary-content">
            <div class="summary-item mt-3">
              <span class="summary-label">Subtotal</span>
              <span class="summary-value">${{ financial(getSubTotal) }}</span>
            </div>

            <div class="summary-item">
              <span class="summary-label">Taxes
              </span>

              <span class="summary-value">${{ financial(getTaxTotal) }}</span>
            </div>

            <div class="summary-total mb-2">
              <span class="total-label">Total</span>
              <span class="total-value"><strong>${{ financial(getGrandTotal) }}</strong></span>
            </div>

            <div class="credit-cards-imgs mt-2">
              <img src="@/assets/images/masterCard.png" alt="Mastercard">
              <img src="@/assets/images/maestro.png" alt="maestro">
              <img src="@/assets/images/visa.png" alt="Interac">
              <img src="@/assets/images/americanExpress.png" alt="American Express">
              <img src="@/assets/images/discover.png" alt="discover">
              <img src="@/assets/images/direct-dept.png" alt="direct-dept">
            </div>
          </div>
        </div>

        <div class="payment-btn-container hidden-mobile">
          <a class="payment-btn mt-3" href="javascript:void(0)" @click="nextStep">
            <button type="button" class="primary-btn">Continue to payment</button>
          </a>

          <p class="text-align-center">Happipad <a href="https://happipad.com/privacy/" target="_blank">Privacy&nbsp;Policy</a> and <a href="https://happipad.com/termsofuse/" target="_blank">Terms of use</a> apply.</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useRenterVerificationPaymentSetup } from "@/stores/renter/verification/useRenterVerificationPayment";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterVerificationPaymentStep1",
  computed: {
    ...mapState(useRenterVerificationPaymentSetup, ['line_items', 'getSubTotal', 'getTaxTotal', 'getGrandTotal'])
  },
  methods: {
    ...mapActions(useRenterVerificationPaymentSetup, ['nextStep']),

    financial(value) {
      return Number.parseFloat(value).toFixed(2);
    },
    backClicked(){
      //TODO
    },
  }
};
</script>