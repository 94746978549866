import { defineStore } from 'pinia';

export const useRenterReceipts = defineStore('receipts', {
    state: () => ({
        renter_first_name: 'Jay',
        show_full_list: false,
        select_all: false,
        recepts: [
            {
                host_first_name: 'Jay',
                host_last_name: 'Doe',
                host_profile_photo: require('@/assets/images/user-placeholder.png'),
                payment_amount: 1300,
                date: '2024-09-23',
                purpose: 'September Rent',
                status: 'Paid',
                selected: false
            },
            {
                host_first_name: 'Jay',
                host_last_name: 'Doe',
                host_profile_photo: require('@/assets/images/user-placeholder.png'),
                payment_amount: 1300,
                date: '2024-09-23',
                purpose: 'Screening',
                status: 'Paid',
                selected: false
            },
            {
                host_first_name: 'Jay',
                host_last_name: 'Doe',
                host_profile_photo: require('@/assets/images/user-placeholder.png'),
                payment_amount: 1300,
                date: '2024-09-23',
                purpose: 'Deposit',
                status: 'Paid',
                selected: false
            },
            {
                host_first_name: 'Jay',
                host_last_name: 'Doe',
                host_profile_photo: require('@/assets/images/user-placeholder.png'),
                payment_amount: 1300,
                date: '2024-09-23',
                purpose: 'Deposit',
                status: 'Paid',
                selected: false
            }
        ]
    }),
    getters: {
        getRecepts() {
            const sortedList = this.recepts.sort((a, b) => {
                return (a.date < b.date) ? -1 : ((a.date > b.date) ? 1 : 0);
            });

            if (this.show_full_list) return sortedList;

            return sortedList.slice(0, 3);
        },
        getMoreCount() {
            if (this.show_full_list) return 0;

            return this.recepts.length - this.getRecepts.length;
        },
    },
    actions: {
        toggleShowFullList() {
            this.show_full_list = !this.show_full_list;
        },
        toggleSelectAll() {
            this.select_all = !this.select_all;

            this.recepts.forEach((recept) => {
                recept.selected = this.select_all;
            });
        },
    },
});