import { defineStore } from 'pinia';

export const useRenterProfile = defineStore('profile', {
    state: () => ({
        currentStep: 1,
        maxSteps: 10,
        steps: [
            {
                step: 1,
                section: 'basic',
                title: 'Location'
            },
            {
                step: 2,
                section: 'basic',
                title: 'Dates'
            },
            {
                step: 3,
                section: 'basic',
                title: 'Budget'
            },
            {
                step: 4,
                section: 'basic',
                title: 'Moving with you'
            },
            {
                step: 5,
                section: 'background',
                title: 'Gender'
            },
            {
                step: 6,
                section: 'background',
                title: 'Languages'
            },
            {
                step: 7,
                section: 'background',
                title: 'Employment'
            },
            {
                step: 8,
                section: 'background',
                title: 'Hobbies'
            },
            {
                step: 9,
                section: 'background',
                title: 'About you'
            },
            {
                step: 10,
                section: 'background',
                title: 'More details'
            },
        ],
        formData: {
            //Step1
            address: '',
            city: '',
            province: '',
            postal_code: '',
            latitude: '',
            longitude: '',

            //Step2
            start_date: '',
            how_long: '',

            //Step3
            budget: null,

            //Step4
            who_moving_with: [],
            adults_count: 0,
            child_count: 0,
            pets: [],
            photos: [],

            //Step5
            your_gender: '',
            self_describe: '',
            preferred_gender: '',

            //Step6
            languages: [],
            additional_languages: [],

            //Step7
            employment: [],
            other_employment: '',
            school: '',

            //Step8
            hobbies: [],

            //Step9
            more_about_you: [],

            //Step10
            profile_picture: [],
            story: ''
        },
        errors: {},
    }),
    getters: {
        basicSteps(){
            return this.steps.filter(step => step.section === 'basic');
        },
        backgroundSteps(){
            return this.steps.filter(step => step.section === 'background');
        },
        basicProgress(){
            let progress = (this.currentStep / 4) * 100;
            if(progress > 100) progress = 100;
            return progress;
        },
        backgroundProgress(){
            const current = this.currentStep - 4;
            if(current < 0){
                return 0
            }

            let progress = (current / 6) * 100;
            if(progress > 100) progress = 100;
            return progress;
        },
        otherEmploymentSelected(){
            return this.formData.employment.includes('other');
        },
        studentEmploymentSelected(){
            return this.formData.employment.includes('student');
        },
    },
    actions: {
        updateFormData(newData) {
            Object.assign(this.formData, newData);
        },
        nextStep(){
            const next = this.currentStep + 1;
            if (next <= this.maxSteps && this.validateStep()) {
                this.currentStep++;
            }
        },
        prevStep(){
            if(this.currentStep <= 1) return;
            this.currentStep--;
        },

        increment(key){
            if(!(key in this.formData)) {
                console.error('key provided not in object');
                return;
            }

            this.formData[key]++;
        },
        decrement(key){
            if(!(key in this.formData)) {
                console.error('key provided not in object');
                return;
            }

            const newValue = this.formData[key] - 1;
            if(newValue < 0) return;

            this.formData[key] = newValue;
        },
        goToStep(step){
            this.currentStep = step;
        },

        validatePostalCode(postalCode) {
            // Canadian postal code format
            const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            return postalCodeRegex.test(postalCode);
        },
        validateDate(dateString){
            const date = new Date(dateString);
            return !isNaN(date);
        },
        validateText(text){
            return text && text.trim() !== '';
        },
        validateStep(){
            this.errors = {};

            switch (this.currentStep) {
                case 1:
                    // Validate Address
                    if (!this.validateText(this.formData.address)) {
                        this.errors.address = ['Address is required'];
                    }
                    // Validate City
                    if (!this.validateText(this.formData.city)) {
                        this.errors.city = ['City is required'];
                    }
                    // Validate Province
                    if (!this.validateText(this.formData.province)) {
                        this.errors.province = ['Province is required'];
                    }
                    // Validate Postal Code
                    if (!this.formData.postal_code) {
                        this.errors.postal_code = ['Postal Code is required'];
                    }
                    else if (!this.validatePostalCode(this.formData.postal_code)) {
                        this.errors.postal_code = ['Invalid email address'];
                    }
                    break;

                case 2:
                    // Validate Start date
                    if (!this.formData.start_date) {
                        this.errors.start_date = ['Start date is required'];
                    }
                    else if (!this.validateDate(this.formData.start_date)) {
                        this.errors.start_date = ['Invalid start date'];
                    }
                    break;

                case 3:
                    // Validate Budget
                    if (!this.formData.budget) {
                        this.errors.budget = ['Budget is required'];
                    }
                    else if (isNaN(this.formData.budget) || this.formData.budget < 0) {
                        this.errors.budget = ['Invalid budget'];
                    }
                    break;

                case 5:
                    // Validate self described gender
                    if (!this.validateText(this.formData.your_gender)) {
                        this.errors.your_gender = ['Gender is required'];
                    }
                    else if (this.formData.your_gender.trim() === 'self-describe' && !this.validateText(this.formData.self_describe)) {
                        this.errors.your_gender = ['Self-described gender is required'];
                    }
                    // Validate host gender preference
                    if (!this.formData.preferred_gender) {
                        this.errors.preferred_gender = ['Host gender preference is required'];
                    }

                    break;

                case 6:
                    // Validate Language
                    if (this.formData.languages.length === 0 && this.formData.additional_languages.length === 0) {
                        this.errors.languages = ['Language is required'];
                    }

                    break;

                default:
                    break;
            }

            return Object.keys(this.errors).length === 0;
        },

        
    }
});