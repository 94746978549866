<template>
  <div class="form-step active">
    <div>
      <h2 class="heading-2">More about you.</h2>
      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="about-yourself-label">
        <div class="checkbox-container">
          <input
            v-model="formData.more_about_you"
            type="checkbox"
            id="smoker"
            name="about-yourself"
            value="I smoke cigarettes or vape">

          <label for="smoker">I smoke cigarettes or vape</label>
        </div>

        <div class="checkbox-container">
          <input
            v-model="formData.more_about_you"
            type="checkbox"
            id="cannabis-friendly"
            name="about-yourself"
            value="I’m cannabis friendly">

          <label for="cannabis-friendly">I’m cannabis friendly</label>
        </div>

        <div class="checkbox-container">
          <input
            v-model="formData.more_about_you"
            type="checkbox"
            id="alcohol"
            name="about-yourself"
            value="I consume alcohol socially">

          <label for="alcohol">I consume alcohol socially</label>
        </div>

        <div class="checkbox-container">
          <input
            v-model="formData.more_about_you"
            type="checkbox"
            id="pet-friendly"
            name="about-yourself"
            value="I’m pet friendly">

          <label for="pet-friendly">I’m pet friendly</label>
        </div>

        <div class="checkbox-container">
          <input
            v-model="formData.more_about_you"
            type="checkbox"
            id="none"
            name="about-yourself"
            value="None of those">

          <label for="none">None of those</label>
        </div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextStep">Next</button>
    </div>
  </div>
</template>

<script>
import { useRenterProfile } from "@/stores/renter/profile/useRenterProfile";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterProfileStep9",
  data() {
    return {};
  },
  computed: {
    ...mapState(useRenterProfile, ['formData']),
  },
  methods: {
    ...mapActions(useRenterProfile, ['nextStep', 'prevStep']),
  }
};
</script>