import { defineStore } from 'pinia';

export const useDashboardNotifications = defineStore('notifications', {
    state: () => ({
        list: [
            {
                icon: require('@/assets/images/happipad-chat.png'),
                text: 'Porter has requested your security screening report. Complete your account verification now to send Porter your report. Porter has requested your security screening report. Complete your account verification now to send Porter your report.',
                time: '2 weeks ago',
                unread: true,
                seen: true
            },
            {
                icon: require('@/assets/images/happipad-chat.png'),
                text: 'Porter has requested your security screening report. Complete your account verification now to send Porter your report. Porter has requested your security screening report. Complete your account verification now to send Porter your report.',
                time: '9:35 PM',
                unread: true,
                seen: false
            }
        ]
    }),
    // getters: {},
    // actions: {},
});