<template>
  <RenterDashboardTopNav />

  <div class="user-profile renter-profile renter-profile-edit">
    <RenterDashboardSidebar class="hidden-mobile mt-5" />

    <div class="container flex-container profile-section">
      <h1 class="heading-2">Profile</h1>

      <!-- Profile Section -->
      <section class="profile-header" aria-labelledby="profile-heading">
        <div tabindex="0" aria-labelledby="profile-name">
          <div
            class="profile-header__bg"
            :style="{ backgroundImage: `url(${require('@/assets/images/profile-background-placeholder.jpeg')})` }"
          >

            <div class="profile-header__edit-image">
              <img
                :src="formData.renter_picture"
                alt="profile picture"
                class="profile-image"
              />

              <a href="#" class="edit-link" @click="editProfilePicture">
                <img src="@/assets/svgs/edit.svg" alt="edit">
              </a>
            </div>
          </div>

          <div class="profile-header__info">
            <h2 class="heading-2" id="profile-name">{{ formData.renter_firstName }}, {{ formData.renter_age }}</h2>

            <p class="verification-status">
              <span v-if="formData.renter_id_verified" aria-label="ID Verified">
                <img src="@/assets/svgs/verified.svg" alt="verified" />
                ID verified
              </span>
              <span v-if="formData.renter_email_verified" aria-label="Email Verified">
                <img src="@/assets/svgs/verified.svg" alt="verified" />
                Email verified
              </span>
            </p>

            <div v-if="formData.renter_screening_verified" class="info-note mt-4">
              <img src="@/assets/images/certn-logo.webp" alt="Certn" />
              <p>Screening completed</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Story Section -->
      <ProfileStory />

      <!-- verification Section -->
      <section aria-labelledby="verification-heading" class="card" tabindex="0">
        <div class="verification-not-completed-section">
          <div class="edit-info">
            <h2 id="verification-heading" class="heading-6">Verifications</h2>

            <!-- <a href="#" class="edit-link" @click="editVerifications">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a> -->
          </div>

          <div v-if="certnSubmitted" class="additional-info">
            <p>
              <img src="@/assets/svgs/checkmark-circle-green.svg" alt="checkmark" />
              Screening was submitted and pending results
            </p>
          </div>

          <div v-if="!certnSubmitted" class="additional-info">
            <p>
              <img src="@/assets/images/certn-logo.webp" alt="Certn" />
              Screening not completed
            </p>

            <p>
              <img src="@/assets/images/certn-logo.webp" alt="Certn" />
              ID verification not completed
            </p>
          </div>

          <button v-if="!certnSubmitted" class="tertiary-btn mt-3" @click="startVerification">
            Start verification
          </button>
        </div>
      </section>

      <!-- reference Section -->
      <section aria-labelledby="reference-heading" class="card" tabindex="0">
        <div class="reference-section">
          <h2 id="reference-heading" class="heading-6">References</h2>

          <div v-if="formData.renter_references.length > 0" class="additional-info">
            <p>
              <img src="@/assets/svgs/checkmark.svg" alt="check">
              {{ referencesProvided }}
            </p>
          </div>
          <p v-else>No references provided</p>

          <button class="tertiary-btn mt-3" @click="showModal = true">
            Add references
          </button>

          <!-- Modal Popup -->
          <div v-if="showModal" class="modal-overlay" :class="{ 'show': showModal }" @click.self="closeModal">
            <div class="modal-container">
              <div class="modal-header">
                <p>Reference</p>
                <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
              </div>

              <!-- Integrated Form in Modal -->
              <div class="form-container">
                <h2 class="heading-4">Reference details</h2>

                <form id="screening-details-form" @submit.prevent="submitForm">
                  <div class="form-group">
                    <label for="full-name">Referee full name</label>
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Enter your full name"
                      maxlength="30"
                      id="fullName"
                      v-model="form.fullName"
                    >
                  </div>

                  <div class="form-group">
                    <label for="phone">Phone number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="xxxxxxxxxxx"
                      id="phone"
                      v-model="form.phoneNumber"
                    >
                    <div id="phoneErrorText" class="validation-error"></div>
                  </div>

                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="john@acme.com"
                      maxlength="254"
                      id="email"
                      v-model="form.email"
                    >
                    <div id="emailErrorText" class="validation-error"></div>
                  </div>

                  <div class="form-group">
                    <label for="relation">Relation to renter</label>
                    <input
                      type="relation"
                      name="relation"
                      placeholder="Type..."
                      maxlength="254"
                      id="relation"
                      v-model="form.relation"
                    >
                    <div id="relationErrorText" class="validation-error"></div>
                  </div>

                  <div class="info-note mt-4">
                    <img src="@/assets/svgs/info.svg" alt="Info" />
                    <p>Explain how do you know this person.</p>
                  </div>

                  <div
                    class="add-address-container add-container mt-4"
                    tabindex="0"
                    role="button"
                    aria-label="Add another address"
                    @click="addNewAddress"
                  >
                    <img src="@/assets/svgs/plus.svg" alt="Add address icon" class="add-address-icon" @click="addNewReference" />
                    <p><strong>Add another reference</strong></p>
                  </div>

                  <div class="form-actions sticky-btm">
                    <button type="submit" class="primary-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- looking in Section -->
      <ProfileLookingIn />

      <!-- looking for Section -->
      <ProfileLookingForDates />

      <!-- Budget Section -->
      <ProfileLookingForPrice />

      <!-- moving with Section -->
      <section aria-labelledby="moving-with-heading" class="card" tabindex="0">
        <div class="moving-with-section">
          <div class="edit-info">
            <h2 id="moving-with-heading" class="heading-6">Moving with</h2>

            <a href="#" class="edit-link" @click="editMovingWith">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <div class="additional-info">
            <p v-for="(item, index) in formData.renter_moving_with" :key="index">
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
              {{ capitalize(item) }}
            </p>
          </div>

          <div class="pets-list">
            <img
              v-for="(url, index) in formData.renter_moving_with_pictures"
              :key="index"
              :src="url"
              alt="pet image"
              class="pet-image"
            />
          </div>
        </div>
      </section>

      <!-- Preferences -->
      <ProfileGender />

      <!-- langs -->
      <ProfileLanguages />

      <!-- employment -->
      <ProfileEmployment />

      <!-- Habits Section -->
      <section aria-labelledby="habits-heading" class="card" tabindex="0">
        <div class="habits-section">
          <h2 id="habits-heading" class="heading-6">{{ formData.renter_firstName }}'s Habits</h2>
          <div class="additional-info">
            <p v-for="(habit, index) in formData.renter_habits" :key="index">
              <img :src="getHabitsIcon(habit)" :alt="capitalize(habit)" />
              {{ capitalize(habit) }}
            </p>
          </div>
        </div>
      </section>

      <!-- Hobbies Section -->
      <section aria-labelledby="hobbies-heading" class="card" tabindex="0">
        <div class="hobbies-section">
          <h2 id="hobbies-heading" class="heading-6">{{ formData.renter_firstName }}'s Hobbies</h2>
          <div class="additional-info">
            <p v-for="(hobby, index) in formData.renter_hobbies" :key="index">
              <img :src="getHobbiesIcon(hobby)" :alt="capitalize(hobby)" />
              {{ capitalize(hobby) }}
            </p>
          </div>
        </div>
      </section>

      <!-- email Section -->
      <ProfileEmail />

      <!-- phone Section -->
      <ProfilePhone />
    </div>
  </div>

  <RenterDashboardBottomNav />
</template>

<script>
import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

import ProfileStory from "@/views/renter/dashboard/profile/ProfileStory.vue";
import ProfileLookingIn from "@/views/renter/dashboard/profile/ProfileLookingIn.vue";
import ProfileLookingForDates from "@/views/renter/dashboard/profile/ProfileLookingForDates.vue";
import ProfileLookingForPrice from "@/views/renter/dashboard/profile/ProfileLookingForPrice.vue";
import ProfileGender from "@/views/renter/dashboard/profile/ProfileGender.vue";
import ProfileLanguages from "@/views/renter/dashboard/profile/ProfileLanguages.vue";
import ProfileEmployment from "@/views/renter/dashboard/profile/ProfileEmployment.vue";
import ProfileEmail from "@/views/renter/dashboard/profile/ProfileEmail.vue";
import ProfilePhone from "@/views/renter/dashboard/profile/ProfilePhone.vue";


import { useRenterProfile } from "@/stores/renter/useRenterProfile";
import { mapState } from "pinia";

export default {
  name: "RenterProfile",

  components: {
    RenterDashboardSidebar,
    RenterDashboardBottomNav,
    RenterDashboardTopNav,
    ProfileStory,
    ProfileLookingIn,
    ProfileLookingForDates,
    ProfileLookingForPrice,
    ProfileGender,
    ProfileLanguages,
    ProfileEmployment,
    ProfileEmail,
    ProfilePhone,
  },

  data() {
    return {
      showModal: false,
      form: {
        email: "",
        fullName: "",
        relation: "",
        phoneNumber: "",
      },
    };
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'languagesText', 'certnSubmitted', 'certnCompleted', 'referencesProvided'])
  },
  methods: {
    openModal() {
      this.showModal = true;
      document.body.style.overflow = "hidden"; // Prevent background scroll
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = "";
    },
    addNewReference() {
      this.references.push({
        fullName: "",
        phoneNumber: "",
        email: "",
        relation: "",
      });
    },
    submitForm() {
      this.closeModal();
    },
    editProfilePicture() {
      //TODO
    },
    editVerifications() {
      //TODO
    },
    startVerification() {
      //TODO
    },
    editMovingWith() {
      //TODO
    },



    getNormalizedText(text) {
      return text.replace(/[-| |/|\\]/g, '_').replace(/:/g, '');
    },
    getHabitsIcon(habit) {
      const habitNormalized = this.getNormalizedText(habit);

      const images = {
        drinking_socially: require('@/assets/svgs/profile-drinking.svg'),
        cannabis_friendly: require('@/assets/svgs/profile-cannabis.svg'),
        not_pet_friendly: require('@/assets/svgs/profile-not-pet-friendly.svg'),
        smoke_vape_never: require('@/assets/svgs/profile-non-smoker.svg'),
        cooks_1_2_times_per_week: require('@/assets/svgs/profile-cooks.svg'),
        cleaning_a_few_times_per_week: require('@/assets/svgs/profile-cleans.svg'),
        organize_only_if_necessary: require('@/assets/svgs/profile-organization.svg'),
      };

      return images[habitNormalized];
    },
    getHobbiesIcon(hobby) {
      const hobbyNormalized = this.getNormalizedText(hobby);

      const images = {
        traveling: require('@/assets/svgs/profile-traveling.svg'),
        gardening: require('@/assets/svgs/profile-gardening.svg'),
        music: require('@/assets/svgs/profile-music.svg'),
      };

      return images[hobbyNormalized];
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
};
</script>