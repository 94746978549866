import { defineStore } from 'pinia';

export const useDates = defineStore('dates', {
    actions: {
        getMonthNumberToShortText(number) {
            const monthNumber = parseInt(number);

            switch (monthNumber) {
                case 1:
                    return 'Jan';
                case 2:
                    return 'Feb';
                case 3:
                    return 'Mar';
                case 4:
                    return 'Apr';
                case 5:
                    return 'May';
                case 6:
                    return 'Jun';
                case 7:
                    return 'Jul';
                case 8:
                    return 'Aug';
                case 9:
                    return 'Sep';
                case 10:
                    return 'Oct';
                case 11:
                    return 'Nov';
                case 12:
                    return 'Dec';
                default:
                    return '';
            }
        },
        getMonthNumberToLongText(number) {
            const monthNumber = parseInt(number);

            switch (monthNumber) {
                case 1:
                    return 'January';
                case 2:
                    return 'February';
                case 3:
                    return 'March';
                case 4:
                    return 'April';
                case 5:
                    return 'May';
                case 6:
                    return 'June';
                case 7:
                    return 'July';
                case 8:
                    return 'August';
                case 9:
                    return 'September';
                case 10:
                    return 'October';
                case 11:
                    return 'November';
                case 12:
                    return 'December';
                default:
                    return '';
            }
        },
        getOrdinalSuffix(number) {
            if (number > 3 && number < 21) return "th";

            switch (number % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        },
        getRelativeDateText(isoDate) {
            const dayDiff = this.getDaysDifference(new Date(), isoDate);

            if(dayDiff === 0){
                return "Today";
            }
            else if(dayDiff === 1){
                return "Yesterday";
            }
            else if(dayDiff > 1 && dayDiff <= 7){
                return "This Week";
            }
            else if(dayDiff > 7 && dayDiff <= 14){
                return "Last Week";
            }
            else if(dayDiff > 14 && dayDiff <= 30){
                return "This Month";
            }
            else if(dayDiff > 30 && dayDiff <= 60){
                return "Last Month";
            }
            else if(dayDiff > 60 && dayDiff <= 365){
                return "This Year";
            }
            else{
                return "";
            }
        },
        getDaysDifference(first, second){
            const today = first instanceof Date ? first : new Date(first);
            const date = second instanceof Date ? second : new Date(second);
            today.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);

            const msDiff = today - date;
            return Math.floor(msDiff / (1000 * 60 * 60 * 24));
        },

        getFormattedDateString(isoDate, format){
            const date = isoDate instanceof Date ? isoDate : new Date(isoDate);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            
            let dateString = '';

            if(format === 'MM DD, YYYY'){
                const monthText = this.getMonthNumberToShortText(month);
                dateString = `${monthText} ${day}, ${year}`;
            }
            else if(format === 'MMMM DD'){
                const monthText = this.getMonthNumberToShortText(month);
                dateString = `${monthText} ${day}`;
            }

            return dateString;
        },

        getFormattedTimeString(isoDate, format){
            const date = isoDate instanceof Date ? isoDate : new Date(isoDate);
            let timeString = '';

            if(format === 'H:MM AM'){
                let hours = date.getHours();
                let minutes = date.getMinutes();
                let ampm = hours >= 12 ? "PM" : "AM";

                hours = hours % 12 || 12; // Display 1-11 PM, midnight as 12 AM
                minutes = minutes < 10 ? "0" + minutes : minutes;
                timeString = `${hours}:${minutes} ${ampm}`;
            }

            return timeString;
        },
    }
});