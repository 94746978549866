<template>
  <RenterDashboardTopNav />

  <div class="renter-dashboard">
    <div class="invoices container flex-container">
      <RenterDashboardSidebar />

      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-2">Receipts</h1>

          <div class="edit-info hidden-mobile">
            <a href="javascript:void(0)" class="edit-link" @click="exportSelected">
              Export selected
            </a>

            <a href="javascript:void(0)" class="edit-link" @click="exportAsCSV">
              <img src="@/assets/svgs/download.svg" alt="download">
              Export as CSV
            </a>
          </div>

          <p class="hidden-desktop" id="table-title">Showing {{ getRecepts.length }} of {{ recepts.length }}</p>
        </header>

        <div class="cards-container card">
          <!-- Table Section -->
          <section v-if="recepts.length > 0" class="mb-4" aria-labelledby="table-title">
            <div class="mb-4 hidden-mobile">
              <p class="hidden-mobile" id="table-title">Showing {{ getRecepts.length }} of {{ recepts.length }}</p>
            </div>

            <div class="edit-info mb-3 hidden-desktop">
              <a href="javascript:void(0)" class="edit-link" @click="exportAsCSV">
                <img src="@/assets/svgs/download.svg" alt="download">
                Export as CSV
              </a>
            </div>

            <div class="dashboard-table-container">
              <table>
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="default-checkbox" tabindex="0">
                        <input type="checkbox" id="select-all" name="select-all" v-model="select_all" @change="toggleSelectAll()" />

                        <label for="select-all"></label>
                      </div>
                    </th>
                    <th class="hidden-desktop" scope="col">Payment</th>
                    <th scope="col">Host</th>
                    <th scope="col">Payment</th>
                    <th scope="col">Date</th>
                    <th scope="col">Purpose</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(recept, index) in getRecepts" :key="index">
                    <td class="hidden-desktop">
                      <div data-label="Payment 6574937"></div>
                      <a href="javascript:void(0)" @click="viewRecept">
                        <img src="@/assets/svgs/eye-white.svg" alt="view">
                        View
                      </a>
                    </td>

                    <td class="hidden-mobile">
                      <div class="default-checkbox" tabindex="0">
                        <input type="checkbox" :id="`select-${index}`" :name="`select-${index}`" v-model="recept.selected" />

                        <label :for="`select-${index}`"></label>
                      </div>
                    </td>

                    <td data-label="Host">
                      <div>
                        <img :src="recept.host_profile_photo" alt="Host profile" />
                        {{ recept.host_first_name }} {{ recept.host_last_name }}
                      </div>
                    </td>

                    <td data-label="Payment">${{ recept.payment_amount }}</td>

                    <td data-label="Date">
                      <div>
                        {{ isoToFormattedDayMonth(recept.date) }}
                      </div>
                    </td>

                    <td data-label="Purpose">
                      <div>
                        {{ recept.purpose }}
                      </div>
                    </td>

                    <td data-label="Status">
                      <span class="status-badge" :class="{ 'status-badge--green': recept.status === 'Paid' }" :aria-label="`Listing status: ${recept.status}`" role="status">{{ recept.status }}</span>
                    </td>

                    <td class="hidden-mobile">
                      <div>
                        <button class="tertiary-btn" aria-label="details" @click="viewRecept">
                          <img src="@/assets/svgs/eye.svg" alt="" />
                        </button>

                        <a href="javascript:void(0)" class="tertiary-btn" @click="downloadRecept">Download</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- we need to make this work similar to host dashboard functionality -->
            <a v-if="!show_full_list && getMoreCount > 0" href="javascript:void(0)" class="view-more mt-4" @click="toggleShowFullList()">Show {{ getMoreCount }} more
              <img src="@/assets/svgs/chevron-down-blue.svg" alt="chevron down">
            </a>
            <a v-if="show_full_list" href="javascript:void(0)" class="view-more mt-4" @click="toggleShowFullList()">Show less
              <img src="@/assets/svgs/chevron-up-blue.svg" alt="chevron up">
            </a>
          </section>

          <div v-if="recepts.length === 0" class="listing-card listing-card--empty text-align-center">
            <p>Hey {{ renter_first_name }}! No transactions to display.<br>
              If you have any transactions in the future they will be displayed here.</p>

            <img src="@/assets/svgs/no-transactions.svg" alt="">

            <a href="javascript:void(0)" class="tertiary-btn" @click="readQA">
              <img src="@/assets/svgs/document.svg" alt="document">
              Read Q & A
            </a>
          </div>
        </div>
      </div>

      <!-- Modal Popup -->
      <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
        <div class="modal-container">
          <div class="modal-header">
            <p>Receipt</p>
            <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
          </div>

          <!-- Integrated receipt in Modal -->
          <div class="modal-content-container">
            <div class="modal-content-container__btns">
              <button class="secondary-btn">Download</button>
              <button class="primary-btn"><img src="@/assets/svgs/upload.svg" alt="upload"></button>
            </div>

            <h3 class="heading-5">Receipt 546565</h3>
          </div>
        </div>
      </div>

      <RenterDashboardBottomNav />
    </div>
  </div>
</template>

<script>
import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";
import { useRenterReceipts } from "@/stores/renter/useRenterReceipts";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterNotificationControl",

  components: {
    RenterDashboardSidebar,
    RenterDashboardBottomNav,
    RenterDashboardTopNav
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState(useRenterReceipts, ['renter_first_name', 'recepts', 'show_full_list', 'getRecepts', 'getMoreCount', 'select_all'])
  },
  methods: {
    ...mapActions(useRenterReceipts, ['toggleShowFullList', 'toggleSelectAll']),

    openModal() {
      this.showModal = true;
      document.body.style.overflow = "hidden"; // Prevent background scroll
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = "";
    },
    addNewReference() {
      this.references.push({
        fullName: "",
        phoneNumber: "",
        email: "",
        relation: "",
      });
    },
    submitForm() {
      this.closeModal();
    },
    isoToFormattedDayMonth(isoDate) {
      const splitDate = isoDate.split('-');
      const year = splitDate[0];
      const month = this.monthNumberToText(splitDate[1]);
      const day = splitDate[2];
      return `${month} ${day}, ${year}`;
    },
    monthNumberToText(value) {
      const monthNumber = parseInt(value);

      switch (monthNumber) {
        case 1:
          return 'Jan';
        case 2:
          return 'Feb';
        case 3:
          return 'Mar';
        case 4:
          return 'Apr';
        case 5:
          return 'May';
        case 6:
          return 'Jun';
        case 7:
          return 'Jul';
        case 8:
          return 'Aug';
        case 9:
          return 'Sep';
        case 10:
          return 'Oct';
        case 11:
          return 'Nov';
        case 12:
          return 'Dec';
        default:
          return '';
      }
    },
    viewRecept() {
      this.showModal = true;
    },
    downloadRecept() {
      //TODO
    },
    readQA() {
      //TODO
    },
  },
};
</script>