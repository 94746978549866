import { defineStore } from 'pinia';

export const useHostApplications = defineStore('applications', {
    state: () => ({
        show_full_list: false,
        host_name: "Jay",
        applications_list: [
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-23",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "pending"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-22",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "declined"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-21",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "approved"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-20",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "pending"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-19",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "pending"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-18",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "pending"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-17",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "pending"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-16",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "pending"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-15",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "pending"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-14",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "pending"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-13",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "pending"
            },
            {
                application_id: Math.random(),
                profile_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                name: "Jay Doe",
                applied_on: "2021-04-12",
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                payment: 1300,
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                status: "pending"
            },
        ]
    }),
    getters: {
        getApplications() {
            const sortedList = this.applications_list.sort((a, b) => {
                return (a.applied_on > b.applied_on) ? -1 : ((a.applied_on < b.applied_on) ? 1 : 0);
            });

            if (this.show_full_list) return sortedList;

            const count = sortedList.length;
            const max = count > 8 ? 8 : count;

            return sortedList.slice(0, max);
        },
        getMoreCount() {
            if (this.show_full_list) return 0;

            return this.applications_list.length - this.getApplications.length;
        },
    },
    actions: {
        toggleShowFullList() {
            this.show_full_list = !this.show_full_list;
        },
    },
});