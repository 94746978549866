<template>
  <div class="form-step active" data-step-index="1">
    <div>
      <h1 class="heading-2">Where are you looking to move in?</h1>
      <p class="font-size-medium">Draw exact area where you want to rent</p>
    </div>

    <div class="form-group">
      <div class="google_map">
        <div style="height: 400px;" id="map"></div>
      </div>

      <label class="mt-3">{{ formData.city }} {{ formData.province }}</label>

      <p class="text-faded">Check if location on the map matches your place.</p>

      <div class="form-group mt-5">
        <label for="address">Address</label>

        <input 
          v-model="formData.address"
          type="text" 
          name="address" 
          placeholder="Start typing your address.." 
          id="address" 
          required />

        <div id="addressErrorText" class="validation-error">
          <span v-if="errors.address">{{ errors.address[0] }}</span>
        </div>

        <div class="form-group">
          <label for="city">City</label>
          <input 
            v-model="formData.city"
            type="text" 
            name="city" 
            placeholder="Start typing your city.." 
            id="city" 
            required />
          <div id="cityErrorText" class="validation-error">
            <span v-if="errors.city">{{ errors.city[0] }}</span>
          </div>
        </div>

        <div class="form-group flex-container flex-col-2">
          <div>
            <label for="province">Province</label>

            <input 
              v-model="formData.province"
              type="text" 
              name="province" 
              id="state" 
              placeholer="" 
              required />

            <div id="stateErrorText" class="validation-error">
              <span v-if="errors.province">{{ errors.province[0] }}</span>
            </div>
          </div>

          <div>
            <label for="postalCode">Postal Code</label>

            <input
              v-model="formData.postal_code"
              type="text" 
              name="postalCode" 
              placeholder="Start typing your postal code.." 
              id="zip" 
              required />

            <div id="zipErrorText" class="validation-error">
              <span v-if="errors.postal_code">{{ errors.postal_code[0] }}</span>
            </div>
          </div>
        </div>

        <div>
          <input 
            v-model="formData.latitude"
            type="hidden" 
            name="latitude" 
            id="latitude" 
            readonly />
          <input 
            v-model="formData.longitude"
            type="hidden" 
            name="longitude" 
            id="longitude" 
            readonly />
        </div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="primary-btn next-step" @click="nextStep">Next</button>
    </div>
  </div>
</template>

<script>
// do not remove /* global google */
/* global google */
import { useRenterProfile } from "@/stores/renter/profile/useRenterProfile";
import { mapState, mapActions } from "pinia";
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: "RenterProfileStep1",
  data() {
    return {
      map: null,
      marker: null,
      autocomplete: null,
      geocoder: null,
    };
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'errors'])
  },
  mounted() {
    const loader = new Loader({
      apiKey: 'AIzaSyCCfSrPTp0wnNNwR9cEXPO8OZXpAaXZkZ8',
      libraries: ['places'],
    });

    loader.load().then(() => {
      this.initMap();
      this.initAutocomplete();
      this.geocoder = new google.maps.Geocoder();
      this.getCurrentLocation(); // Fetch current location on mount
    });
  },
  methods: {
    ...mapActions(useRenterProfile, ['nextStep']),

    initAutocomplete() {
      const input = document.getElementById('address');
      const options = {
        types: ['address'],
      };

      this.autocomplete = new google.maps.places.Autocomplete(input, options);
      this.autocomplete.addListener('place_changed', this.onPlaceChanged);
    },
    onPlaceChanged() {
      const place = this.autocomplete.getPlace();

      if (!place.geometry) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // Update formData directly
      this.formData.address = place.formatted_address || '';
      this.formData.city = '';
      this.formData.province = '';
      this.formData.postal_code = '';
      this.formData.latitude = '';
      this.formData.longitude = '';

      for (const component of place.address_components) {
        const types = component.types;
        if (types.includes('locality')) {
          this.formData.city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          this.formData.province = component.short_name;
        }
        if (types.includes('postal_code')) {
          this.formData.postal_code = component.long_name;
        }
      }

      if (place.geometry && place.geometry.location) {
        const location = place.geometry.location;
        this.formData.latitude = location.lat();
        this.formData.longitude = location.lng();

        this.updateMap(location);
      }
    },
    initMap() {
      const mapElement = document.getElementById('map');
      this.map = new google.maps.Map(mapElement, {
        center: { lat: 43.6532, lng: -79.3832 }, // Default to Toronto
        zoom: 12,
      });
      this.marker = new google.maps.Marker({
        map: this.map,
      });
    },
    updateMap(location) {
      this.map.setCenter(location);
      this.map.setZoom(16);
      this.marker.setPosition(location);
    },
    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              this.formData.latitude = lat;
              this.formData.longitude = lng;

              this.geocoder.geocode(
                  { location: { lat, lng } },
                  (results, status) => {
                    if (status === 'OK' && results[0]) {
                      const place = results[0];
                      this.formData.address = place.formatted_address;

                      for (const component of place.address_components) {
                        const types = component.types;
                        if (types.includes('locality')) {
                          this.formData.city = component.long_name;
                        }
                        if (types.includes('administrative_area_level_1')) {
                          this.formData.province = component.short_name;
                        }
                        if (types.includes('postal_code')) {
                          this.formData.postal_code = component.long_name;
                        }
                      }

                      const location = place.geometry.location;
                      this.updateMap(location);
                    } else {
                      console.error('Geocoder failed due to:', status);
                    }
                  }
              );
            },
            (error) => {
              console.error('Error fetching location:', error);
            }
        );
      }
    },
  }
};
</script>