import { useValidation } from '@/stores/shared/useValidation';
import { defineStore } from 'pinia';

export const useRenterProfile = defineStore('profile', {
    state: () => ({
        formData: {
            renter_firstName: 'John',
            renter_lastName: 'Doe',
            renter_age: 54,
            renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
            renter_photo: [],
            renter_gender: 'male',
            renter_self_describe: '',
            renter_preferred_gender: 'male',
            renter_languages: ['english', 'french'],
            additional_languages: [],
            renter_story: 'I\'m an easy going person, and I do work full time doing maintenance and I stay to my self and Im looking for room and I love having fun but I\'m a very quiet person.',
            renter_phone: '1234567890',
            renter_email: 'jaydoe@gmail.com',
            renter_email_verified: true,
            renter_id_verified: true,
            renter_screening_verified: true,
            renter_hobbies: ['traveling', 'gardening', 'music'],
            renter_habits: [
                'drinking socially',
                'cannabis friendly',
                'not pet friendly',
                'smoke/vape: never',
                'cooks 1-2 times per week',
                'cleaning: a few times per week',
                'organize only if necessary'
            ],
            renter_employment: ['part-time employee', 'student'],
            renter_other_employment: '',
            renter_school: 'Saint Mary\'s U',
            renter_looking_in: {
                address: '2277 Lillooet Crescent, Kelowna, BC V1V 1T1, Canada',
                city: 'Kelowna',
                province: 'BC',
                postal_code: 'V1V 1T1',
                latitude: 49.9001,
                longitude: -119.434,
            },
            renter_looking_for_start: '12/06/2024',
            renter_looking_for_end: '12/06/2024',
            renter_looking_for_price: '2000',
            renter_references: [
            ],
            renter_certn: {
                screening: '',
                id: ''
            },
            renter_moving_with: ['a family of 2', 'pet dog'],
            renter_moving_with_pictures: [
                require('@/assets/images/profile-pet-placeholder.jpeg'),
                require('@/assets/images/profile-pet-2-placeholder.jpeg')
            ],
        },

        errors: {},
    }),
    getters: {
        certnSubmitted() {
            const certn = this.formData.renter_certn;
            return certn.screening !== '' && certn.id !== '';
        },
        certnCompleted() {
            const certn = this.formData.renter_certn;
            return certn.renter_id_verified && certn.renter_screening_verified;
        },
        referencesProvided() {
            const references = this.formData.renter_references;
            const plural = references.length === 1 ? "" : "s";
            return `${references.length} reference${plural} provided`;
        },
        languagesText() {
            const languages = [...this.formData.renter_languages];
            if (languages == null || languages.length === 0) return '';

            for (let i = 0; i < languages.length; i++) {
                languages[i] = this.capitalize(languages[i]);
            }

            const length = languages.length;
            if (length === 1) {
                return languages[0];
            }

            if (length === 2) {
                return languages.join(' and ');
            }

            let results = '';
            languages.forEach((language, index) => {
                let separator = "";

                if (index > 0 && index + 1 < length) {
                    separator = ', ';
                }
                else if (index + 1 === length) {
                    separator = ', and ';
                }

                results += `${separator}${language}`;
            });

            return results;
        },
        otherEmploymentSelected(){
            return this.formData.renter_employment.includes('other');
        },
        studentEmploymentSelected(){
            return this.formData.renter_employment.includes('student');
        },
    },
    actions:{
        capitalize(text) {
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
        clearErrors(){
            this.errors = {};
        },
        validateAddress(location){
            const validate = useValidation();
            this.clearErrors();

            // Validate Address
            if (!validate.hasValue(location.address)) {
                this.errors.address = ['Address is required'];
            }
            // Validate City
            if (!validate.hasValue(location.city)) {
                this.errors.city = ['City is required'];
            }
            // Validate Province
            if (!validate.hasValue(location.province)) {
                this.errors.province = ['Province is required'];
            }
            // Validate Postal Code
            if (!validate.hasValue(location.postal_code)) {
                this.errors.postal_code = ['Postal code is required'];
            }
            else if (!validate.postalCode(location.postal_code)) {
                this.errors.postal_code = ['Invalid postal code'];
            }

            return Object.keys(this.errors).length === 0;
        },
        validateBudget(value){
            this.clearErrors();

            // Validate Budget
            if (!value) {
                this.errors.budget = ['Budget is required'];
            }
            else if (isNaN(value) || value < 0) {
                this.errors.budget = ['Invalid budget'];
            }

            return Object.keys(this.errors).length === 0;
        },
        validateGender(renter_gender, self_describe, preferred_gender){
            const validate = useValidation();
            this.clearErrors();

            // Validate self described gender
            if (!validate.hasValue(renter_gender)) {
                this.errors.your_gender = ['Gender is required'];
            }
            else if (renter_gender.trim() === 'self-describe' && !validate.hasValue(self_describe)) {
                this.errors.your_gender = ['Self-described gender is required'];
            }
            // Validate host gender preference
            if (!validate.hasValue(preferred_gender)) {
                this.errors.preferred_gender = ['Host gender preference is required'];
            }

            return Object.keys(this.errors).length === 0;
        },
        validateLanguages(languages){
            this.clearErrors();

            if (languages.length === 0) {
                this.errors.renter_languages = ['Language is required'];
            }

            return Object.keys(this.errors).length === 0;
        },
        validateEmail(email){
            const validate = useValidation();
            this.clearErrors();

            // Validate Address
            if (!validate.hasValue(email)) {
                this.errors.renter_email = ['Email is required'];
            }
            else if (!validate.email(email)) {
                this.errors.renter_email = ['Invalid email'];
            }

            return Object.keys(this.errors).length === 0;
        },
        validatePhone(phone){
            const validate = useValidation();
            this.clearErrors();

            // Validate Address
            if (!validate.hasValue(phone)) {
                this.errors.renter_phone = ['Phone is required'];
            }
            else if (!validate.phone(phone)) {
                this.errors.renter_phone = ['Invalid phone'];
            }

            return Object.keys(this.errors).length === 0;
        },
    }
});