import { defineStore } from 'pinia';

export const useHostListings = defineStore('listings', {
    state: () => ({
        host_name: "Jay",
        listings_list: [
            {
                listing_id: Math.random(),
                title: "Shared kitchen, private bathroom room in Corktown.",
                details: "Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities. Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities. Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities.",
                thumbnail: require('@/assets/images/listing-thumbnail.png'),
                accommodation_type: "house",
                shared_with: "couple",
                bath_type: "private",
                pet_friendly: true,
                price: 1200,
                status: "draft"
            },
            {
                listing_id: Math.random(),
                title: "Shared kitchen, private bathroom room in Corktown.",
                details: "Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities. Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities. Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities.",
                thumbnail: require('@/assets/images/listing-thumbnail.png'),
                accommodation_type: "house",
                shared_with: "couple",
                bath_type: "shared",
                pet_friendly: true,
                price: 1200,
                status: "listed"
            },
        ]
    }),
});