import { defineStore } from 'pinia';

export const useRenterSaved = defineStore('saved', {
    state: () => ({
        properties: [
            {
                id: Math.random(),
                image: require('@/assets/images/mystay-placeholder.jpeg'),
                location: 'Etobicoke, Toronto',
                title: 'Bright and Quiet furnished bedroom with private bathroom ( 24 hrs sec.',
                descriptors: [
                    '4 residents',
                    'Room in a house',
                    'Shared bath',
                    'Pets free',
                ],
                price: 1200
            },
            {
                id: Math.random(),
                image: require('@/assets/images/mystay-placeholder.jpeg'),
                location: 'Etobicoke, Toronto',
                title: 'Bright and Quiet furnished bedroom with private bathroom ( 24 hrs sec.',
                descriptors: [
                    '4 residents',
                    'Room in a house',
                    'Shared bath',
                    'Pets free',
                ],
                price: 1200
            },
            {
                id: Math.random(),
                image: require('@/assets/images/mystay-placeholder.jpeg'),
                location: 'Etobicoke, Toronto',
                title: 'Bright and Quiet furnished bedroom with private bathroom ( 24 hrs sec.',
                descriptors: [
                    '4 residents',
                    'Room in a house',
                    'Shared bath',
                    'Pets free',
                ],
                price: 1200
            },
            {
                id: Math.random(),
                image: require('@/assets/images/mystay-placeholder.jpeg'),
                location: 'Etobicoke, Toronto',
                title: 'Bright and Quiet furnished bedroom with private bathroom ( 24 hrs sec.',
                descriptors: [
                    '4 residents',
                    'Room in a house',
                    'Shared bath',
                    'Pets free',
                ],
                price: 1200
            },
            {
                id: Math.random(),
                image: require('@/assets/images/mystay-placeholder.jpeg'),
                location: 'Etobicoke, Toronto',
                title: 'Bright and Quiet furnished bedroom with private bathroom ( 24 hrs sec.',
                descriptors: [
                    '4 residents',
                    'Room in a house',
                    'Shared bath',
                    'Pets free',
                ],
                price: 1200
            },
            {
                id: Math.random(),
                image: require('@/assets/images/mystay-placeholder.jpeg'),
                location: 'Etobicoke, Toronto',
                title: 'Bright and Quiet furnished bedroom with private bathroom ( 24 hrs sec.',
                descriptors: [
                    '4 residents',
                    'Room in a house',
                    'Shared bath',
                    'Pets free',
                ],
                price: 1200
            },
        ]
    }),
    // getters: {},
    actions: {
        financial(value) {
            return Number.parseFloat(value).toFixed(2);
        },
        getDescriptorText(descriptors){
            return descriptors.join(' · ');
        }
    },
});