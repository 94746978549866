<template>
  <div class="form-step active">
    <div>
      <h2 class="heading-2">What languages do you speak? *</h2>

      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group" data-group="languages-group">
      <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="languages-label">
        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="english" 
            name="languages" 
            value="english" 
            aria-labelledby="english-label">

          <label for="english" id="english-label">
            <div class="label-content">English</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="french" 
            name="languages" 
            value="french" 
            aria-labelledby="french-label">

          <label for="french" id="french-label">
            <div class="label-content">French</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="filipino" 
            name="languages" 
            value="filipino" 
            aria-labelledby="filipino-label">

          <label for="filipino" id="filipino-label">
            <div class="label-content">Filipino</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="spanish" 
            name="languages" 
            value="spanish" 
            aria-labelledby="spanish-label">

          <label for="spanish" id="spanish-label">
            <div class="label-content">Spanish</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="hindi" 
            name="languages" 
            value="hindi" 
            aria-labelledby="hindi-label">

          <label for="hindi" id="hindi-label">
            <div class="label-content">Hindi</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="german" 
            name="languages" 
            value="german" 
            aria-labelledby="german-label">

          <label for="german" id="german-label">
            <div class="label-content">German</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="chinese" 
            name="languages" 
            value="chinese" 
            aria-labelledby="chinese-label">

          <label for="chinese" id="chinese-label">
            <div class="label-content">Chinese</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="punjabi" 
            name="languages" 
            value="punjabi" 
            aria-labelledby="punjabi-label">

          <label for="punjabi" id="punjabi-label">
            <div class="label-content">Punjabi</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="japanese" 
            name="languages" 
            value="japanese" 
            aria-labelledby="japanese-label">

          <label for="japanese" id="japanese-label">
            <div class="label-content">Japanese</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="russian" 
            name="languages" 
            value="russian" 
            aria-labelledby="russian-label">

          <label for="russian" id="russian-label">
            <div class="label-content">Russian</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="farsi" 
            name="languages" 
            value="farsi" 
            aria-labelledby="farsi-label">

          <label for="farsi" id="farsi-label">
            <div class="label-content">Farsi</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="arabic" 
            name="languages" 
            value="arabic" a
            ria-labelledby="arabic-label">

          <label for="arabic" id="arabic-label">
            <div class="label-content">Arabic</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="portuguese" 
            name="languages" 
            value="portuguese" 
            aria-labelledby="portuguese-label">

          <label for="portuguese" id="portuguese-label">
            <div class="label-content">Portuguese</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="korean" 
            name="languages" 
            value="korean" 
            aria-labelledby="korean-label">

          <label for="korean" id="korean-label">
            <div class="label-content">Korean</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.languages"
            type="checkbox" 
            id="malay" 
            name="languages" 
            value="malay" 
            aria-labelledby="malay-label">

          <label for="malay" id="malay-label">
            <div class="label-content">Malay</div>
          </label>
        </div>
      </div>

      <div v-if="formData.additional_languages.length > 0" id="additional-lang">

      </div>

      <div class="add-lang-container add-container" tabindex="0" role="button" aria-label="Add other language" @click="addLanguageClicked">
        <img src="@/assets/svgs/plus.svg" alt="Add icon">

        <p><strong>Add other language </strong></p>
      </div>

      <div id="langErrorText" class="validation-error">
        <span v-if="errors.languages">{{ errors.languages[0] }}</span>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextStep">Next</button>
    </div>
  </div>
</template>

<script>
import { useRenterProfile } from "@/stores/renter/profile/useRenterProfile";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterProfileStep6",
  data() {
    return {};
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'errors'])
  },
  methods: {
    ...mapActions(useRenterProfile, ['nextStep', 'prevStep']),

    addLanguageClicked(){
      //TODO
    }
  }
};
</script>