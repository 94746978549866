import { defineStore } from 'pinia';

export const useValidation = defineStore('validation', {
    actions: {
        hasValue(text){
            return text != null && text.trim() !== '';
        },
        name(name){
            const nameRegex = /^[A-Za-z\- ]*$/;
            return nameRegex.test(name);
        },
        number(number){
            const numberRegex = /^\d*$/;
            return numberRegex.test(number);
        },
        postalCode(postalCode) {
            // Canadian postal code format
            const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            return postalCodeRegex.test(postalCode);
        },
        email(email){
            // Canadian postal code format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        phone(phone){
            // Canadian phone number
            const value = phone.replace(/\D/g, ''); // Remove non-digit characters
            return value.length === 10; // Canadian phone number should have exactly 10 digits
        },
        date(dateString){
            const date = new Date(dateString);
            return !isNaN(date);
        },
        dateFormatMMYY(dob){
            const datePattern = /^(0[1-9]|1[0-2])\/\d{2}$/; // dd/mm/yyyy format
            const value = dob.trim();
            return datePattern.test(value);
        },
        dateFormatDDMMYYYY(dob){
            const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/; // dd/mm/yyyy format
            const value = dob.trim();
            return datePattern.test(value);
        },
        datePastDDMMYYYY(dob){
            const [day, month, year] = dob.split('/');
            const date = new Date(`${year}-${month}-${day}`);

            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

            return date < today;
        },

    }
});