<template>
  <div class="background-light-blue">
    <div class="container flex-container flex-col-2 renter-profile-container">
      <div class="mobile-progress">
        <div class="mobile-progress-bar">
          <span v-for="item in basicSteps" :key="item.step" :class="{'current': currentStep >= item.step}"></span>
          <span v-for="item in backgroundSteps" :key="item.step" :class="{'current': currentStep >= item.step}"></span>
        </div>

        <div v-if="!showSteps" class="mobile-progress-info">
          <div class="current-steps-container">
            <span class="steps-progress">{{ currentStep }} / <span class="total-steps">10</span> steps</span>
          </div>

          <button class="see-steps-btn" tabindex="0" @click="toggleStepVisibility">See steps
            <img src="@/assets/svgs/chevron-down-blue.svg" alt="Chevron icon">
          </button>
        </div>

        <div class="sidebar" :class="{'visible': showSteps}">
          <div class="sidebar-steps-container">
            <p class="heading-5">Steps</p>
            <ul class="steps">
              <!-- Main Step 1 -->
              <li class="step" data-step-index="1" tabindex="0">
                <div class="progress-bar"><div class="progress-bar-fill" :style="{ height: `${basicProgress}%` }"></div></div>

                <div class="step-content">
                  <span @click="goToStep(1)">Basics</span>

                  <ul class="sub-steps">
                    <li
                        class="sub-step"
                        v-for="item in basicSteps"
                        :key="item.step"
                        :class="{ active: currentStep === item.step, completed: currentStep > item.step }"
                        tabindex="0"
                        @click="goToStep(item.step)" >
                      {{ item.title }}
                    </li>
                  </ul>
                </div>
              </li>

              <!-- Main Step 2 -->
              <li class="step" data-step-index="2" tabindex="0">
                <div class="progress-bar"><div class="progress-bar-fill current" :style="{ height: `${backgroundProgress}%` }"></div></div>

                <div class="step-content">
                  <span @click="goToStep(5)">Background</span>

                  <ul class="sub-steps">
                    <li
                        class="sub-step"
                        v-for="item in backgroundSteps"
                        :key="item.step"
                        :class="{ active: currentStep === item.step, completed: currentStep > item.step }"
                        tabindex="0"
                        @click="goToStep(item.step)" >
                      {{ item.title }}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

          <div class="progress-info">
            <div class="current-steps-container">
              <span class="steps-progress">{{ currentStep }} / <span class="total-steps">10</span> steps</span>
            </div>

            <button class="hide-steps-btn" tabindex="0" @click="toggleStepVisibility">Hide steps
              <img src="@/assets/svgs/chevron-up-blue.svg" alt="Chevron icon">
            </button>
          </div>
        </div>
      </div>

      <div class="form-container">
        <form action="" method="POST" id="renter-form">
          <!-- moving to location -->
          <Step1 v-if="currentStep === 1" />
          <!-- moving dates -->
          <Step2 v-if="currentStep === 2" />
          <!-- budget -->
          <Step3 v-if="currentStep === 3" />
          <!-- who is moving with you -->
          <Step4 v-if="currentStep === 4" />
          <!-- gender -->
          <Step5 v-if="currentStep === 5" />
          <!-- languages -->
          <Step6 v-if="currentStep === 6" />
          <!-- employment -->
          <Step7 v-if="currentStep === 7" />
          <!-- hobbies -->
          <Step8 v-if="currentStep === 8" />
          <!-- about your self -->
          <Step9 v-if="currentStep === 9" />
          <!-- photo -->
          <Step10 v-if="currentStep === 10" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Step1 from "./Step1.vue"
import Step2 from "./Step2.vue"
import Step3 from "./Step3.vue"
import Step4 from "./Step4.vue"
import Step5 from "./Step5.vue"
import Step6 from "./Step6.vue"
import Step7 from "./Step7.vue"
import Step8 from "./Step8.vue"
import Step9 from "./Step9.vue"
import Step10 from "./Step10.vue"

import { useRenterProfile } from "@/stores/renter/profile/useRenterProfile";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterProfileQuestions",
  components:{
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
    Step10,
  },
  data() {
    return {
      showSteps: false
    }
  },
  computed: {
    ...mapState(useRenterProfile, [
      'currentStep', 
      'basicSteps', 
      'backgroundSteps', 
      'basicProgress', 
      'backgroundProgress'
    ])
  },
  methods: {
    ...mapActions(useRenterProfile, ['goToStep']),

    toggleStepVisibility(){
      this.showSteps = !this.showSteps;
    },
  }
};
</script>

<style scoped>
.progress-bar-fill {
  background-color: #4969AF; /* The fill color you want */
  height: 0%; /* We'll adjust this dynamically */
  transition: width 0.3s ease; /* Smooth transition */
  border-radius:10px; /* Adjust radius as needed */
}
</style>