<template>
  <div class="container flex-container verify-email mb-6">
    <svg width="162" height="161" viewBox="0 0 162 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" width="160.5" height="160.5" rx="80.25" fill="#ECF0F9" />
      <path d="M72.3785 88.5592L60.5652 76.7459C58.2906 74.4713 54.5484 74.4713 52.2738 76.7459C49.9992 79.0205 49.9992 82.7626 52.2738 85.0372L68.2328 100.996C70.5074 103.271 74.2495 103.271 76.5241 100.996L109.726 67.7942C112.001 65.5196 112.001 61.7774 109.726 59.5028C107.452 57.2282 103.709 57.2282 101.435 59.5028L72.3785 88.5592Z" fill="#4969AF" />
    </svg>

    <div>
      <h1 class="heading-6 mb-2">Your payment was submitted</h1>
      <p>Your receipt wil be sent to your email within 3-5 minutes. <br>If you have any questions, contact our customer support</p>
    </div>
  </div>
</template>


<script>
export default {
  name: "RenterVerificationPaymentStep3",
};
</script>
