import { defineStore } from 'pinia';

export const useHostDashboard = defineStore('dashboard', {
    state: () => ({
        list: [
            {
                id: Math.random(),
                name: "Room A",
                type: "room",
                status: "required",
                step: 5,
                max: 5
            },
            {
                id: Math.random(),
                name: "Room A",
                type: "room",
                status: "required",
                step: 4,
                max: 6
            },
            {
                id: Math.random(),
                name: "",
                type: "verification",
                status: "required",
                step: 3,
                max: 6
            },
        ]
    }),
});