<template>
<section aria-labelledby="budget-heading" class="card" tabindex="0">
  <div class="budget-section">
    <div class="edit-info">
      <h2 id="budget-heading" class="heading-6">Budget</h2>

      <a href="javascript:void(0)" class="edit-link" @click="openModal">
        <img src="@/assets/svgs/edit.svg" alt="edit">
        Edit
      </a>
    </div>

    <p>$ {{ formData.renter_looking_for_price }}/month</p>
  </div>

  <div v-show="showModal" class="modal-overlay show" @click.self="closeModal">
    <div class="modal-container">
      <div class="modal-header">
        <p>Budget</p>
        <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
      </div>

      <!-- Integrated Form in Modal -->
      <div class="form-container">
        <h2 class="heading-4">Budget details</h2>

        <form id="screening-details-form" @submit.prevent="submitForm">
          <div class="form-group budget-container">
            <input
              v-model="price"
              type="number" 
              name="budget" 
              id="budget" 
              min="0"
              placeholder="$..." 
              required />

            <div id="budgetErrorText" class="validation-error">
              <span v-if="errors.budget">{{ errors.budget[0] }}</span>
            </div>

            <p class="text-faded text-align-center">Your price should include utilities</p>
          </div>

          <div class="form-actions sticky-btm">
            <button type="submit" class="primary-btn">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import { useRenterProfile } from "@/stores/renter/useRenterProfile";
import { mapState, mapActions } from "pinia";

export default {
  name: "ProfileLookingForPrice",
  data() {
    return {
      showModal: false,
      price: 0,
    };
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'errors'])
  },
  methods: {
    ...mapActions(useRenterProfile, ['validateBudget', 'clearErrors']),

    openModal() {
      this.price = this.formData.renter_looking_for_price;
      document.body.style.overflow = "hidden"; // Prevent background scroll
      this.showModal = true;
    },
    closeModal() {
      this.clearErrors();
      this.showModal = false;
      document.body.style.overflow = "";
      this.price = 0;
    },

    submitForm() {
      const isValid = this.validateBudget(this.price);
      if(!isValid) return;
      //TODO: Save data
      
      this.formData.renter_looking_for_price = this.price;
      this.closeModal()
    },
  },
};
</script>