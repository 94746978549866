<template>
<section aria-labelledby="looking-in-heading" class="card" tabindex="0">
  <div class="looking-in-section">
    <div class="edit-info">
      <h2 id="looking-in-heading" class="heading-6">Looking in</h2>

      <a href="javascript:void(0)" class="edit-link" @click="openModal">
        <img src="@/assets/svgs/edit.svg" alt="edit">
        Edit
      </a>
    </div>

    <p>{{ formData.renter_looking_in.address }}</p>
  </div>

  <div v-show="showModal" class="modal-overlay show" @click.self="closeModal">
    <div class="modal-container">
      <div class="modal-header">
        <p>Looking in</p>
        <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
      </div>

      <!-- Integrated Form in Modal -->
      <div class="form-container">
        <h2 class="heading-4">Looking in details</h2>

        <form id="screening-details-form" @submit.prevent="submitForm">
          <div class="form-group mt-5">
            <label for="address">Address</label>

            <input 
              v-model="location.address"
              type="text" 
              name="address" 
              placeholder="Start typing your address.." 
              id="address" 
              required />

            <div id="addressErrorText" class="validation-error">
              <span v-if="errors.address">{{ errors.address[0] }}</span>
            </div>

            <div class="form-group">
              <label for="city">City</label>
              <input 
                v-model="location.city"
                type="text" 
                name="city" 
                placeholder="Start typing your city.." 
                id="city" 
                required />
              <div id="cityErrorText" class="validation-error">
                <span v-if="errors.city">{{ errors.city[0] }}</span>
              </div>
            </div>

              <div>
                <label for="province">Province</label>

                <input 
                  v-model="location.province"
                  type="text" 
                  name="province" 
                  id="state" 
                  placeholer="" 
                  required />

                <div id="stateErrorText" class="validation-error">
                  <span v-if="errors.province">{{ errors.province[0] }}</span>
                </div>
              </div>

              <div>
                <label for="postalCode">Postal Code</label>

                <input
                  v-model="location.postal_code"
                  type="text" 
                  name="postalCode" 
                  placeholder="Start typing your postal code.." 
                  id="zip" 
                  required />

                <div id="zipErrorText" class="validation-error">
                  <span v-if="errors.postal_code">{{ errors.postal_code[0] }}</span>
                </div>
              </div>

            <div>
              <input 
                v-model="location.latitude"
                type="hidden" 
                name="latitude" 
                id="latitude" 
                readonly />
              <input 
                v-model="location.longitude"
                type="hidden" 
                name="longitude" 
                id="longitude" 
                readonly />
            </div>
          </div>

          <div class="form-actions sticky-btm">
            <button type="submit" class="primary-btn">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
</template>

<script>
// do not remove /* global google */
/* global google */
import { useRenterProfile } from "@/stores/renter/useRenterProfile";
import { mapState, mapActions } from "pinia";
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: "ProfileLookingIn",

  data() {
    return {
      showModal: false,
      location: {
        address: '',
        city: '',
        province: '',
        postal_code: '',
        latitude: '',
        longitude: '',
      },
      autocomplete: null,
      geocoder: null,
    };
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'errors'])
  },
  mounted() {
    const loader = new Loader({
      apiKey: 'AIzaSyCCfSrPTp0wnNNwR9cEXPO8OZXpAaXZkZ8',
      libraries: ['places'],
    });

    loader.load().then(() => {
      this.initAutocomplete();
      this.geocoder = new google.maps.Geocoder();
    });
  },
  methods: {
    ...mapActions(useRenterProfile, ['validateAddress', 'clearErrors']),

    openModal() {
      this.location = this.copyObject(this.formData.renter_looking_in);
      document.body.style.overflow = "hidden"; // Prevent background scroll
      this.showModal = true;
    },
    closeModal() {
      this.clearErrors();
      this.showModal = false;
      document.body.style.overflow = "";
      this.location = "";
    },

    submitForm() {
      const isValid = this.validateAddress(this.location);
      if(!isValid) return;
      //TODO: Save data
      
      
      this.formData.renter_looking_in = this.copyObject(this.location);
      this.closeModal()
    },

    initAutocomplete() {
      const input = document.getElementById('address');
      const options = {
        types: ['address'],
      };

      this.autocomplete = new google.maps.places.Autocomplete(input, options);
      this.autocomplete.addListener('place_changed', this.onPlaceChanged);
    },

    onPlaceChanged() {
      const place = this.autocomplete.getPlace();

      if (!place.geometry) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // Update formData directly
      this.location.address = place.formatted_address || '';
      this.location.city = '';
      this.location.province = '';
      this.location.postal_code = '';
      this.location.latitude = '';
      this.location.longitude = '';

      for (const component of place.address_components) {
        const types = component.types;
        if (types.includes('locality')) {
          this.location.city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          this.location.province = component.short_name;
        }
        if (types.includes('postal_code')) {
          this.location.postal_code = component.long_name;
        }
      }

      if (place.geometry && place.geometry.location) {
        const location = place.geometry.location;
        this.location.latitude = location.lat();
        this.location.longitude = location.lng();
      }
    },
    copyObject(object){
      return JSON.parse(JSON.stringify(object))
    }
  },
};
</script>