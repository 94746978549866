<template>
  <div class="form-step active">
    <div>
      <h1 class="heading-2">When are you looking to move in?</h1>

      <p class="font-size-medium">Pick what applies.</p>
    </div>

    <div class="form-group ios-input-fix">
      <label for="start-date">Start date</label>

      <div class="input-container">
        <flat-pickr v-model="formData.start_date" />

        <img class="input-icon calendar-icon" src="@/assets/svgs/calendar.svg" alt="calendar icon">
      </div>

      <div id="startDateErrorText" class="validation-error">
        <span v-if="errors.start_date">{{ errors.start_date[0] }}</span>
      </div>
    </div>

    <div class="form-group">
      <label for="">For how long? (optional)</label>

      <div class="flex-container custom-checkbox-container mt-3" role="group" aria-labelledby="prefered-stay-label">
        <div class="checkbox-container">
          <input
            v-model="formData.how_long"
            type="checkbox" 
            id="stay-1-month" 
            name="stay-length" 
            true-value="1 month"
            false-value="" />

          <label for="stay-1-month">
            1 month
          </label>
        </div>

        <div class="checkbox-container">
          <input
            v-model="formData.how_long"
            type="checkbox"
            id="stay-3-months"
            name="stay-length"
            true-value="3 month"
            false-value="" />

          <label for="stay-3-months">
            3 months
          </label>
        </div>

        <div class="checkbox-container">
          <input
            v-model="formData.how_long"
            type="checkbox"
            id="stay-6-months"
            name="stay-length"
            true-value="6 month"
            false-value="" />

          <label for="stay-6-months">
            6 months
          </label>
        </div>

        <div class="checkbox-container">
          <input
            v-model="formData.how_long"
            type="checkbox"
            id="stay-9-months"
            name="stay-length"
            true-value="9 month"
            false-value="" />

          <label for="stay-9-months">
            9 months
          </label>
        </div>

        <div class="checkbox-container">
          <input
            v-model="formData.how_long"
            type="checkbox"
            id="stay-12-months"
            name="stay-length"
            true-value="12 month"
            false-value="" />

          <label for="stay-12-months">
            12 months
          </label>
        </div>

        <div class="checkbox-container">
          <input
            v-model="formData.how_long"
            type="checkbox"
            id="stay-long-term"
            name="stay-length"
            true-value="Long term"
            false-value="" />

          <label for="stay-long-term">
            Long term
          </label>
        </div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextStep">Next</button>
    </div>
  </div>
</template>

<script>
import { useRenterProfile } from "@/stores/renter/profile/useRenterProfile";
import { mapState, mapActions } from "pinia";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  name: "RenterProfileStep2",
  components: {
    flatPickr
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'errors'])
  },
  methods: {
    ...mapActions(useRenterProfile, ['nextStep', 'prevStep']),
  }
};
</script>