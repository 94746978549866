<template>
  <div class="container flex-container next-steps-container mt-6 mb-6">
    <div class="content">
      <h1 class="heading-2">Wondering what is next?</h1>
      <p>Find your room in a few steps</p>
    </div>

    <div class="next-steps">
      <div
          v-for="(step, index) in steps"
          :key="index"
          :class="['next-steps-item', getStatusClass(index + 1)]"
      >
        <div class="next-steps-icon">
          <span>{{ index + 1 }}</span>
        </div>
        <div class="next-steps-content">
          <h4>{{ step.title }}</h4>
          <p>{{ step.statusText }}</p>
        </div>
      </div>
    </div>


    <div class="sticky-btm" v-if="currentStep === 2">
      <router-link to="/renter/listings" class="primary-btn">Check listings</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "RenterNextSteps",
  data() {
    return {
      currentStep: 1,
      steps: [
        { title: "Register your account", statusText: "21/05/2024" },
        { title: "Explore listings", statusText: "Waiting..." },
        { title: "Send booking request", statusText: "Waiting..." },
        { title: "SIGN contract", statusText: "" },
      ],
    };
  },
  mounted() {
    this.setCurrentStepFromQuery();
  },
  watch: {
    // Watch for changes in the route to update the current step if the query changes
    $route() {
      this.setCurrentStepFromQuery();
    },
  },
  methods: {
    setCurrentStepFromQuery() {
      const step = parseInt(this.$route.query.step, 10);
      this.currentStep = isNaN(step) ? 1 : step;
    },
    getStatusClass(stepNumber) {
      if (stepNumber < this.currentStep) return "completed";
      if (stepNumber === this.currentStep) return "active";
      return "";
    },
  },
};
</script>