<template>
  <div class="renter-verification-container renter-verification-step-3">
    <div class="verification-container">
      <div class="verification-content">
        <h1 class="heading-2">Verify personal details.</h1>
        <p class="intro-text">This information is collected to verify your identity, and keep your account safe.</p>

        <div class="info-note mt-4 mb-6">
          <img src="@/assets/svgs/info.svg" alt="Info">
          <p>This information is collected for Comprehensive Screening for Past Illegal Behaviour.</p>
        </div>

        <div class="form-container">
          <form id="screening-details-form" action="" method="POST">
            <div class="form-group">
              <div v-if="!editToggles.email" class="detail">
                <div>
                  <p>Email address</p>
                  <p><strong>{{ personal_info.email }}</strong></p>
                </div>

                <a href="javascript:void(0)" class="change-link secondary-btn" @click="editClicked('email')">
                  <img src="@/assets/svgs/edit.svg" alt="edit">
                  Edit
                </a>
              </div>

              <div v-if="editToggles.email">
                <label for="email">Email address <span class="required">*</span></label>
                <input
                  v-model="personal_info.email"
                  type="email" 
                  name="email" 
                  placeholder="john@acme.com" 
                  maxlength="254" 
                  id="email" 
                  required>
  
                <div v-if="errors?.email?.length" id="emailErrorText" class="validation-error">{{ errors.email[0] }}</div>
              </div>
            </div>

            <div class="form-group">
              <div v-if="!editToggles.first_name" class="detail">
                <div>
                  <p>Legal first name</p>
                  <p><strong>{{ personal_info.first_name }}</strong></p>
                </div>

                <a href="javascript:void(0)" class="change-link secondary-btn" @click="editClicked('first_name')">
                  <img src="@/assets/svgs/edit.svg" alt="edit">
                  Edit
                </a>
              </div>

              <div v-if="editToggles.first_name">
                <label for="first-name">Legal first name <span class="required">*</span></label>
                <input
                  v-model="personal_info.first_name"
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  maxlength="30"
                  id="firstName"
                  required>
                <div v-if="errors?.first_name?.length" id="firstNameErrorText" class="validation-error">{{ errors.first_name[0] }}</div>
              </div>
            </div>

            <div class="form-group">
              <div v-if="!editToggles.last_name" class="detail">
                <div>
                  <p>Legal last name</p>
                  <p><strong>{{ personal_info.last_name }}</strong></p>
                </div>

                <a href="javascript:void(0)" class="change-link secondary-btn" @click="editClicked('last_name')">
                  <img src="@/assets/svgs/edit.svg" alt="edit">
                  Edit
                </a>
              </div>

              <div v-if="editToggles.last_name">
                <label for="last-name">Legal last name <span class="required">*</span></label>
                <input
                  v-model="personal_info.last_name"
                  type="text"
                  name="lastName"
                  placeholder="Enter your last name"
                  maxlength="30"
                  id="lastName"
                  required>
                <div v-if="errors?.last_name?.length" id="lastNameErrorText" class="validation-error">{{ errors.last_name[0] }}</div>
              </div>
            </div>

            <div class="form-group">
              <div v-if="!editToggles.phone" class="detail">
                <div>
                  <p>Phone number</p>
                  <p><strong>{{ personal_info.phone_number }}</strong></p>
                </div>

                <a href="javascript:void(0)" class="change-link secondary-btn" @click="editClicked('phone')">
                  <img src="@/assets/svgs/edit.svg" alt="edit">
                  Edit
                </a>
              </div>

              <div v-if="editToggles.phone">
                <label for="phone">Phone number <span class="required">*</span></label>
                <input 
                  v-model="personal_info.phone_number"
                  type="text" 
                  name="phoneNumber" 
                  placeholder="(xxx) xxx-xxxx" 
                  id="phone"
                  title="Please enter a valid Canadian phone number with at least 10 digits." 
                  required
                  @input="maskPhoneField">

                <div v-if="errors?.phone_number?.length" id="phoneErrorText" class="validation-error">{{ errors.phone_number[0] }}</div>
              </div>
            </div>

            <div class="form-group">
              <div v-if="!editToggles.gender" class="detail">
                <div>
                  <p>Gender</p>
                  <p><strong>{{ getGenderText(personal_info.gender) }}</strong></p>
                </div>

                <a href="javascript:void(0)" class="change-link secondary-btn" @click="editClicked('gender')">
                  <img src="@/assets/svgs/edit.svg" alt="edit">
                  Edit
                </a>
              </div>

              <div v-if="editToggles.gender">
                <label for="gender">Gender <span class="required">*</span></label>
                <DropdownComponent
                  :options="gender_options"
                  @select="selectGender"
                  :selected="personal_info.gender" />
                <div v-if="errors?.gender?.length" id="dropdownErrorText" class="validation-error">{{ errors.gender[0] }}</div>
              </div>
            </div>

            <div class="form-group">
              <div v-if="!editToggles.dob" class="detail">
                <div>
                  <p>Date of birth</p>
                  <p><strong>{{ personal_info.date_of_birth }}</strong></p>
                </div>

                <a href="javascript:void(0)" class="change-link secondary-btn" @click="editClicked('dob')">
                  <img src="@/assets/svgs/edit.svg" alt="edit">
                  Edit
                </a>
              </div>

              <div v-if="editToggles.dob">
                <label for="date_of_birth">Date of birth <span class="required">*</span></label>
                <input
                  v-model="personal_info.date_of_birth"
                  type="text"
                  name="dateOfBirth"
                  id="date_of_birth"
                  placeholder="dd/mm/yyyy"
                  maxlength="10"
                  required
                  @input="maskDateField" >
                <div v-if="errors?.date_of_birth?.length" id="dateOfBirthErrorText" class="validation-error">{{ errors.date_of_birth[0] }}</div>
              </div>
            </div>

            <h3 class="mt-4">Addresses</h3>

            <div v-if="!editToggles.current_address" class="form-group">
              <div class="detail">
                <div>
                  <p>Address</p>
                  <p><strong>{{ personal_info.address }}, {{ personal_info.city }}, {{ personal_info.province }}</strong></p>
                </div>

                <a href="javascript:void(0)" class="change-link secondary-btn" @click="addressEditClicked('address-current')">
                  <img src="@/assets/svgs/edit.svg" alt="edit">
                  Edit
                </a>
              </div>
            </div>

            <div v-if="editToggles.current_address" class="form-group mb-4 mt-5" tabindex="0">
              <div class="default-checkbox">
                <input
                  v-model="personal_info.same_as_listing"
                  type="checkbox"
                  id="keep-me-signed"
                  name="keep-me-signed">
                <label for="keep-me-signed">
                  <span>Current living address is the same as listing address: <strong>{{ personal_info.listing_address }}</strong> </span>
                </label>
              </div>
            </div>

            <div v-if="editToggles.current_address && !personal_info.same_as_listing" class="form-group mb-5">
              <label for="address-current">Current address <span class="required">*</span></label>
              <input
                v-model="personal_info.address"
                type="text" 
                name="address[]" 
                placeholder="Start typing your address.." 
                id="address-current"
                ref="address-current"
                required>
              <div v-if="errors?.current_address?.length" id="addressErrorText" class="validation-error">{{ errors.current_address[0] }}</div>
            </div>

            <div v-for="(address, index) in personal_info.additional_address" :key="index" class="form-group">
              <div class="detail">
                <div v-if="!editToggles.additional_address[index]">
                  <p>Address</p>
                  <p><strong>{{ address.address }}, {{ address.city }}, {{ address.province }}</strong></p>
                </div>

                <a v-if="!editToggles.additional_address[index]" href="javascript:void(0)" class="change-link secondary-btn" @click="addressEditClicked(`address-${index}`)">
                  <img src="@/assets/svgs/edit.svg" alt="edit">
                  Edit
                </a>

                <input
                  v-if="editToggles.additional_address[index]"
                  v-model="address.address"
                  type="text" 
                  name="address[]" 
                  placeholder="Start typing your address.." 
                  :id="`address-${index}`" 
                  :ref="`address-${index}`" 
                  required>
              </div>
            </div>

          </form>
        </div>

        <div class="btns-container mt-6 sticky-btm">
          <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
          <button type="submit" form="screening-details-form" class="primary-btn next-step" @click="submitClicked">Submit information</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// do not remove /* global google */
/* global google */
import { useRenterVerification } from "@/stores/renter/verification/useRenterVerification";
import { mapState, mapActions } from "pinia";
import { nextTick } from 'vue'
import { Loader } from '@googlemaps/js-api-loader';

import DropdownComponent from "@/components/DropdownComponent.vue"

export default {
  name: "RenterVerificationStep4",
  data() {
    return {
      editToggles: {
        email: false,
        first_name: false,
        last_name: false,
        phone: false,
        gender: false,
        dob: false,
        current_address: false,
        additional_address: []
      },
      loader: null,
      autocomplete: {},
    };
  },
  components: {
    DropdownComponent,
  },
  computed: {
    ...mapState(useRenterVerification, ['personal_info', 'gender_options', 'errors'])
  },
  mounted() {
    this.loader = new Loader({
      apiKey: 'AIzaSyCCfSrPTp0wnNNwR9cEXPO8OZXpAaXZkZ8',
      libraries: ['places'],
    });

    this.loader.load();

    const count = this.personal_info.additional_address.length;
    for (let index = 0; index < count; index++) {
      this.editToggles.additional_address[index] = false;
    }
  },
  methods: {
    ...mapActions(useRenterVerification, ['nextStep', 'prevStep', 'validateAll', 'getGenderText']),

    editClicked(key){
      this.editToggles[key] = !this.editToggles[key];
    },
    addressEditClicked(key){
      if(key === "address-current"){
        this.editToggles.current_address = !this.editToggles.current_address;

        nextTick(() => {
          this.initAutocomplete("address-current");
        });
      }
      else {
        const index = parseInt(key.charAt(key.length - 1));
        this.editToggles.additional_address[index] = !this.editToggles.additional_address[index];

        nextTick(() => {
          this.initAutocomplete(`address-${index}`);
        });
      }
    },
    selectGender(selectedGender) {
      this.personal_info.gender = selectedGender;
    },
    maskPhoneField() {
      let value = this.personal_info.phone_number.replace(/\D/g, ''); // Remove non-digit characters
      if (value.length > 10) value = value.slice(0, 10); // Limit to 10 digits
      this.personal_info.phone_number = this.formatPhoneField(value);
    },
    formatPhoneField(value) {
        const areaCode = value.slice(0, 3);
        const middle = value.slice(3, 6);
        const last = value.slice(6, 10);

        if (value.length > 6) {
          return `(${areaCode}) ${middle}-${last}`;
        } 
        else if (value.length > 3) {
          return `(${areaCode}) ${middle}`;
        } 
        else if (value.length > 0) {
          return `(${areaCode}`;
        }

        return value;
    },
    maskDateField() {
      let value = this.personal_info.date_of_birth.replace(/\D/g, ''); // Remove non-digit characters
      if (value.length > 8) value = value.slice(0, 8); // Limit to 8 digits
      this.personal_info.date_of_birth = this.formatDateField(value);
    },
    formatDateField(value) {
        const day = value.slice(0, 2);
        const month = value.slice(2, 4);
        const year = value.slice(4, 8);

        if (value.length > 4) {
          return `${day}/${month}/${year}`;
        } 
        else if (value.length > 2) {
          return `${day}/${month}`;
        } 

        return value;
    },
    

    initAutocomplete(ref) {
      const currentRef = this.$refs[ref];
      const input = Array.isArray(currentRef) ? currentRef[0] : currentRef;

      const options = {
        types: ['address'],
      };

      this.autocomplete[ref] = new google.maps.places.Autocomplete(input, options);
      this.autocomplete[ref].addListener('place_changed', () => { this.onPlaceChanged(ref) });
    },
    onPlaceChanged(ref) {
      const place = this.autocomplete[ref].getPlace();
      if (!place.geometry) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // Update formData directly
      const location = {
        address: place.formatted_address || '',
        city: '',
        province: '',
        postal_code: '',
      }

      for (const component of place.address_components) {
        const types = component.types;
        if (types.includes('locality')) {
          location.city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          location.province = component.short_name;
        }
        if (types.includes('postal_code')) {
          location.postal_code = component.long_name;
        }
      }

      if(ref === "address-current"){
        this.personal_info.address = location.address;
        this.personal_info.city = location.city;
        this.personal_info.province = location.province;
        this.personal_info.postal_code = location.postal_code;
      }
      else {
        const index = parseInt(ref.charAt(ref.length - 1));
        this.personal_info.additional_address[index] = location;
      }
    },

    submitClicked(){
      const isValid = this.validateAll();
      if(!isValid) return;

      //TODO

      this.nextStep();
    }
  },
};
</script>