<template>
  <div class="renter-verification-container renter-verification-step-1">
    <div class="container flex-container">
      <div class="illustration-container">
        <img src="@/assets/svgs/signup-illustration.svg" alt="Happipad Logo">
      </div>

      <div class="verification-container">
        <div class="benefits">
          <div class="benefit-item">
            <div class="icon">
              <img src="@/assets/svgs/checkmark-in-circle.svg" alt="Checkmark">
            </div>
            <p class="benefit-item-title">Verified</p>
            <p>hosts only</p>
          </div>
          <div class="benefit-item">
            <div class="icon">
              <img src="@/assets/svgs/checkmark-in-circle.svg" alt="Checkmark">
            </div>
            <p class="benefit-item-title">Secure</p>
            <p>payments</p>
          </div>
          <div class="benefit-item">
            <div class="icon">
              <img src="@/assets/svgs/checkmark-in-circle.svg" alt="Checkmark">
            </div>
            <p class="benefit-item-title">Flexible</p>
            <p>contracts</p>
          </div>
          <div class="benefit-item">
            <div class="icon">
              <img src="@/assets/svgs/checkmark-in-circle.svg" alt="Checkmark">
            </div>
            <p class="benefit-item-title">24/7</p>
            <p>customer support</p>
          </div>
        </div>

        <div class="verification-content">
          <h1 class="heading-2">Start your verification.</h1>
          <p class="intro-text">This step helps establish secure space for our customers.</p>

          <div class="verification-steps mt-6">
            <div class="step">
              <img src="@/assets/svgs/card.svg" alt="card">
              <div class="flex-container">
                <p><strong>Payment</strong></p>
                <p>One-time payment is required to process.</p>
              </div>
            </div>

            <div class="step">
              <img src="@/assets/svgs/personal-info.svg" alt="Personal info">
              <div class="flex-container">
                <p><strong>Personal info</strong></p>
                <p>Legal name, date of birth, addresses for the last 5 years, phone number.</p>
              </div>
            </div>

            <div class="step">
              <img src="@/assets/svgs/id.svg" alt="ID">
              <div class="flex-container">
                <p><strong>Your ID</strong></p>
                <p>Passport or driving licence.</p>
              </div>
            </div>

            <div class="step">
              <img src="@/assets/svgs/mobile.svg" alt="Mobile">
              <div class="flex-container">
                <p><strong>Selfie</strong></p>
                <p>Photo of you in real time.</p>
              </div>
            </div>
          </div>

          <div class="verification-info">
            <input type="checkbox" id="toggle-verification-info" class="toggle-checkbox">
            <label for="toggle-verification-info" class="toggle-checkbox-btn">
              Learn how we verify
              <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2071 0.292893C14.5976 0.683418 14.5976 1.31658 14.2071 1.70711L8.20711 7.70711C7.81658 8.09763 7.18342 8.09763 6.79289 7.70711L0.792892 1.70711C0.402368 1.31658 0.402369 0.683417 0.792892 0.292893C1.18342 -0.0976317 1.81658 -0.0976317 2.20711 0.292893L7.5 5.58579L12.7929 0.292893C13.1834 -0.0976312 13.8166 -0.0976311 14.2071 0.292893Z" fill="#4869AF" />
              </svg>
            </label>

            <div class="verification-details">
              <h2 class="heading-6">1. <strong>Identity Verification through Real-Time Photo Comparison with Government-Issued Identification:</strong></h2>
              <p>The identity verification process involves a sophisticated method of confirming an individual’s identity by comparing a real-time photograph with the image on a government-issued identification document. This procedure is critical for ensuring that the person presenting the ID is indeed the rightful owner and that the identification document itself is authentic and unaltered.</p>

              <h2 class="heading-6 mt-4">2. <strong>Comprehensive Screening for Past Illegal Behavior, Criminal Activity, and Fraud Incidents:</strong></h2>
              <p>The second process involves a detailed background screening designed to identify any past illegal behavior, criminal activity, or incidents of fraud associated with an individual. This is particularly important in contexts where security, trust, and compliance are paramount, such as employment in sensitive industries, financial services, or access to privileged information.</p>
            </div>
          </div>

          <div class="info-note mt-4">
            <img src="@/assets/svgs/info.svg" alt="Info">
            <p>Verification is provided by our Partner Certn and all Information in private and encrypted.</p>
          </div>

          <div class="btns-container mt-6 sticky-btm">
            <button type="button" class="secondary-btn prev-step" @click="exitClicked">Save and exit</button>
            <button type="button" class="primary-btn next-step" @click="nextStep">I’m ready to start</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useRenterVerification } from "@/stores/renter/verification/useRenterVerification";
import { mapActions } from "pinia";

export default {
  name: "RenterVerificationStep1",
  methods: {
    ...mapActions(useRenterVerification, ['nextStep', 'resetStep']),

    exitClicked(){
      this.resetStep();
      this.$router.push('/');
    }
  }
};
</script>