import { defineStore } from 'pinia';

export const useDashboardSecurity = defineStore('security', {
    state: () => ({
        newPassword: {
            show: false,
            password: '',
            showPlainText: false,
            otp: '',
            keepMeLoggedIn: false
        },
        deleteAccount: {
            show: false,
            reasons: [],
        }
    }),
    actions: {
        updateDeleteReasons(reason) {
            let reasonList = this.deleteAccount.reasons;
            const isReasonInList = reasonList.indexOf(reason) >= 0;

            if (isReasonInList) {
                reasonList = reasonList.filter((r) => r !== reason);
            }
            else {
                reasonList.push(reason);
            }

            this.deleteAccount.reasons = reasonList;
        },
    },
});