<template>
  <div class="renter-verification-container renter-verification-step-4">
    <div class="verification-container">
      <div class="verification-content">
        <h1 class="heading-2">Let’s verify your ID.</h1>
        <p class="intro-text">Select one o the following documents which you can submit:</p>

        <div class="form-container">
          <form id="id-selection-form" action="" method="POST">
            <div class="form-group">
              <div class="default-radio-btns">
                <input
                  v-model="document_type"
                  type="radio"
                  id="driver-license"
                  name="id-type"
                  value="driver-license"
                  @input="changeDocumentType('driver-license')" />
                <label for="driver-license">Driver license</label>
              </div>

              <div class="default-radio-btns">
                <input
                  v-model="document_type"
                  type="radio"
                  id="healthcare-insurance-card"
                  name="id-type"
                  value="healthcare-insurance-card"
                  @input="changeDocumentType('healthcare-insurance-card')" />
                <label for="healthcare-insurance-card">Healthcare insurance card</label>
              </div>

              <div class="default-radio-btns">
                <input
                  v-model="document_type"
                  type="radio"
                  id="national-id"
                  name="id-type"
                  value="national-id"
                  @input="changeDocumentType('national-id')" />
                <label for="national-id">National ID</label>
              </div>

              <div class="default-radio-btns">
                <input
                  v-model="document_type"
                  type="radio"
                  id="passport"
                  name="id-type"
                  value="passport"
                  @input="changeDocumentType('passport')" />
                <label for="passport">Passport</label>
              </div>

              <div class="default-radio-btns">
                <input
                  v-model="document_type"
                  type="radio"
                  id="permanent-resident-card"
                  name="id-type"
                  value="permanent-resident-card"
                  @input="changeDocumentType('permanent-resident-card')" />
                <label for="permanent-resident-card">Permanent resident card</label>
              </div>

              <div class="default-radio-btns">
                <input
                  v-model="document_type"
                  type="radio"
                  id="other"
                  name="id-type"
                  value="other"
                  @input="changeDocumentType('other')" />
                <label for="other">Other</label>
              </div>
            </div>
          </form>
        </div>

        <div class="btns-container mt-6 sticky-btm">
          <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
          <button type="button" form="id-selection-form" class="primary-btn next-step" :class="{'btn-disabled': !hasDocumentType}" :disabled="!hasDocumentType" @click="submitClicked">Submit information</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useRenterVerification } from "@/stores/renter/verification/useRenterVerification";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterVerificationStep5",
  computed: {
    ...mapState(useRenterVerification, ['document_type', 'hasDocumentType'])
  },
  methods: {
    ...mapActions(useRenterVerification, ['nextStep', 'prevStep', 'changeDocumentType']),
    
    submitClicked(){
      if(!this.hasDocumentType) return;
      //TODO

      this.nextStep();
    }
  }
};
</script>