<template>
<HostDashboardTopNav />

<div class="host-dashboard">
  <div class="contracts container flex-container">
    <HostDashboardSidebar />

    <div class="dashboard-content">
      <header class="mb-2">
        <h1 class="heading-2">Contracts</h1>

        <p class="hidden-desktop" id="table-title">Showing {{ getContracts.length }} of {{ contracts_list.length }}</p>
      </header>

      <div class="cards-container card">
        <!-- Notification Section -->
        <div v-for="item in requires_action" :key="item" class="info-note hidden-mobile mt-4">
          <img :src="getAsset('contract.svg')" alt="contract">

          <div class="info-note-content">
            <p class="heading-6">Action required!</p>

            <p>Your contract was updated. <a href="javascript:void(0)" @click="signItClicked">Sign it!</a></p>
          </div>
        </div>

        <!-- Table Section -->
        <section v-if="contracts_list.length > 0" class="mt-4 mb-4" aria-labelledby="table-title">
          <p class="hidden-mobile" id="table-title">Showing {{ getContracts.length }} of {{ contracts_list.length }}</p>

          <div class="dashboard-table-container">
            <table>
              <thead>
                <tr>
                  <th class="hidden-desktop" scope="col">Contract 1</th>
                  <th scope="col">Room</th>
                  <th scope="col">Renter</th>
                  <th scope="col">Dates</th>
                  <th scope="col">Payment</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in getContracts" :key="item.contract_id">
                  <td class="hidden-desktop">
                    <div data-label="Contract 1"></div>
                    <a href="javascript:void(0)" @click="detailsClicked">
                      <img :src="getAsset('eye-white.svg')" alt="view">
                      View
                    </a>
                  </td>

                  <td data-label="Room">
                    <div>
                      <img :src="item.room_picture" alt="Room A thumbnail" />
                      {{ item.room_name }}
                    </div>
                  </td>

                  <td data-label="Renter">
                    <div>
                      <img :src="item.renter_picture" alt="Renter profile" />
                      {{ item.renter_name }}
                    </div>
                  </td>

                  <td data-label="Dates">
                    <div>
                      {{ getFormattedDate(item.from_date) }} - {{ getFormattedDate(item.to_date) }}
                    </div>
                  </td>

                  <td data-label="Payment">${{ item.payment }}</td>

                  <td class="hidden-desktop" data-label="Contract ID">{{ item.contract_id }}</td>

                  <td class="hidden-mobile">
                    <div>
                      <button class="tertiary-btn" aria-label="details" @click="detailsClicked(item.contract_id)">
                        <img :src="getAsset('eye.svg')" alt="" />
                      </button>

                      <a href="javascript:void(0)" class="tertiary-btn" @click="downloadClicked(item.contract_id)">Download</a>
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

          <a v-if="!show_full_list && getMoreCount > 0" href="javascript:void(0)" class="view-more mt-4" @click="toggleShowFullList()">Show {{ getMoreCount }} more
            <img src="@/assets/svgs/chevron-down-blue.svg" alt="chevron down">
          </a>
          <a v-if="show_full_list" href="javascript:void(0)" class="view-more mt-4" @click="toggleShowFullList()">Show less
            <img src="@/assets/svgs/chevron-up-blue.svg" alt="chevron up">
          </a>
        </section>

        <div v-if="contracts_list.length === 0" class="listing-card listing-card--empty">
          <p>Hey {{ host_name }}! No contracts signed.</p>

          <img :src="getAsset('no-contracts.svg')" alt="">

          <router-link class="tertiary-btn" to="/host/find-renter">
            <img :src="getAsset('renters.svg')" alt="renters">
            Find renters
          </router-link>
        </div>
      </div>
    </div>

    <HostDashboardBottomNav />
  </div>
</div>
</template>

<script>
import HostDashboardTopNav from "@/components/HostDashboardTopNav.vue";
import HostDashboardSidebar from "@/components/HostDashboardSidebar.vue";
import HostDashboardBottomNav from "@/components/HostDashboardBottomNav.vue";
import { useHostContracts } from "@/stores/host/dashboard/useHostContracts";
import { mapState, mapActions } from "pinia";

export default {
  name: "HostContracts",

  components: {
    HostDashboardTopNav,
    HostDashboardSidebar,
    HostDashboardBottomNav
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState(useHostContracts, ['show_full_list', 'host_name', 'requires_action', 'contracts_list', 'getContracts', 'getMoreCount'])
  },
  methods: {
    ...mapActions(useHostContracts, ['toggleShowFullList']),

    getAsset(filename) {
      return require(`@/assets/svgs/${filename}`);
    },
    getFormattedDate(isoDate) {
      const splitDate = isoDate.split('-');
      const year = splitDate[0];
      const month = this.monthNumberToText(splitDate[1]);
      const day = parseInt(splitDate[2]);
      return `${month} ${day}, ${year}`;
    },
    monthNumberToText(value) {
      const monthNumber = parseInt(value);

      switch (monthNumber) {
        case 1:
          return 'Jan';
        case 2:
          return 'Feb';
        case 3:
          return 'Mar';
        case 4:
          return 'Apr';
        case 5:
          return 'May';
        case 6:
          return 'Jun';
        case 7:
          return 'Jul';
        case 8:
          return 'Aug';
        case 9:
          return 'Sep';
        case 10:
          return 'Oct';
        case 11:
          return 'Nov';
        case 12:
          return 'Dec';
        default:
          return '';
      }
    },
    detailsClicked() {
      //TODO
      alert("This should view contract PDF");
    },
    downloadClicked() {
      //TODO
      alert("This should download contract PDF");
    },
    signItClicked(){
      //TODO
      alert("This should direct to a unsigned contract");
    }
  }
};
</script>