<template>
  <HostDashboardTopNav />

  <div class="renter-dashboard help-dashboard">
    <div class="main-dashboard container flex-container">
      <HostDashboardSidebar />

      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-2">Help 24/7</h1>
        </header>

        <div class="cards-container flex-container">
          <a href="#" class="card chat-container">
            <h2 class="heading-6">Contact support</h2>

            <p class="chat-date mt-4 mb-6 text-align-center">Today, June 19</p>

            <div class="chatbot-wrapper">
              <div class="chatbot-icon">
                <img src="@/assets/images/happipad-chat.png" alt="happiad" />
              </div>

              <div class="chatbot-message">
                <p>Hi! It’s Robert, Happipad chatbot. What can I help you with?</p>
              </div>
            </div>

            <button class="secondary-btn mt-4" @click="startChatClicked">Start chat</button>
          </a>

          <a href="javascript:void(0)" class="card faq-container">
            <h2 class="heading-6">FAQ</h2>

            <div class="faq-accordion">
              <div class="faq-item" v-for="(item, index) in faqItems" :key="index">
                <div class="faq-question" @click="toggleAccordion(index, $event)">
                  <p><strong>{{ item.question }}</strong></p>
                  <span class="arrow" :class="{ open: isOpen(index) }">
                    <img src="@/assets/svgs/chevron-down-dark.svg" alt="chevron down">
                  </span>
                </div>

                <div class="faq-answer" v-show="isOpen(index)">
                  <p>{{ item.answer }}</p>
                </div>
              </div>
            </div>

            <button class="view-more mt-4">Load more topics
              <img src="@/assets/svgs/chevron-down-blue.svg" alt="chevron down">
            </button>
          </a>
        </div>
      </div>

      <HostDashboardBottomNav />
    </div>
  </div>
</template>


<script>
import HostDashboardSidebar from "@/components/HostDashboardSidebar.vue";
import HostDashboardBottomNav from "@/components/HostDashboardBottomNav.vue";
import HostDashboardTopNav from "@/components/HostDashboardTopNav.vue";

export default {
  name: "HostSupport",

  components: {
    HostDashboardSidebar,
    HostDashboardBottomNav,
    HostDashboardTopNav
  },
  data() {
    return {
      activeIndex: null,
      faqItems: [
        { question: "Renting process", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." },
        { question: "Posting your listing", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." },
        { question: "Expired link during verification", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." },
        { question: "Can’t get access to my account", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." },
        { question: "Issues during logging in", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." },
        { question: "Learn more about Happipad", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." }
      ]
    };
  },
  methods: {
    toggleAccordion(index, event) {
      // Prevent the default click behavior
      if (event) event.preventDefault();

      // Toggle the accordion index
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    isOpen(index) {
      return this.activeIndex === index;
    },
    startChatClicked(){
      //TODO
      alert("This should go to a chat");
    }
  }
};
</script>