<template>
  <RenterDashboardTopNav />
  <FindRenterSearch />

  <div class="host-dashboard find-renter-dashboard">
    <div class="main-dashboard container flex-container">
      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-4">Ottawa, ON</h1>
          <p>Showing 255 renters</p>
        </header>

        <div class="cards-container flex-container">
          <div class="card profile-card">
            <div class="profile-section">
              <section class="profile-header" aria-labelledby="profile-heading">
                <div tabindex="0" aria-labelledby="profile-name">
                  <div
                    class="profile-header__bg"
                    :style="{ backgroundImage: `url(${require('@/assets/images/profile-background-placeholder.jpeg')})` }"
                  >
                    <img src="@/assets/images/profile-user-placeholder.jpeg" alt="profile picture" class="profile-image">
                  </div>

                  <div class="profile-header__info">
                    <p class="heading-5" id="profile-name">John K, 54 </p>

                    <p class="profile-description" tabindex="0">
                      As a single dad working full time. Looking for someone who would be...
                    </p>

                    <div class="additional-info" tabindex="0">
                      <p>
                        <img src="@/assets/svgs/profile-gender-female.svg" alt="female">
                        Male
                      </p>

                      <p>
                        <img src="@/assets/svgs/profile-couple.svg" alt="single">
                        1
                      </p>

                      <p>
                        <img src="@/assets/svgs/money-outline.svg" alt="money">
                        $1600
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div class="card profile-card">
            <div class="profile-section">
              <section class="profile-header" aria-labelledby="profile-heading">
                <div tabindex="0" aria-labelledby="profile-name">
                  <div
                    class="profile-header__bg"
                    :style="{ backgroundImage: `url(${require('@/assets/images/profile-background-placeholder.jpeg')})` }"
                  >
                    <img src="@/assets/images/profile-user-placeholder.jpeg" alt="profile picture" class="profile-image">
                  </div>

                  <div class="profile-header__info">
                    <p class="heading-5" id="profile-name">John K, 54 </p>

                    <p class="profile-description" tabindex="0">
                      As a single dad working full time. Looking for someone who would be...
                    </p>

                    <div class="additional-info" tabindex="0">
                      <p>
                        <img src="@/assets/svgs/profile-gender-female.svg" alt="female">
                        Male
                      </p>

                      <p>
                        <img src="@/assets/svgs/profile-couple.svg" alt="single">
                        1
                      </p>

                      <p>
                        <img src="@/assets/svgs/money-outline.svg" alt="money">
                        $1600
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div class="card profile-card">
            <div class="profile-section">
              <section class="profile-header" aria-labelledby="profile-heading">
                <div tabindex="0" aria-labelledby="profile-name">
                  <div
                    class="profile-header__bg"
                    :style="{ backgroundImage: `url(${require('@/assets/images/profile-background-placeholder.jpeg')})` }"
                  >
                    <img src="@/assets/images/profile-user-placeholder.jpeg" alt="profile picture" class="profile-image">
                  </div>

                  <div class="profile-header__info">
                    <p class="heading-5" id="profile-name">John K, 54 </p>

                    <p class="profile-description" tabindex="0">
                      As a single dad working full time. Looking for someone who would be...
                    </p>

                    <div class="additional-info" tabindex="0">
                      <p>
                        <img src="@/assets/svgs/profile-gender-female.svg" alt="female">
                        Male
                      </p>

                      <p>
                        <img src="@/assets/svgs/profile-couple.svg" alt="single">
                        1
                      </p>

                      <p>
                        <img src="@/assets/svgs/money-outline.svg" alt="money">
                        $1600
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div class="card profile-card">
            <div class="profile-section">
              <section class="profile-header" aria-labelledby="profile-heading">
                <div tabindex="0" aria-labelledby="profile-name">
                  <div
                    class="profile-header__bg"
                    :style="{ backgroundImage: `url(${require('@/assets/images/profile-background-placeholder.jpeg')})` }"
                  >
                    <img src="@/assets/images/profile-user-placeholder.jpeg" alt="profile picture" class="profile-image">
                  </div>

                  <div class="profile-header__info">
                    <p class="heading-5" id="profile-name">John K, 54 </p>

                    <p class="profile-description" tabindex="0">
                      As a single dad working full time. Looking for someone who would be...
                    </p>

                    <div class="additional-info" tabindex="0">
                      <p>
                        <img src="@/assets/svgs/profile-gender-female.svg" alt="female">
                        Male
                      </p>

                      <p>
                        <img src="@/assets/svgs/profile-couple.svg" alt="single">
                        1
                      </p>

                      <p>
                        <img src="@/assets/svgs/money-outline.svg" alt="money">
                        $1600
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div class="card profile-card">
            <div class="profile-section">
              <section class="profile-header" aria-labelledby="profile-heading">
                <div tabindex="0" aria-labelledby="profile-name">
                  <div
                    class="profile-header__bg"
                    :style="{ backgroundImage: `url(${require('@/assets/images/profile-background-placeholder.jpeg')})` }"
                  >
                    <img src="@/assets/images/profile-user-placeholder.jpeg" alt="profile picture" class="profile-image">
                  </div>

                  <div class="profile-header__info">
                    <p class="heading-5" id="profile-name">John K, 54 </p>

                    <p class="profile-description" tabindex="0">
                      As a single dad working full time. Looking for someone who would be...
                    </p>

                    <div class="additional-info" tabindex="0">
                      <p>
                        <img src="@/assets/svgs/profile-gender-female.svg" alt="female">
                        Male
                      </p>

                      <p>
                        <img src="@/assets/svgs/profile-couple.svg" alt="single">
                        1
                      </p>

                      <p>
                        <img src="@/assets/svgs/money-outline.svg" alt="money">
                        $1600
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <RenterDashboardBottomNav />
    </div>
  </div>
</template>

<script>
  // needs to be replaced with host navigation content
  import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
  import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

  import FindRenterSearch from "@/components/FindRenterSearch.vue";


  export default {
    name: "HostFindRenter",
    components: {
      // needs to be replaced with host navigation content
      RenterDashboardBottomNav,
      RenterDashboardTopNav,
      FindRenterSearch,
    },
  };
</script>