import { defineStore } from 'pinia';

export const useRenterDashboard = defineStore('dashboard', {
    state: () => ({
        formData: {
            steps: [
                {
                    status: 'required',
                    type: 'pay_rent',
                    date: '2024-12-01',
                },
                {
                    status: 'required',
                    type: 'upload_insurance',
                    date: '2024-12-01'
                },
            ]
        }
    }),
});