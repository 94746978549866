<template>
  <nav class="dashboard-nav" :class="{ 'active': isSidebarOpen }" aria-label="Main Navigation">
  <div class="flex-container">
    <div class="left-col">
      <router-link to="/" class="logo">
        <img :src="getAsset('Happipad_Logo_Dark.svg')" alt="Happipad Logo">
      </router-link>
    </div>

    <div class="right-col">
      <div class="nav-links">
        <router-link to="/host/dashboard/messages" class="hidden-mobile" activeClass="active">
          <img :src="getAsset('messages-circle.svg')" alt="messages">
          Messages
        </router-link>

        <router-link to="/host/dashboard/notifications" class="hidden-mobile" activeClass="active">
          <div class="notifications-available">
            <img :src="getAsset('notification-circle.svg')" alt="notification">
            <span v-if="has_notifications" class="notification-dot"></span>
          </div>
          Notifications
        </router-link>

        <a href="javascript:void(0)" id="profile-sidebar-toggle" :class="{ 'active': isSidebarOpen }" @click="toggleSidebar">
          <div class="profile-info" :class="{ 'hidden': isSidebarOpen }">
            <img :src="getAsset('profile-circle.svg')" alt="notification">
            {{ host_name }}

            <img :src="getAsset('chevron-down-dark.svg')" alt="chevron">
          </div>

          <img class="close-icon" :class="{ 'hidden': !isSidebarOpen }" :src="getAsset('close.svg')" alt="close">
        </a>

        <!-- Sidebar -->
        <div class="dashboard-sidebar-nav hidden-desktop" :class="{ 'active': isSidebarOpen }" id="sidebar">
          <DashboardSidebar />
        </div>
      </div>

      <router-link to="/host/find-renter" class="primary-btn hidden-mobile">Find renters</router-link>
    </div>
  </div>
</nav>
</template>

<script>
import DashboardSidebar from "@/components/HostDashboardSidebar.vue";
import { useHostTopNav } from "@/stores/host/dashboard/useHostTopNav";
import { mapState } from "pinia";

export default {
  name: "HostDashboardTopNav",

  components: {
    DashboardSidebar,
  },

  data() {
    return {
      isSidebarOpen: false,
    };
  },

  computed: {
    ...mapState(useHostTopNav, ['host_name', 'has_notifications'])
  },

  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    getAsset(filename) {
      return require(`@/assets/svgs/${filename}`);
    },
  }
};
</script>
