// router.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/host/Signup/Signup.vue';
import HostProfile from '@/views/host/profile/Create.vue';
import HostDashboard from '@/views/host/dashboard/Dashboard.vue';
import HostDashboardProfile from '@/views/host/dashboard/Profile.vue';
import HostDashboardMessages from '@/views/host/dashboard/Messages.vue';
import HostDashboardSupport from '@/views/host/dashboard/Support.vue';
import HostDashboardSettings from '@/views/host/dashboard/Settings.vue';
import HostDashboardNotificationControl from '@/views/host/dashboard/NotificationControl.vue';
import HostDashboardNotifications from '@/views/host/dashboard/Notifications.vue';
import HostDashboardSecurity from '@/views/host/dashboard/Security.vue';
import HostApplications from '@/views/host/dashboard/Applications.vue';
import HostListings from '@/views/host/dashboard/Listings.vue';
import HostInvoices from '@/views/host/dashboard/Invoices.vue';
import HostContracts from '@/views/host/dashboard/Contracts.vue';
import CreateListing from '@/views/host/listing/Create.vue';
import VerifyAccount from '@/views/host/Signup/VerifyAccount.vue';
import AccountReady from '@/views/host/Signup/AccountReady.vue';
import NextSteps from '@/views/host/Signup/NextSteps.vue';

import Login from '@/views/auth/Login.vue';
import RecoverPassword from "@/views/auth/RecoverPassword.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";

import RenterDashboard from "@/views/renter/dashboard/RenterDashboard.vue";
import RenterProfile from "@/views/renter/dashboard/RenterProfile.vue";
import RenterSettings from "@/views/renter/dashboard/RenterSettings.vue";
import RenterSecurity from "@/views/renter/dashboard/RenterSecurity.vue";
import RenterNotificationControl from "@/views/renter/dashboard/RenterNotificationControl.vue";
import RenterReceipts from "@/views/renter/dashboard/RenterReceipts.vue";
import RenterHelp from "@/views/renter/dashboard/RenterHelp.vue";
import RenterMyStay from "@/views/renter/dashboard/RenterMyStay.vue";
import RenterInsurance from "@/views/renter/dashboard/RenterInsurance.vue";
import RenterSaved from "@/views/renter/dashboard/RenterSaved.vue";
import RenterMessages from "@/views/renter/dashboard/RenterMessages.vue";
import RenterNotifications from "@/views/renter/dashboard/RenterNotifications.vue";

import HostFindRenter from "@/views/host/dashboard/HostFindRenter.vue";
import FindListings from "@/views/shared/FindListings.vue";
import FindListingsLoggedIn from "@/views/shared/FindListingsLoggedIn.vue";
import RenterCreateProfile from "@/views/renter/signup/CreateProfile.vue";
import RenterNextSteps from "@/views/renter/signup/NextSteps.vue";
import RenterVerifyEmail from "@/views/renter/signup/VerifyEmail.vue";
import RenterProfileQuestions from "@/views/renter/profile/ProfileQuestions.vue";

import RenterVerification from "@/views/renter/verification/Verification.vue";
import RenterVerificationLink from "@/views/renter/verification/Link.vue";
import RenterVerificationPayment from "@/views/renter/verification/payment/Payment.vue";

import SingleListing from "@/views/shared/SingleListing.vue";


const routes = [
  {
    path: '/host',
    children: [
      {
        path: 'signup',
        name: 'Home',
        component: Home,
      },
      {
        path: 'profile/create',
        name: 'HostProfile',
        component: HostProfile,
        meta: { requiresAuth: true },
      },
      {
        path: 'listing/create',
        name: 'CreateListing',
        component: CreateListing,
        meta: { requiresAuth: true },
      },
      {
        path: 'listing/properties/:id/edit',
        name: 'EditListing',
        component: CreateListing,
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: 'listing/properties/:id',
        name: 'ShowListing',
        component: CreateListing,
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: 'find-renter',
        name: 'HostFindRenter',
        component: HostFindRenter,
      },
      {
        path: 'dashboard',
        name: 'HostDashboard',
        component: HostDashboard,
      },
      {
        path: 'dashboard/applications',
        name: 'HostApplications',
        component: HostApplications,
      },
      {
        path: 'dashboard/listings',
        name: 'HostListings',
        component: HostListings,
      },
      {
        path: 'dashboard/invoices',
        name: 'HostInvoices',
        component: HostInvoices,
      },
      {
        path: 'dashboard/contracts',
        name: 'HostContracts',
        component: HostContracts,
      },
      {
        path: 'dashboard/profile',
        name: 'HostDashboardProfile',
        component: HostDashboardProfile,
      },
      {
        path: 'dashboard/messages',
        name: 'HostDashboardMessages',
        component: HostDashboardMessages,
      },
      {
        path: 'dashboard/support',
        name: 'HostDashboardSupport',
        component: HostDashboardSupport,
      },
      {
        path: 'dashboard/settings',
        name: 'HostDashboardSettings',
        component: HostDashboardSettings,
      },
      {
        path: 'dashboard/security',
        name: 'HostDashboardSecurity',
        component: HostDashboardSecurity,
      },
      {
        path: 'dashboard/notification-control',
        name: 'HostDashboardNotificationControl',
        component: HostDashboardNotificationControl,
      },
      {
        path: 'dashboard/notifications',
        name: 'HostDashboardNotifications',
        component: HostDashboardNotifications,
      },
    ],
  },
  {
    path: '/renter',
    children: [
      {
        path: 'dashboard',
        name: 'RenterDashboard',
        component: RenterDashboard,
      },
      {
        path: 'profile',
        name: 'RenterProfile',
        component: RenterProfile,
      },
      {
        path: 'settings',
        name: 'RenterSettings',
        component: RenterSettings,
      },
      {
        path: 'security',
        name: 'RenterSecurity',
        component: RenterSecurity,
      },
      {
        path: 'notification-control',
        name: 'RenterNotificationControl',
        component: RenterNotificationControl,
      },
      {
        path: 'receipts',
        name: 'RenterReceipts',
        component: RenterReceipts,
      },
      {
        path: 'help',
        name: 'RenterHelp',
        component: RenterHelp,
      },
      {
        path: 'mystay',
        name: 'RenterMyStay',
        component: RenterMyStay,
      },
      {
        path: 'insurance',
        name: 'RenterInsurance',
        component: RenterInsurance,
      },
      {
        path: 'saved',
        name: 'RenterSaved',
        component: RenterSaved,
      },
      {
        path: 'messages',
        name: 'RenterMessages',
        component: RenterMessages,
      },
      {
        path: 'notifications',
        name: 'RenterNotifications',
        component: RenterNotifications,
      },
      {
        path: "createprofile",
        name: "CreateProfile",
        component: RenterCreateProfile,
      },
      {
        path: "nextsteps",
        name: "RenterNextSteps",
        component: RenterNextSteps,
      },
      {
        path: "verifyemail",
        name: "RenterVerifyEmail",
        component: RenterVerifyEmail,
      },
      {
        path: "profilequestions",
        name: "RenterProfileQuestions",
        component: RenterProfileQuestions,
      },
      {
        path: 'verification',
        name: 'RenterVerification',
        component: RenterVerification,
      },
      {
        path: 'verification/payment',
        name: 'RenterVerificationPayment',
        component: RenterVerificationPayment,
      },
      {
        path: 'verification/link',
        name: 'RenterVerificationLink',
        component: RenterVerificationLink,
      },
    ],
  },
  {
    path: '/awaiting-verification',
    name: 'AwaitingVerification',
    component: VerifyAccount,
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: AccountReady,
  },
  {
    path: '/next',
    name: 'NextSteps',
    component: NextSteps,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/recover-password',
    name: 'RecoverPassword',
    component: RecoverPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/find-listings',
    name: 'FindListings',
    component: FindListings,
  },
  {
    path: '/find-listings-logged',
    name: 'FindListingsLoggedIn',
    component: FindListingsLoggedIn,
  },
  {
    path: '/listing',
    name: 'SingleListing',
    component: SingleListing,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('auth_token') !== null;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;