<template>
  <div id="password-requirements" class="password-requirements mt-3">
    <p id="min-length" class="requirement" :class="{ met: passwordMinLengthMet }">
      <span class="requirement-icon">
        <template v-if="passwordMinLengthMet">
          <img src="@/assets/svgs/checkmark.svg" style="display:inline;">
        </template>
      </span>
      Must be at least 8 characters
    </p>
    <p id="uppercase" class="requirement" :class="{ met: passwordHasUppercase }">
      <span class="requirement-icon">
        <template v-if="passwordHasUppercase">
          <img src="@/assets/svgs/checkmark.svg" style="display:inline;">
        </template>
      </span>
      Must have at least one uppercase letter
    </p>
    <p id="symbol" class="requirement" :class="{ met: passwordHasSymbol }">
      <span class="requirement-icon">
        <template v-if="passwordHasSymbol">
          <img src="@/assets/svgs/checkmark.svg" style="display:inline;">
        </template>
      </span>
      Must have at least one symbol
    </p>
    <p id="symbol" class="requirement" :class="{ met: passwordHasLowercase }">
      <span class="requirement-icon">
        <template v-if="passwordHasLowercase">
          <img src="@/assets/svgs/checkmark.svg" style="display:inline;">
        </template>
      </span>
      Must have at least one lowercase letter
    </p>
    <p id="symbol" class="requirement" :class="{ met: passwordHasNumber }">
      <span class="requirement-icon">
        <template v-if="passwordHasNumber">
          <img src="@/assets/svgs/checkmark.svg" style="display:inline;">
        </template>
      </span>
      Must have at least one number
    </p>
  </div>
</template>

<script>
export default {
  name: 'PasswordRequirements',
  props: {
    password: {
      type: String,
      default: '',
    }
  },
  computed: {
    passwordMinLengthMet() {
      return this.password && this.password.length >= 8;
    },
    passwordHasUppercase() {
      return /[A-Z]/.test(this.password);
    },
    passwordHasLowercase() {
      return /[a-z]/.test(this.password);
    },
    passwordHasNumber() {
      return /\d/.test(this.password);
    },
    passwordHasSymbol() {
      return /[@$!%*?#&]/.test(this.password);
    },
  },
};
</script>

