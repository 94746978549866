import { defineStore } from 'pinia';

export const useDashboardNotificationControl = defineStore('notification-control', {
    state: () => ({
        formData: {
            contact: {
                email: false,
                phone: false
            },
            notifications: {
                phone: false,
                whatsapp: false,
                email: false,
            },
            marketing: {
                email: false
            }
        }
    }),
});