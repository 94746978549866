<template>
 <div class="find-listings">
   <div class="container flex-container">
     <div class="dashboard-content">
       <header class="mb-4">
         <p class="subheading">For Renters</p>
         <h1 class="heading-2">Our latest listings</h1>

         <svg width="118" height="6" viewBox="0 0 118 6" fill="none" xmlns="http://www.w3.org/2000/svg">
           <rect x="0.333984" y="0.826172" width="96" height="5" rx="2.5" fill="#D2E4D2"/>
           <rect x="101.334" width="16.3321" height="5.82698" rx="2.91349" fill="#D2E4D2"/>
         </svg>
       </header>

       <div class="cards-container flex-container">
         <a href="#" class="card saved-properties-details">
           <div class="property-details">
             <div class="property-header">
               <div class="property-header--img">
                 <img src="@/assets/images/mystay-placeholder.jpeg" alt="Property Image" class="property-image" />

                 <span>
                   <!-- stroke becomes blue on active?? -->
                   <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M17.0212 1.9579C14.95 -0.154182 12.5518 0.736593 11.0668 1.6786C10.2277 2.21087 9.06533 2.21087 8.22623 1.6786C6.74123 0.736604 4.34297 -0.154158 2.27188 1.9579C-2.64457 6.97163 5.78672 16.6329 9.64653 16.6329C13.5063 16.6329 21.9376 6.97163 17.0212 1.9579Z" fill="white" stroke="white" stroke-width="1.33092" stroke-linecap="round"/>
                   </svg>
                 </span>
               </div>

               <div class="property-info">
                 <div class="info-item hidden-mobile">
                   <img src="@/assets/svgs/location.svg" alt="location" class="icon" />

                   <div class="info-item__content">
                     <p>Etobicoke, Toronto</p>
                   </div>
                 </div>

                 <div class="info-item">
                  <p class="heading-6">Bright and Quiet furnished bedroom with private bathroom ( 24 hrs sec.</p>
                 </div>

                 <div class="info-item hidden-mobile mt-2">
                   <p>4 residents · Room in a house · Shared bath  · Pets free</p>
                 </div>

                 <span class="property-price mt-2 hidden-mobile"><strong>C$ 1,200</strong></span>

                 <div class="property-footer">
                   <div class="info-item hidden-desktop">
                     <img src="@/assets/svgs/location.svg" alt="location" class="icon" />

                     <div class="info-item__content">
                       <p>Etobicoke, Toronto</p>
                     </div>
                   </div>

                   <div class="info-item hidden-desktop">
                     <span class="property-price mt-2"><strong>C$ 1,200</strong></span>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </a>

         <a href="#" class="card saved-properties-details">
           <div class="property-details">
             <div class="property-header">
               <div class="property-header--img">
                 <img src="@/assets/images/mystay-placeholder.jpeg" alt="Property Image" class="property-image" />

                 <span>
                   <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M17.0212 1.9579C14.95 -0.154182 12.5518 0.736593 11.0668 1.6786C10.2277 2.21087 9.06533 2.21087 8.22623 1.6786C6.74123 0.736604 4.34297 -0.154158 2.27188 1.9579C-2.64457 6.97163 5.78672 16.6329 9.64653 16.6329C13.5063 16.6329 21.9376 6.97163 17.0212 1.9579Z" fill="white" stroke="white" stroke-width="1.33092" stroke-linecap="round"/>
                   </svg>
                 </span>
               </div>

               <div class="property-info">
                 <div class="info-item hidden-mobile">
                   <img src="@/assets/svgs/location.svg" alt="location" class="icon" />

                   <div class="info-item__content">
                     <p>Etobicoke, Toronto</p>
                   </div>
                 </div>

                 <div class="info-item">
                  <p class="heading-6">Bright and Quiet furnished bedroom with private bathroom ( 24 hrs sec.</p>
                 </div>

                 <div class="info-item hidden-mobile mt-2">
                   <p>4 residents · Room in a house · Shared bath  · Pets free</p>
                 </div>

                 <span class="property-price mt-2 hidden-mobile"><strong>C$ 1,200</strong></span>

                 <div class="property-footer">
                   <div class="info-item hidden-desktop">
                     <img src="@/assets/svgs/location.svg" alt="location" class="icon" />

                     <div class="info-item__content">
                       <p>Etobicoke, Toronto</p>
                     </div>
                   </div>

                   <div class="info-item hidden-desktop">
                     <span class="property-price mt-2"><strong>C$ 1,200</strong></span>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </a>

         <a href="#" class="card saved-properties-details">
           <div class="property-details">
             <div class="property-header">
               <div class="property-header--img">
                 <img src="@/assets/images/mystay-placeholder.jpeg" alt="Property Image" class="property-image" />

                 <span>
                   <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M17.0212 1.9579C14.95 -0.154182 12.5518 0.736593 11.0668 1.6786C10.2277 2.21087 9.06533 2.21087 8.22623 1.6786C6.74123 0.736604 4.34297 -0.154158 2.27188 1.9579C-2.64457 6.97163 5.78672 16.6329 9.64653 16.6329C13.5063 16.6329 21.9376 6.97163 17.0212 1.9579Z" fill="white" stroke="white" stroke-width="1.33092" stroke-linecap="round"/>
                   </svg>
                 </span>
               </div>

               <div class="property-info">
                 <div class="info-item hidden-mobile">
                   <img src="@/assets/svgs/location.svg" alt="location" class="icon" />

                   <div class="info-item__content">
                     <p>Etobicoke, Toronto</p>
                   </div>
                 </div>

                 <div class="info-item">
                  <p class="heading-6">Bright and Quiet furnished bedroom with private bathroom ( 24 hrs sec.</p>
                 </div>

                 <div class="info-item hidden-mobile mt-2">
                   <p>4 residents · Room in a house · Shared bath  · Pets free</p>
                 </div>

                 <span class="property-price mt-2 hidden-mobile"><strong>C$ 1,200</strong></span>

                 <div class="property-footer">
                   <div class="info-item hidden-desktop">
                     <img src="@/assets/svgs/location.svg" alt="location" class="icon" />

                     <div class="info-item__content">
                       <p>Etobicoke, Toronto</p>
                     </div>
                   </div>

                   <div class="info-item hidden-desktop">
                     <span class="property-price mt-2"><strong>C$ 1,200</strong></span>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </a>
      </div>
     </div>
   </div>
 </div>
</template>

<script>
 export default {
   name: "FindListings",
   components: {
   },
 };
</script>