<template>
  <section aria-labelledby="phone-heading" class="card" tabindex="0">
    <div class="phone-section">
      <div class="edit-info">
        <h2 id="phone-heading" class="heading-6">Phone</h2>

        <a href="javascript:void(0)" class="edit-link" @click="openModal">
          <img src="@/assets/svgs/edit.svg" alt="edit">
          Edit
        </a>
      </div>

      <p>{{ formData.renter_phone }}</p>
    </div>

    <div v-show="showModal" class="modal-overlay show" @click.self="closeModal">
      <div class="modal-container">
        <div class="modal-header">
          <p>Phone</p>
          <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
        </div>

        <!-- Integrated Form in Modal -->
        <div class="form-container">
          <h2 class="heading-4">Phone details</h2>

          <form id="screening-details-form" @submit.prevent="submitForm">
            <div class="form-group">
                <input 
                  v-model="phone"
                  type="tel"
                  name="phone" 
                  id="phone" 
                  maxlength="11"
                  placeholder="xxxxxxxxxxx" 
                  title="Please enter a valid Canadian phone number with at least 10 digits."
                  @input="phone = phone.replace(/[^0-9]/g, '')" />

                <div id="addressErrorText" class="validation-error">
                  <span v-if="errors.renter_phone">{{ errors.renter_phone[0] }}</span>
                </div>
            </div>

            <div class="form-actions sticky-btm">
              <button type="submit" class="primary-btn">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  

</template>

<script>
import { useRenterProfile } from "@/stores/renter/useRenterProfile";
import { mapState, mapActions } from "pinia";

export default {
  name: "ProfilePhone",
  data() {
    return {
      showModal: false,
      phone: '',
    };
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'errors'])
  },
  methods: {
    ...mapActions(useRenterProfile, ['validatePhone', 'clearErrors']),

    openModal() {
      this.phone = this.formData.renter_phone;
      document.body.style.overflow = "hidden"; // Prevent background scroll
      this.showModal = true;
    },
    closeModal() {
      this.clearErrors();
      this.showModal = false;
      document.body.style.overflow = "";

      this.phone = '';
    },
    submitForm() {const isValid = this.validatePhone(this.phone);
      if(!isValid) return;
      //TODO: Save data
      
      this.formData.renter_phone = this.phone;
      this.closeModal()
    },
  },
};
</script>

<style scoped>

.custom-checkbox-container{
  flex-direction: column;
}
</style>