import { useDates } from '@/stores/shared/useDates';
import { defineStore } from 'pinia';

export const useDashboardMessages = defineStore('messages', {
    state: () => ({
        name: "Jay",
        selected_conversation: null,
        conversations: [
            {
                id: Math.random(),
                name: "John Doe",
                time: "2025-01-22T19:22:00Z",
                message: "Irure proident mollit nostrud nostrud ex est quis proident minim elit aute sunt Lorem cillum. Qui occaecat ut ex ut aute esse aliqua amet fugiat velit cupidatat adipisicing nisi labore.",
                count: 0,
                property_picture: require('@/assets/images/mystay-placeholder.jpeg'),
                user_picture: require('@/assets/images/user-placeholder.png'),
            },
            {
                id: Math.random(),
                name: "John Doe",
                time: "2025-01-22T19:22:00Z",
                message: "Irure proident mollit nostrud nostrud ex est quis proident minim elit aute sunt Lorem cillum. Qui occaecat ut ex ut aute esse aliqua amet fugiat velit cupidatat adipisicing nisi labore.",
                count: 1,
                property_picture: require('@/assets/images/mystay-placeholder.jpeg'),
                user_picture: require('@/assets/images/user-placeholder.png'),
            },
            {
                id: Math.random(),
                name: "John Doe",
                time: "2025-01-22T19:22:00Z",
                message: "Irure proident mollit nostrud nostrud ex est quis proident minim elit aute sunt Lorem cillum. Qui occaecat ut ex ut aute esse aliqua amet fugiat velit cupidatat adipisicing nisi labore.",
                count: 1,
                property_picture: require('@/assets/images/mystay-placeholder.jpeg'),
                user_picture: require('@/assets/images/user-placeholder.png'),
            },
            {
                id: Math.random(),
                name: "John Doe",
                time: "2025-01-22T19:22:00Z",
                message: "Irure proident mollit nostrud nostrud ex est quis proident minim elit aute sunt Lorem cillum. Qui occaecat ut ex ut aute esse aliqua amet fugiat velit cupidatat adipisicing nisi labore.",
                count: 1,
                property_picture: require('@/assets/images/mystay-placeholder.jpeg'),
                user_picture: require('@/assets/images/user-placeholder.png'),
            },
            {
                id: Math.random(),
                name: "John Doe",
                time: "2025-01-22T19:22:00Z",
                message: "Irure proident mollit nostrud nostrud ex est quis proident minim elit aute sunt Lorem cillum. Qui occaecat ut ex ut aute esse aliqua amet fugiat velit cupidatat adipisicing nisi labore.",
                count: 1,
                property_picture: require('@/assets/images/mystay-placeholder.jpeg'),
                user_picture: require('@/assets/images/user-placeholder.png'),
            },
            {
                id: Math.random(),
                name: "John Doe",
                time: "2025-01-22T19:22:00Z",
                message: "Irure proident mollit nostrud nostrud ex est quis proident minim elit aute sunt Lorem cillum. Qui occaecat ut ex ut aute esse aliqua amet fugiat velit cupidatat adipisicing nisi labore.",
                count: 1,
                property_picture: require('@/assets/images/mystay-placeholder.jpeg'),
                user_picture: require('@/assets/images/user-placeholder.png'),
            },
            {
                id: Math.random(),
                name: "John Doe",
                time: "2025-01-22T19:22:00Z",
                message: "Irure proident mollit nostrud nostrud ex est quis proident minim elit aute sunt Lorem cillum. Qui occaecat ut ex ut aute esse aliqua amet fugiat velit cupidatat adipisicing nisi labore.",
                count: 1,
                property_picture: require('@/assets/images/mystay-placeholder.jpeg'),
                user_picture: require('@/assets/images/user-placeholder.png'),
            },
            {
                id: Math.random(),
                name: "John Doe",
                time: "2025-01-22T19:22:00Z",
                message: "Irure proident mollit nostrud nostrud ex est quis proident minim elit aute sunt Lorem cillum. Qui occaecat ut ex ut aute esse aliqua amet fugiat velit cupidatat adipisicing nisi labore.",
                count: 1,
                property_picture: require('@/assets/images/mystay-placeholder.jpeg'),
                user_picture: require('@/assets/images/user-placeholder.png'),
            },
        ],

        messages: [],
        property: {},
        new_message: '',
        attachement: null
    }),
    // getters: {},
    actions: {
        openChat(conversation){
            this.getChatMessages();
            this.selected_conversation = conversation;
        },
        closeChat() {
            this.selected_conversation = null;
        },
        getChatMessages(){
            //TODO: API call to get messages

            this.property = {
                id: Math.random(),
                title: 'Room with shared bath and kitchen.',
                start_date: '2025-02-01',
                end_date: '2025-03-01',
                person_picture: require('@/assets/images/messages-user.png'),
                status: 'Online'
            };
            this.messages = [
                {
                    id: Math.random(),
                    current_user: true,
                    date_time: '2025-01-21T19:20:00Z',
                    text: 'Cras dapibus. Fusce fermentum odio nec arcu. Pellentesque commodo eros a enim. Phasellus a est. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Cras dapibus. Fusce fermentum odio nec arcu. Pellentesque commodo eros a enim. Phasellus a est. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc.',
                    sent: true,
                    seen: true
                },
                {
                    id: Math.random(),
                    current_user: false,
                    date_time: '2025-01-22T19:21:00Z',
                    text: 'Hi John, yes, we can accommodate.',
                    sent: true,
                    seen: true
                },
                {
                    id: Math.random(),
                    current_user: true,
                    date_time: "2025-01-22T19:22:00Z",
                    text: "Hello John, I hope you're doing well. I’m interested in your property and wanted to check if it's still available. Looking forward to hearing from you!",
                    sent: true,
                    seen: false
                },
            ];
        },
        getFormattedDate(isoDate){
            const dates = useDates();
            return dates.getFormattedDateString(isoDate, 'MM DD, YYYY');
        },
        showChatDay(index){
            if(index === 0) return true;

            const dates = useDates();
            const daysDiff = Math.abs(dates.getDaysDifference(this.messages[index-1].date_time, this.messages[index].date_time));
            return daysDiff > 0;
        },
        getFormattedTime(isoDate){
            const dates = useDates();
            return dates.getFormattedTimeString(isoDate, 'H:MM AM');
        },
        getChatDate(isoDate){
            const dates = useDates();
            const dateStr = dates.getFormattedDateString(isoDate, 'MMMM DD');
            const relativeDateStr = dates.getRelativeDateText(isoDate);

            if(relativeDateStr === '') return dateStr;
            else return `${relativeDateStr}, ${dateStr}`;
        },
        updateNewMessage(event){
            this.new_message = event.target.value;
        },
        addAttachment(event){
            this.attachement = event.target.files;
            //TODO: send attachments
        },
        sendMessage(){
            //TODO: API to send message
            
            this.messages.push({
                id: Math.random(),
                current_user: true,
                date_time: new Date().toISOString(),
                text: this.new_message,
                sent: true,
                seen: false
            });
            this.new_message = '';
        }
    },
});