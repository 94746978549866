<template>
  <nav class="dashboard-nav" aria-label="Main Navigation">
    <div class="flex-container">
      <div class="left-col">
        <a href="/" class="logo">
          <img src="@/assets/svgs/Happipad_Logo_Dark.svg" alt="Happipad Logo" />
        </a>
      </div>

      <div class="right-col">
        <div class="nav-links">
          <router-link to="/renter/saved" class="hidden-mobile">
            <img src="@/assets/svgs/saved-circle.svg" alt="Messages" />
            Saved
          </router-link>

          <router-link to="/renter/notifications" class="hidden-mobile">
            <div class="notifications-available">
              <img src="@/assets/svgs/notification-circle.svg" alt="Notification" />
              <span class="notification-dot"></span>
            </div>
            Notifications
          </router-link>

          <router-link to="/renter/messages" class="hidden-mobile">
            <img src="@/assets/svgs/messages-circle.svg" alt="Messages" />
            Messages
          </router-link>

          <a href="javascript:void(0)" id="profile-sidebar-toggle" @click.prevent="toggleSidebar">
            <div class="profile-info" :class="{ hidden: isSidebarOpen }">
              <img src="@/assets/svgs/profile-circle.svg" alt="Profile" />
              Jay Doe
              <img src="@/assets/svgs/chevron-down-dark.svg" alt="Chevron" />
            </div>

            <img
              class="close-icon"
              :class="{ hidden: !isSidebarOpen }"
              src="@/assets/svgs/close.svg"
              alt="Close"
            />
          </a>

          <div
            class="dashboard-sidebar-nav hidden-desktop"
            id="sidebar"
            :class="{ active: isSidebarOpen }"
          >
            <DashboardSidebar />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  import DashboardSidebar from "@/components/RenterDashboardSidebar.vue";

  export default {
    name: "RenterDashboardTopNav",

    components: {
      DashboardSidebar,
    },

    data() {
      return {
        isSidebarOpen: false,
      };
    },

    methods: {
      toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
      },
    },
  };
</script>
