import { defineStore } from 'pinia';

export const useRenterInsurance = defineStore('renter-insurance', {
    state: () => ({
        insurance_type: '',
        document: null
    }),
    // getters: {
    // },
    actions: {
        setInsuranceType(type){
            this.insurance_type = type;
        },
        setDocument(event){
            this.document = event.target.files;
        }
    },
});