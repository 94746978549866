<template>
  <div class="form-step active">
    <div>
      <h1 class="heading-2">Let’s set your Maximum monthly budget including utilities.</h1>
    </div>

    <div class="form-group budget-container">
      <input
        v-model="formData.budget"
        type="number" 
        name="budget" 
        id="budget" 
        min="0"
        placeholder="$..." 
        required />

      <div id="budgetErrorText" class="validation-error">
        <span v-if="errors.budget">{{ errors.budget[0] }}</span>
      </div>

      <p class="text-faded text-align-center">Your price should include utilities</p>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextStep">Next</button>
    </div>
  </div>
</template>

<script>
import { useRenterProfile } from "@/stores/renter/profile/useRenterProfile";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterProfileStep3",
  data() {
    return {};
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'errors'])
  },
  methods: {
    ...mapActions(useRenterProfile, ['nextStep', 'prevStep']),
  }
};
</script>