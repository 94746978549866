import { defineStore } from 'pinia';

export const useHostContracts = defineStore('contracts', {
    state: () => ({
        show_full_list: false,
        host_name: "Jay",
        requires_action: [
            Math.random(), //contract_id
        ],
        contracts_list: [
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
            {
                contract_id: Math.random(),
                room_picture: require('@/assets/images/room-placeholder.png'),
                room_name: "Room A",
                renter_picture: require('@/assets/images/profile-user-placeholder.jpeg'),
                renter_name: "Jay Doe",
                from_date: "2021-04-23",
                to_date: "2021-04-23",
                payment: 1300,
            },
        ]
    }),
    getters: {
        getContracts() {
            const sortedList = this.contracts_list.sort((a, b) => {
                return (a.from_date > b.from_date) ? -1 : ((a.from_date < b.from_date) ? 1 : 0);
            });

            if (this.show_full_list) return sortedList;

            const count = sortedList.length;
            const max = count > 8 ? 8 : count;

            return sortedList.slice(0, max);
        },
        getMoreCount() {
            if (this.show_full_list) return 0;

            return this.contracts_list.length - this.getContracts.length;
        },
    },
    actions: {
        toggleShowFullList() {
            this.show_full_list = !this.show_full_list;
        },
    },
});