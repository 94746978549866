<template>
  <RenterDashboardTopNav />

  <div class="renter-dashboard">
    <div class="main-dashboard container flex-container">
      <div class="dashboard-content">
        <div class="messages">
          <div v-show="conversations.length > 0" class="messages-container">

            <aside class="messages-sidebar">
              <div class="search-bar">
                <input type="text" placeholder="Search" />
                <img src="@/assets/svgs/search.svg" alt="search" class="icon" />
              </div>

              <ul class="conversation-list">
                <li
                  v-for="conversation in conversations"
                  :key="conversation.id"
                  class="conversation"
                  :class="{'not-read': conversation.count > 0}"
                  @click="openChat(conversation)"
                >
                  <div class="conversation-imgs">
                    <img :src="conversation.property_picture" alt="Property Image" class="property-img" />
                    <img :src="conversation.user_picture" alt="user Image" class="user-img" />
                  </div>

                  <div class="conversation-details-container">
                    <div class="conversation-details">
                      <p><strong>{{ conversation.name }}</strong></p>
                      <span class="time">{{ getFormattedTime(conversation.time) }}</span>
                    </div>

                    <div class="conversation-details">
                      <p class="message single-line">{{ conversation.message }}</p>
                      <span v-if="conversation.count > 0" class="notification-count"><strong>{{ conversation.count }}</strong></span>
                    </div>
                  </div>
                </li>
              </ul>
            </aside>

            <!-- Chat Modal -->
            <transition name="slide-in">
              <div v-if="selected_conversation" class="chat-modal hidden-desktop">
                <div class="chat-header">
                  <button class="close-btn" @click="closeChat">
                    <img src="@/assets/svgs/chevron-left-dark.svg" alt="chevron" class="icon"/>
                  </button>

                  <div class="chat-header__user">
                    <img class="user-img" :src="property.person_picture" alt="">

                    <div>
                      <p><strong>{{ selected_conversation.name }}</strong></p>
                      <span class="status">{{ property.status }}</span>
                    </div>
                  </div>
                </div>

                <div class="chat-header">
                  <div>
                    <img :src="selected_conversation.property_picture" alt="Property Image" class="property-image" />

                    <div class="chat-header__content">
                      <p class="dates">{{ getFormattedDate(property.start_date) }} - {{ getFormattedDate(property.end_date) }}</p>
                      <p class="heading-5">{{ property.title }}</p>
                    </div>
                  </div>
                </div>

                <div class="chat-messages">
                  <div v-for="(message, index) in messages" :key="message.id" class="message-date-container">
                    <p v-if="showChatDay(index)" class="chat-date">{{ getChatDate(message.date_time) }}</p>
                    
                    <div class="message-container" :class="{'user-message': message.current_user}">
                      <img v-if="!message.current_user" class="user-img" :src="property.person_picture" alt="">
                      
                      <div>
                        <div class="message">
                          <p class="content">{{ message.text }}</p>
                        </div>
                        <div class="time">
                          <p>{{ getFormattedTime(message.date_time) }}</p>
                          <img v-if="message.sent && !message.seen" src="@/assets/svgs/messages-checkmark.svg" alt="Sent" class="icon" />
                          <img v-if="message.sent && message.seen" src="@/assets/svgs/messages-double-checkmark.svg" alt="Seen" class="icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sticky-btm">
                  <div class="chat-input">
                    <input
                      v-model="new_message"
                      type="text"
                      placeholder="Type a message.."
                      @keyup.enter="sendMessage"
                      @input="updateNewMessage" />

                    <div class="chat-input__actions">
                      <a href="javascript:void(0)" @click="attachmentClicked">
                        <img src="@/assets/svgs/attachment-upload.svg" alt="attachment upload">
                      </a>

                      <button class="primary-btn" @click="sendMessage">
                        <img src="@/assets/svgs/message-send.svg" alt="send">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <main v-if="selected_conversation" class="chat-window hidden-mobile">
              <div class="chat-header">
                <div>
                  <img :src="selected_conversation.property_picture" alt="Property Image" class="property-image" />

                  <div class="chat-header__content">
                    <p class="heading-5">{{ property.title }}</p>
                    <p class="dates">{{ getFormattedDate(property.start_date) }} - {{ getFormattedDate(property.end_date) }}</p>
                  </div>
                </div>

                <button class="tertiary-btn" @click="finishBookingClicked">Finish booking</button>
              </div>

              <div class="chat-messages">
                <div v-for="(message, index) in messages" :key="message.id" class="message-date-container">
                  <p v-if="showChatDay(index)" class="chat-date">{{ getChatDate(message.date_time) }}</p>
                  
                  <div class="message-container" :class="{'user-message': message.current_user}">
                    <img v-if="!message.current_user" class="user-img" :src="property.person_picture" alt="">
                    
                    <div>
                      <div class="message">
                        <p class="content">{{ message.text }}</p>
                      </div>
                      <div class="time">
                        <p>{{ getFormattedTime(message.date_time) }}</p>
                        <img v-if="message.sent && !message.seen" src="@/assets/svgs/messages-checkmark.svg" alt="Sent" class="icon" />
                        <img v-if="message.sent && message.seen" src="@/assets/svgs/messages-double-checkmark.svg" alt="Seen" class="icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="chat-input hidden-mobile">
                <input
                  v-model="new_message"
                  type="text"
                  placeholder="Type a message.."
                  @keyup.enter="sendMessage"
                  @input="updateNewMessage" />

                <div class="chat-input__actions">
                  <a href="javascript:void(0)" @click="attachmentClicked">
                    <img src="@/assets/svgs/attachment-upload.svg" alt="attachment upload">
                  </a>
                  <button class="primary-btn" @click="sendMessage">
                    <img src="@/assets/svgs/message-send.svg" alt="send">
                  </button>
                </div>
              </div>
            </main>

            <input 
              type="file" 
              id="attachment" 
              ref="attachment"
              name="attachment" 
              style="display: none;" 
              multiple
              @input="addAttachment" />
          </div>

          <div v-show="conversations.length === 0" class="card card--empty text-align-center">
            <p>Hey {{ name }}! No messages received.</p>
            <img src="@/assets/svgs/no-messages.svg" alt="">
            <router-link to="/find-listings" class="primary-btn">
              Find a place
            </router-link>
          </div>
        </div>
      </div>

      <RenterDashboardBottomNav />
    </div>
  </div>
</template>

<script>
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

import { useDashboardMessages } from "@/stores/shared/useDashboardMessages";
import { mapActions, mapState } from "pinia";

export default {
  name: "RenterDashboardMessages",
  components: {
    RenterDashboardBottomNav,
    RenterDashboardTopNav,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useDashboardMessages, [
      'name', 
      'conversations', 
      'selected_conversation', 
      'messages', 
      'property',
      'new_message'
    ])
  },
  methods: {
    ...mapActions(useDashboardMessages, [
      'openChat', 
      'closeChat', 
      'showChatDay',
      'getFormattedTime',
      'getFormattedDate',
      'getChatDate',
      'updateNewMessage',
      'addAttachment',
      'sendMessage',
    ]),

    attachmentClicked(){
      this.$refs.attachment.click();
    },
    finishBookingClicked(){
      //TODO
    },
  },
};
</script>
