<template>
  <Step1 v-if="step === 1"/>
  <Step2 v-if="step === 2"/>
  <Step3 v-if="step === 3"/>
</template>


<script>
import { useRenterVerificationPaymentSetup } from "@/stores/renter/verification/useRenterVerificationPayment";
import { mapState } from "pinia";

import Step1 from "@/views/renter/verification/payment/Step1.vue";
import Step2 from "@/views/renter/verification/payment/Step2.vue";
import Step3 from "@/views/renter/verification/payment/Step3.vue";

export default {
  name: "RenterVerificationPaymentSetup",
  components: {
    Step1,
    Step2,
    Step3,
  },
  computed: {
    ...mapState(useRenterVerificationPaymentSetup, ['step'])
  },
};
</script>