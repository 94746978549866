<template>
<!-- Story Section -->
<section aria-labelledby="story-heading" class="card" tabindex="0">
  <div class="story-section">
    <div class="edit-info">
      <h2 id="story-heading" class="heading-6">My story</h2>

      <a href="javascript:void(0)" class="edit-link" @click="openModal">
        <img src="@/assets/svgs/edit.svg" alt="edit">
        Edit
      </a>
    </div>

    <p v-if="formData && formData.renter_story !== ''" class="profile-description">
      {{ formData.renter_story }}
    </p>
    <p v-else class="profile-description">
      No story provided
    </p>
  </div>


  <div v-show="showModal" class="modal-overlay show" @click.self="closeModal">
    <div class="modal-container">
      <div class="modal-header">
        <p>Story</p>
        <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
      </div>

      <!-- Integrated Form in Modal -->
      <div class="form-container">
        <h2 class="heading-4">Story details</h2>

        <form id="screening-details-form" @submit.prevent="submitForm">
          <div class="form-group">
            <textarea 
              v-model="renter_story"
              rows="5"
              placeholder="Enter your story" />
          </div>

          <div class="form-actions sticky-btm">
            <button type="submit" class="primary-btn">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import { useRenterProfile } from "@/stores/renter/useRenterProfile";
import { mapState } from "pinia";

export default {
  name: "ProfileStory",

  data() {
    return {
      showModal: false,
      renter_story: ''
    };
  },
  computed: {
    ...mapState(useRenterProfile, ['formData'])
  },
  methods: {
    openModal() {
      this.renter_story = this.formData.renter_story
      document.body.style.overflow = "hidden"; // Prevent background scroll
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = "";
      this.renter_story = "";
    },

    submitForm() {
      //TODO: Save data
      
      this.formData.renter_story = this.renter_story;
      this.closeModal()
    },
  },
};
</script>