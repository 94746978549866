<template>
  <RenterDashboardTopNav />

  <div class="renter-dashboard saved-properties">
    <div class="container flex-container">
      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-2">Saved</h1>
        </header>

        <div class="cards-container flex-container">

          <router-link to="/listing" class="card saved-properties-details" v-for="property in properties" :key="property.id">
            <div class="property-details">
              <div class="property-header">
                <div class="property-header--img">
                  <img :src="property.image" alt="Property Image" class="property-image" />

                  <span>
                    <!-- stroke becomes blue on active?? -->
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.0212 1.9579C14.95 -0.154182 12.5518 0.736593 11.0668 1.6786C10.2277 2.21087 9.06533 2.21087 8.22623 1.6786C6.74123 0.736604 4.34297 -0.154158 2.27188 1.9579C-2.64457 6.97163 5.78672 16.6329 9.64653 16.6329C13.5063 16.6329 21.9376 6.97163 17.0212 1.9579Z"
                        fill="white" stroke="white" stroke-width="1.33092" stroke-linecap="round" />
                    </svg>
                  </span>
                </div>

                <div class="property-info">
                  <div class="info-item hidden-mobile">
                    <img src="@/assets/svgs/location.svg" alt="location" class="icon" />

                    <div class="info-item__content">
                      <p>{{ property.location }}</p>
                    </div>
                  </div>

                  <div class="info-item">
                    <p class="heading-6">{{ property.title }}</p>
                  </div>

                  <div class="info-item hidden-mobile mt-2">
                    <p>{{ getDescriptorText(property.descriptors) }}</p>
                  </div>

                  <span class="property-price mt-2 hidden-mobile"><strong>C$ {{ financial(property.price) }}</strong></span>

                  <div class="property-footer">
                    <div class="info-item hidden-desktop">
                      <img src="@/assets/svgs/location.svg" alt="location" class="icon" />

                      <div class="info-item__content">
                        <p>{{ property.location }}</p>
                      </div>
                    </div>

                    <div class="info-item hidden-desktop">
                      <span class="property-price mt-2"><strong>C$ {{ financial(property.price) }}</strong></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <RenterDashboardBottomNav />
    </div>
  </div>
</template>


<script>
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

import { useRenterSaved } from "@/stores/renter/useRenterSaved";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterSaved",

  components: {
    RenterDashboardBottomNav,
    RenterDashboardTopNav
  },
  computed: {
    ...mapState(useRenterSaved, ['properties'])
  },
  methods: {
    ...mapActions(useRenterSaved, ['financial', 'getDescriptorText']),
  },
};
</script>