<template>
  <div class="container flex-container verify-email mb-6">
    <div class="content">
        <h1 class="heading-2">Verify your account.</h1>
        <img class="mt-4" src="@/assets/svgs/verify-email.svg" alt="Email icon" width="160" height="160">
    </div>

    <p>A verification link was emailed to <strong>Jenifer@gmail.com</strong>.<br>To confirm your account, please, follow the link.</p>

    <div class="timer-container">
        <p>Nothing yet?</p>
        <div class="timer" id="timer">00:{{ getTime }}</div>
        <button class="secondary-btn" id="resendBtn" :disabled="disable_button" @click="resendEmailClicked">Resend email</button>
    </div>
  </div>
</template>

<script>
import { useRenterVerifyEmail } from "@/stores/renter/signup/useRenterVerifyEmail";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterVerifyEmail",
  data() {
    return {};
  },
  computed: {
    ...mapState(useRenterVerifyEmail, ['email', 'time_left', 'disable_button', 'getTime'])
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    ...mapActions(useRenterVerifyEmail, ['startTimer', 'resetTimer']),

    resendEmailClicked() {
      this.resetTimer();

      //TODO: resend email
    },
  }
};
</script>