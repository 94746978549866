<template>
<HostDashboardTopNav />
<div class="host-dashboard">
  <div class="main-dashboard container flex-container">
    <HostDashboardSidebar />

    <div class="dashboard-content">
      <header class="mb-2">
        <h1 class="heading-2">Dashboard</h1>

        <div>
          <p>Your next steps</p>

          <span v-if="list.length > 0" class="notification-badge" aria-hidden="true"><strong>{{ list.length }}</strong></span>
        </div>
      </header>

      <div class="cards-container flex-container">
        <section>
          <router-link v-for="item in list" :key="item.id" class="card" :to="getCardLink(item.type, item.id)">
            <div class="card-left-col">
              <img :src="getAsset('warning.svg')" alt="warning">

              <div class="card-content">
                <p><strong>{{ getCardTitle(item) }}</strong></p>
                <span v-if="item.status === 'required'">Required!</span>
              </div>
            </div>

            <div class="card-right-col steps">
              <div>
                <img v-if="item.step >= item.max" :src="getAsset('checkmark-circle.svg')" alt="checkmark">

                <p>{{ item.step }}/{{ item.max }}</p>

                <div class="progress-bar">
                  <div class="progress" :style="getProgressStyle(item.step, item.max)"></div>
                </div>
              </div>

              <img :src="getAsset('chevron-right-dark.svg')" alt="chevron">
            </div>
          </router-link>
        </section>
      </div>
    </div>

    <HostDashboardBottomNav />
  </div>
</div>
</template>

<script>
import HostDashboardTopNav from "@/components/HostDashboardTopNav.vue";
import HostDashboardSidebar from "@/components/HostDashboardSidebar.vue";
import HostDashboardBottomNav from "@/components/HostDashboardBottomNav.vue";
import { useHostDashboard } from "@/stores/host/dashboard/useHostDashboard";
import { mapState } from "pinia";

export default {
  name: "HostDashboard",

  components: {
    HostDashboardTopNav,
    HostDashboardSidebar,
    HostDashboardBottomNav
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState(useHostDashboard, ['list'])
  },
  methods: {
    getAsset(filename) {
      return require(`@/assets/svgs/${filename}`);
    },
    getProgressStyle(step, max) {
      const progress = step > max ? 1 : (step / max);
      return `width: calc((${progress})* 100%);`
    },
    getCardTitle(item) {
      const typeText = {
        room: ' set up',
        verification: 'Verification',
      };

      return `${item.name}${typeText[item.type]}`;
    },

    getCardLink(type, id) {
      const links = {
        room: `/host/listing/properties/${id}/edit`,
        verification: '/host/dashboard', //TODO: need route
      };

      return links[type];
    },
  }
};
</script>