<template>
<HostDashboardTopNav />

<div class="host-dashboard">
  <div class="applications container flex-container">
    <HostDashboardSidebar />

    <div class="dashboard-content">
      <header class="mb-2">
        <h1 class="heading-2">Applications</h1>

        <p class="hidden-desktop" id="table-title">Showing {{ getApplications.length }} of {{ applications_list.length }}</p>
      </header>

      <div class="cards-container card">
        <!-- Table Section -->
        <section v-if="applications_list.length > 0" class="mt-4 mb-4" aria-labelledby="table-title">
          <p class="hidden-mobile" id="table-title">Showing {{ getApplications.length }} of {{ applications_list.length }}</p>

          <div class="dashboard-table-container">
            <table>
              <thead>
                <tr>
                  <th class="hidden-desktop" scope="col">Application</th>
                  <th scope="col">Renter</th>
                  <th scope="col">Applied On</th>
                  <th scope="col">Room</th>
                  <th scope="col">Payment</th>
                  <th scope="col">Dates</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in getApplications" :key="item.application_id">
                  <td class="hidden-desktop">
                    <div data-label="Application"></div>
                    <a href="javascript:void(0)" @click="viewClicked(item)">
                      <img :src="getAsset('eye-white.svg')" alt="view">
                      View
                    </a>
                  </td>

                  <td data-label="Renter">
                    <div>
                      <img :src="item.profile_picture" alt="Renter profile" />
                      {{ item.name }}
                    </div>
                  </td>

                  <td data-label="Applied On">
                    <div>
                      {{ getFormattedDate(item.applied_on) }}
                    </div>
                  </td>

                  <td data-label="Room">
                    <div>
                      <img :src="item.room_picture" alt="Room A thumbnail" />
                      {{ item.room_name }}
                    </div>
                  </td>

                  <td data-label="Payment">${{ item.payment }}</td>

                  <td data-label="Dates">
                    <div>
                      {{ getFormattedDate(item.from_date) }} - {{ getFormattedDate(item.to_date) }}
                    </div>
                  </td>

                  <td data-label="Status">
                    <span class="status-badge" :class="getStatusClass(item.status)" :aria-label="`Listing status: ${capitalize(item.status)}`" role="status">{{ capitalize(item.status) }}</span>
                  </td>

                  <td class="hidden-mobile">
                    <button class="tertiary-btn" aria-label="details" @click="viewClicked(item)">
                      <img :src="getAsset('eye.svg')" alt="" />
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <a v-if="!show_full_list && getMoreCount > 0" href="javascript:void(0)" class="view-more mt-4" @click="toggleShowFullList()">Show {{ getMoreCount }} more
            <img src="@/assets/svgs/chevron-down-blue.svg" alt="chevron down">
          </a>
          <a v-if="show_full_list" href="javascript:void(0)" class="view-more mt-4" @click="toggleShowFullList()">Show less
            <img src="@/assets/svgs/chevron-up-blue.svg" alt="chevron up">
          </a>
        </section>

        <div v-if="applications_list.length === 0" class="listing-card listing-card--empty">
          <p>Hey {{ host_name }}! No applications received.</p>

          <img :src="getAsset('no-applications.svg')" alt="">

          <router-link to="/host/find-renter" class="tertiary-btn">
            <img :src="getAsset('renters.svg')" alt="renters">
            Find renters
          </router-link>
        </div>
      </div>
    </div>

    <HostDashboardBottomNav />
  </div>
</div>
</template>

<script>
import HostDashboardTopNav from "@/components/HostDashboardTopNav.vue";
import HostDashboardSidebar from "@/components/HostDashboardSidebar.vue";
import HostDashboardBottomNav from "@/components/HostDashboardBottomNav.vue";
import { useHostApplications } from "@/stores/host/dashboard/useHostApplications";
import { mapState, mapActions } from "pinia";

export default {
  name: "HostApplications",

  components: {
    HostDashboardTopNav,
    HostDashboardSidebar,
    HostDashboardBottomNav
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState(useHostApplications, ['show_full_list', 'host_name', 'applications_list', 'getApplications', 'getMoreCount'])
  },
  methods: {
    ...mapActions(useHostApplications, ['toggleShowFullList']),

    getAsset(filename) {
      return require(`@/assets/svgs/${filename}`);
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    getStatusClass(status) {
      const classNames = {
        pending: 'status-badge--yellow',
        declined: 'status-badge--red',
        approved: 'status-badge--green',
      };

      return classNames[status];
    },
    getFormattedDate(isoDate) {
      const splitDate = isoDate.split('-');
      const year = splitDate[0];
      const month = this.monthNumberToText(splitDate[1]);
      const day = parseInt(splitDate[2]);
      return `${month} ${day}, ${year}`;
    },
    monthNumberToText(value) {
      const monthNumber = parseInt(value);

      switch (monthNumber) {
        case 1:
          return 'Jan';
        case 2:
          return 'Feb';
        case 3:
          return 'Mar';
        case 4:
          return 'Apr';
        case 5:
          return 'May';
        case 6:
          return 'Jun';
        case 7:
          return 'Jul';
        case 8:
          return 'Aug';
        case 9:
          return 'Sep';
        case 10:
          return 'Oct';
        case 11:
          return 'Nov';
        case 12:
          return 'Dec';
        default:
          return '';
      }
    },

    viewClicked() {
      //TODO
      alert("This should view the application");
    },
  }
};
</script>