<template>
<section aria-labelledby="langs-heading" class="card" tabindex="0">
  <div class="langs-section">
    <div class="edit-info">
      <h2 id="langs-heading" class="heading-6">Languages</h2>

      <a href="#" class="edit-link" @click="openModal">
        <img src="@/assets/svgs/edit.svg" alt="edit">
        Edit
      </a>
    </div>

    <div class="additional-info mt-3" tabindex="0">
      <p>
        <img src="@/assets/svgs/profile-lang.svg" alt="languages">
        Speaks {{ languagesText }}
      </p>
    </div>
  </div>

  <div v-show="showModal" class="modal-overlay show" @click.self="closeModal">
    <div class="modal-container">
      <div class="modal-header">
        <p>Languages</p>
        <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
      </div>

      <!-- Integrated Form in Modal -->
      <div class="form-container">
        <h2 class="heading-4">Languages details</h2>

        <form id="screening-details-form" @submit.prevent="submitForm">
          <div class="form-group" data-group="languages-group">
            <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="languages-label">
              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="english" 
                  name="languages" 
                  value="english" 
                  aria-labelledby="english-label">

                <label for="english" id="english-label">
                  <div class="label-content">English</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="french" 
                  name="languages" 
                  value="french" 
                  aria-labelledby="french-label">

                <label for="french" id="french-label">
                  <div class="label-content">French</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="filipino" 
                  name="languages" 
                  value="filipino" 
                  aria-labelledby="filipino-label">

                <label for="filipino" id="filipino-label">
                  <div class="label-content">Filipino</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="spanish" 
                  name="languages" 
                  value="spanish" 
                  aria-labelledby="spanish-label">

                <label for="spanish" id="spanish-label">
                  <div class="label-content">Spanish</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="hindi" 
                  name="languages" 
                  value="hindi" 
                  aria-labelledby="hindi-label">

                <label for="hindi" id="hindi-label">
                  <div class="label-content">Hindi</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="german" 
                  name="languages" 
                  value="german" 
                  aria-labelledby="german-label">

                <label for="german" id="german-label">
                  <div class="label-content">German</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="chinese" 
                  name="languages" 
                  value="chinese" 
                  aria-labelledby="chinese-label">

                <label for="chinese" id="chinese-label">
                  <div class="label-content">Chinese</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="punjabi" 
                  name="languages" 
                  value="punjabi" 
                  aria-labelledby="punjabi-label">

                <label for="punjabi" id="punjabi-label">
                  <div class="label-content">Punjabi</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="japanese" 
                  name="languages" 
                  value="japanese" 
                  aria-labelledby="japanese-label">

                <label for="japanese" id="japanese-label">
                  <div class="label-content">Japanese</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="russian" 
                  name="languages" 
                  value="russian" 
                  aria-labelledby="russian-label">

                <label for="russian" id="russian-label">
                  <div class="label-content">Russian</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="farsi" 
                  name="languages" 
                  value="farsi" 
                  aria-labelledby="farsi-label">

                <label for="farsi" id="farsi-label">
                  <div class="label-content">Farsi</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="arabic" 
                  name="languages" 
                  value="arabic" a
                  ria-labelledby="arabic-label">

                <label for="arabic" id="arabic-label">
                  <div class="label-content">Arabic</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="portuguese" 
                  name="languages" 
                  value="portuguese" 
                  aria-labelledby="portuguese-label">

                <label for="portuguese" id="portuguese-label">
                  <div class="label-content">Portuguese</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="korean" 
                  name="languages" 
                  value="korean" 
                  aria-labelledby="korean-label">

                <label for="korean" id="korean-label">
                  <div class="label-content">Korean</div>
                </label>
              </div>

              <div class="checkbox-container">
                <input 
                  v-model="renter_languages"
                  type="checkbox" 
                  id="malay" 
                  name="languages" 
                  value="malay" 
                  aria-labelledby="malay-label">

                <label for="malay" id="malay-label">
                  <div class="label-content">Malay</div>
                </label>
              </div>
            </div>

            <div v-if="formData.additional_languages.length > 0" id="additional-lang">

            </div>

            <div class="add-lang-container add-container" tabindex="0" role="button" aria-label="Add other language" @click="addLanguageClicked">
              <img src="@/assets/svgs/plus.svg" alt="Add icon">

              <p><strong>Add other language </strong></p>
            </div>

            <div id="langErrorText" class="validation-error">
              <span v-if="errors.renter_languages">{{ errors.renter_languages[0] }}</span>
            </div>
          </div>

          <div class="form-actions sticky-btm">
            <button type="submit" class="primary-btn">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import { useRenterProfile } from "@/stores/renter/useRenterProfile";
import { mapState, mapActions } from "pinia";

export default {
  name: "ProfileLanguages",
  data() {
    return {
      showModal: false,
      renter_languages: []
    };
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'errors', 'languagesText'])
  },
  methods: {
    ...mapActions(useRenterProfile, ['validateLanguages', 'clearErrors']),

    openModal() {
      this.renter_languages = this.formData.renter_languages;
      document.body.style.overflow = "hidden"; // Prevent background scroll
      this.showModal = true;
    },
    closeModal() {
      this.clearErrors();
      this.showModal = false;
      document.body.style.overflow = "";
      this.renter_languages = '';
    },
    submitForm() {
      const isValid = this.validateLanguages(this.renter_languages);
      if(!isValid) return;
      //TODO: Save data
      
      this.formData.renter_languages = this.renter_languages;
      this.closeModal()
    },
  },
};
</script>

<style scoped>

.custom-checkbox-container{
  flex-direction: column;
}
</style>