import { defineStore } from 'pinia';

export const useRenterCreateProfile = defineStore('createProfile', {
    state: () => ({
        currentStep: 1,
        maxSteps: 6,
        formData: {
            first_name: '',
            last_name: '',
            phone: '',
            contact_method: false,
            birth_year: '',
            province: '',
            password: '',
            email: '',
            agree_terms: false,
        },
        errors: {},
        provinces: {
            AB: 'Alberta',
            BC: 'British Columbia',
            MB: 'Manitoba',
            NB: 'New Brunswick',
            NL: 'Newfoundland and Labrador',
            NS: 'Nova Scotia',
            NT: 'Northwest Territories',
            NU: 'Nunavut',
            ON: 'Ontario',
            PE: 'Prince Edward Island',
            QC: 'Quebec',
            SK: 'Saskatchewan',
            YT: 'Yukon',
        },
    }),
    getters: {
    },
    actions: {
        nextStep() {
            const next = this.currentStep + 1;
            if (next <= this.maxSteps && this.validateStep()) {
                this.currentStep++;
            }
        },
        prevStep() {
            const prev = this.currentStep - 1;
            if (prev >= 1) {
                this.currentStep--;
            }
        },
        selectProvince(selectedProvince) {
            this.formData.province = selectedProvince;
        },
        validateName(name) {
            // Simple regex for name validation
            const nameRegex = /^[A-Za-z\s'-]{1,30}$/;
            return nameRegex.test(name);
        },
        validatePhoneNumber(phone) {
            // Simple regex for phone number validation (accepts digits, spaces, dashes, and parentheses)
            const phoneRegex = /^[0-9+\-\s()]{10,11}$/;
            return phoneRegex.test(phone);
        },
        validatePostalCode(postalCode) {
            // Canadian postal code format
            const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            return postalCodeRegex.test(postalCode);
        },
        validatePassword(password) {
            // Password criteria: at least 8 characters, including uppercase, lowercase, number, and special character
            const passwordRegex =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&])[A-Za-z\d@$!%*?&#]{8,}$/;
            return passwordRegex.test(password);
        },
        validateEmail(email) {
            // Simple email validation regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        validateStep() {
            this.errors = {};
            const currentYear = new Date().getFullYear();
            const minAllowedYear = currentYear - 18; // Must be at least 18 years old

            switch (this.currentStep) {
                case 1:
                    // Validate First Name and Last Name
                    if (!this.formData.first_name) {
                        this.errors.first_name = ['First name is required'];
                    } else if (!this.validateName(this.formData.first_name)) {
                        this.errors.first_name = ['First name contains invalid characters'];
                    }
                    if (!this.formData.last_name) {
                        this.errors.last_name = ['Last name is required'];
                    } else if (!this.validateName(this.formData.last_name)) {
                        this.errors.last_name = ['Last name contains invalid characters'];
                    }
                    break;

                case 2:
                    // Validate Phone Number
                    if (!this.formData.phone) {
                        this.errors.phone = ['Phone number is required'];
                    } else if (!this.validatePhoneNumber(this.formData.phone)) {
                        this.errors.phone = ['Invalid phone number'];
                    }
                    break;

                case 3:
                    // Validate Age (Birth Year)
                    if (!this.formData.birth_year) {
                        this.errors.birth_year = ['Birth year is required'];
                    } else if (
                        isNaN(this.formData.birth_year) ||
                        this.formData.birth_year < 1924 ||
                        this.formData.birth_year > currentYear
                    ) {
                        this.errors.birth_year = ['Invalid birth year'];
                    } else if (this.formData.birth_year > minAllowedYear) {
                        this.errors.birth_year = ['You must be at least 18 years old to register'];
                    }
                    break;

                case 4:
                    // Validate Province
                    if (!this.formData.province) {
                        this.errors.province = ['Province is required'];
                    }

                    break;

                case 5:
                    // Validate Password
                    if (!this.formData.password) {
                        this.errors.password = ['Password is required'];
                    } else if (!this.validatePassword(this.formData.password)) {
                        this.errors.password = [
                            'Password must be at least 8 characters and include uppercase, lowercase, number, and special character',
                        ];
                    }
                    break;

                case 6:
                    // Validate Email
                    if (!this.formData.email) {
                        this.errors.email = ['Email is required'];
                    } else if (!this.validateEmail(this.formData.email)) {
                        this.errors.email = ['Invalid email address'];
                    }

                    if (!this.formData.agree_terms) {
                        this.errors.agree_terms = ['You must agree to the terms'];
                    }
                    break;
                default:
                    break;
            }

            return Object.keys(this.errors).length === 0;
        },
        validateAll() {
            this.errors = {};

            // Validate First Name and Last Name
            if (!this.formData.first_name) {
                this.errors.first_name = ['First name is required'];
            } else if (!this.validateName(this.formData.first_name)) {
                this.errors.first_name = ['First name contains invalid characters'];
            }
            if (!this.formData.last_name) {
                this.errors.last_name = ['Last name is required'];
            } else if (!this.validateName(this.formData.last_name)) {
                this.errors.last_name = ['Last name contains invalid characters'];
            }

            // Validate Phone Number
            if (!this.formData.phone) {
                this.errors.phone = ['Phone number is required'];
            } else if (!this.validatePhoneNumber(this.formData.phone)) {
                this.errors.phone = ['Invalid phone number'];
            }

            // Validate Age (Birth Year)
            const currentYear = new Date().getFullYear();
            const minAllowedYear = currentYear - 18; // Must be at least 18 years old
            if (!this.formData.birth_year) {
                this.errors.birth_year = ['Birth year is required'];
            } else if (
                isNaN(this.formData.birth_year) ||
                this.formData.birth_year < 1924 ||
                this.formData.birth_year > currentYear
            ) {
                this.errors.birth_year = ['Invalid birth year'];
            } else if (this.formData.birth_year > minAllowedYear) {
                this.errors.birth_year = ['You must be at least 18 years old to register'];
            }

            // Validate Province
            if (!this.formData.province) {
                this.errors.province = ['Province is required'];
            }


            // Validate Password
            if (!this.formData.password) {
                this.errors.password = ['Password is required'];
            } else if (!this.validatePassword(this.formData.password)) {
                this.errors.password = [
                    'Password must be at least 8 characters and include uppercase, lowercase, number, and special character',
                ];
            }

            // Validate Email
            if (!this.formData.email) {
                this.errors.email = ['Email is required'];
            } else if (!this.validateEmail(this.formData.email)) {
                this.errors.email = ['Invalid email address'];
            }

            if (!this.formData.agree_terms) {
                this.errors.agree_terms = ['You must agree to the terms'];
            }

            return Object.keys(this.errors).length === 0;
        },
    },
});