<template>
  <div class="renter-verification-container renter-verification-step-2">
    <div class="container flex-container">
      <div class="img-container">
        <img src="@/assets/images/certn-logo.webp" alt="Certn Logo">
      </div>

      <div class="verification-container mb-4">
        <div class="verification-content">
          <h1 class="heading-2 mb-6">We value your privacy.</h1>

          <div class="verification-details">
            <p>
              By clicking the button below, you consent to <strong>CERTN</strong>, our trusted partner, collecting, using, and utilising their service providers to process your biometric information to verify your identity, identify fraud, in accordance with its Privacy Policy.
              <br><br>
              <strong>CERTN</strong> uses facial recognition to match a selfie in real-time with identification documents. The report will confirm the name, date of birth, address, document type, issuing country and expiry date of their identification documents.
              <br><br>
              Read our <a href="https://happipad.com/privacy/" target="_blank">Privacy policy</a> to get more details on how we ensure your privacy.
            </p>
          </div>

          <div class="info-note mt-4">
            <img src="@/assets/svgs/info.svg" alt="Info">
            <p>Neither we nor CERTN will store your ID details or biometric digital map resulting from your facial scan.
            </p>
          </div>

          <div class="btns-container mt-6 sticky-btm">
            <button type="button" class="secondary-btn prev-step" @click="rejectClicked">Reject</button>
            <button class="primary-btn next-step" @click="nextStep">Agree and proceed</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useRenterVerification } from "@/stores/renter/verification/useRenterVerification";
import { mapActions } from "pinia";

export default {
  name: "RenterVerificationStep2",
  methods: {
    ...mapActions(useRenterVerification, ['nextStep', 'resetStep']),

    rejectClicked(){
      this.resetStep();
      this.$router.push('/');
    }
  }
};
</script>