<template>
  <section aria-labelledby="employment-heading" class="card" tabindex="0">
    <div class="employment-section">
      <div class="edit-info">
        <h2 id="employment-heading" class="heading-6">{{ formData.renter_firstName }}'s employment</h2>

        <a href="javascript:void(0)" class="edit-link" @click="openModal">
          <img src="@/assets/svgs/edit.svg" alt="edit">
          Edit
        </a>
      </div>

      <div class="additional-info">
        <p v-for="(item, index) in formData.renter_employment" :key="index">
          <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
          {{ capitalize(item) }}
        </p>
        <p v-if="otherEmploymentSelected && formData.renter_other_employment !== ''">
          <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
          {{ formData.renter_other_employment }}
        </p>
        <p v-if="studentEmploymentSelected && formData.renter_school !== ''">
          <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
          {{ formData.renter_school }}
        </p>
      </div>
    </div>


    <div v-show="showModal" class="modal-overlay show" @click.self="closeModal">
      <div class="modal-container">
        <div class="modal-header">
          <p>Employment</p>
          <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
        </div>

        <!-- Integrated Form in Modal -->
        <div class="form-container">
          <h2 class="heading-4">Employment details</h2>

          <form id="screening-details-form" @submit.prevent="submitForm">
            <div class="form-group">
              <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="employment-label">
                <div class="checkbox-container">
                  <input 
                    v-model="employment"
                    type="checkbox" 
                    id="full-time-employee" 
                    name="employment" 
                    value="Full-time employee">

                  <label for="full-time-employee">Full-time employee</label>
                </div>

                <div class="checkbox-container">
                  <input 
                    v-model="employment"
                    type="checkbox" 
                    id="part-time-employee" 
                    name="employment" 
                    value="part-time employee">

                  <label for="part-time-employee">Part-time employee</label>
                </div>

                <div class="checkbox-container">
                  <input 
                    v-model="employment"
                    type="checkbox" 
                    id="student" 
                    name="employment" 
                    value="student">

                  <label for="student">Student</label>
                </div>

                <div class="checkbox-container">
                  <input 
                    v-model="employment"
                    type="checkbox" 
                    id="work-study-home" 
                    name="employment" 
                    value="Work/study from home">

                  <label for="work-study-home">Work/study from home</label>
                </div>

                <div class="checkbox-container">
                  <input 
                    v-model="employment"
                    type="checkbox" 
                    id="self-employed" 
                    name="employment" 
                    value="Self-employed/business owner">

                  <label for="self-employed">Self-employed/business owner</label>
                </div>

                <div class="checkbox-container">
                  <input 
                    v-model="employment"
                    type="checkbox" 
                    id="retired" 
                    name="employment" 
                    value="Retired">

                  <label for="retired">Retired</label>
                </div>

                <div class="checkbox-container">
                  <input 
                    v-model="employment"
                    type="checkbox" 
                    id="other" 
                    name="employment" 
                    value="other">

                  <label for="other">Other</label>
                </div>
              </div>

              <div v-if="employment.includes('other')" class="form-group mt-5" id="other-employment-container">
                <label class="heading-6" for="other-employment-description">Please provide information.</label>

                <input
                  v-model="other_employment"
                  type="text" 
                  name="other-employment-description" 
                  placeholder="Start typing.."
                  id="other-employment-description">
              </div>

              <div v-if="employment.includes('student')" class="form-group mt-5" id="student-container">
                <label class="heading-6" for="student-description">Add your Alma Mater.</label>
                <p>By adding your college or university, you can get more trust from potential hosts.</p>

                <input
                  v-model="school"
                  type="text" 
                  name="student-description" 
                  placeholder="Start typing.." 
                  id="student-description">
              </div>
            </div>

            <div class="form-actions sticky-btm">
              <button type="submit" class="primary-btn">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  

</template>

<script>
import { useRenterProfile } from "@/stores/renter/useRenterProfile";
import { mapState, mapActions } from "pinia";

export default {
  name: "ProfileEmployment",
  data() {
    return {
      showModal: false,
      employment: [],
      other_employment: '',
      school: ''
    };
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'errors', 'otherEmploymentSelected', 'studentEmploymentSelected'])
  },
  methods: {
    ...mapActions(useRenterProfile, ['validateLanguages', 'clearErrors', 'capitalize']),

    openModal() {
      this.employment = this.formData.renter_employment;
      this.other_employment = this.formData.renter_other_employment;
      this.school = this.formData.renter_school;
      document.body.style.overflow = "hidden"; // Prevent background scroll
      this.showModal = true;
    },
    closeModal() {
      this.clearErrors();
      this.showModal = false;
      document.body.style.overflow = "";

      this.employment = '';
      this.other_employment = '';
      this.school = '';
    },
    submitForm() {
      //TODO: Save data
      
      this.formData.renter_employment = this.employment;
      this.formData.renter_other_employment = this.otherEmploymentSelected ? this.other_employment : '';
      this.formData.renter_school = this.studentEmploymentSelected ? this.school : '';
      this.closeModal()
    },
  },
};
</script>

<style scoped>

.custom-checkbox-container{
  flex-direction: column;
}
</style>