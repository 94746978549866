<template>
 <RenterDashboardTopNav />

 <div class="listing-template">
  <div id="imageGallery" class="image-gallery">
    <!-- Main Image -->
    <div class="main-image hidden-mobile">
      <img src="@/assets/images/mystay-placeholder.jpeg" alt="Main house view" />
    </div>

    <!-- Main Image mobile view -->
    <div class="main-image overlay hidden-desktop">
      <img src="@/assets/images/mystay-placeholder.jpeg" alt="Main house view" />

      <button class="favorite-icon">
        <img src="@/assets/svgs/heart-white.svg" alt="favourite" />
      </button>

      <div class="overlay-content">
        <p>4/8</p>
        <img src="@/assets/svgs/image-white.svg" alt="images" />
      </div>
    </div>

    <!-- Thumbnails -->
    <div class="thumbnails hidden-mobile">
      <div class="thumbnail">
        <img src="@/assets/images/mystay-placeholder.jpeg" alt="Thumbnail 1" />
      </div>

      <div class="thumbnail">
        <img src="@/assets/images/mystay-placeholder.jpeg" alt="Thumbnail 2" />

        <button class="favorite-icon">
          <img src="@/assets/svgs/heart-white.svg" alt="favourite" />
        </button>
      </div>

      <div class="thumbnail">
        <img src="@/assets/images/mystay-placeholder.jpeg" alt="Thumbnail 3" />
      </div>

      <div class="thumbnail overlay">
        <img src="@/assets/images/mystay-placeholder.jpeg" alt="Thumbnail 4" />

        <div class="overlay-content">
          <p>+15</p>
          <img src="@/assets/svgs/image-white.svg" alt="images" />
        </div>
      </div>
    </div>
  </div>

  <!-- Tabs Section -->
  <div id="listingInfoHeadings" class="card listing-info-headings hidden-mobile">
    <ul>
      <li class="active">
        <a href="#description"><strong>Description</strong></a>
      </li>

      <li>
        <a href="#location-insights">Location Insights</a>
      </li>

      <li>
        <a href="#reviews">Reviews</a>
      </li>

      <li>
        <a href="#house-features">House features</a>
      </li>

      <li>
        <a href="#room-furniture">Room furniture</a>
      </li>

      <li>
        <a href="#house-rules">House rules</a>
      </li>

      <li>
        <a href="#availability">Availability</a>
      </li>

      <li>
        <a href="#host">Host</a>
      </li>

      <li>
        <a href="#sharing-with">Sharing with</a>
      </li>
    </ul>

    <button class="tertiary-btn">Scroll up <img src="@/assets/svgs/chevron-up-blue-circle.svg" alt="chevron" /></button>
  </div>

  <div class="listing-container">
    <div class="listing-details">
      <!-- information -->
      <section class="card listing-info">
          <span class="sub-info hidden-desktop">
            <img src="@/assets/svgs/location-light-gray.svg" alt="location" />
            Toronto, Keel st. and Lakeshore blv.
          </span>

          <h2 class="heading-5">Room with shared bath and kitchen in Corktown, Toronto.</h2>

          <div class="features-container">
            <div class="column">
              <div class="additional-info-container">
                <div class="additional-info">
                  <img src="@/assets/svgs/building-dark.svg" alt="condo" />
                  <p>Condo</p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="additional-info-container">
                <div class="additional-info">
                  <img src="@/assets/svgs/private-room.svg" alt="Room" />
                  <p>Room</p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="additional-info-container">
                <div class="additional-info">
                  <img src="@/assets/svgs/bath-tub.svg" alt="Shared bath" />
                  <p>Shared bath</p>
                </div>
              </div>
            </div>
          </div>

          <p>With a king-size bed, separate seating area, larger work desk, and a 55-inch flat-screen TV, these rooms are perfect for those who want to unwind in style. The en-suite bathroom boasts a are perfect for thasmk ...With a king-size bed, separate seating area, larger work desk, and a 55-inch flat-screen TV, these rooms are perfect for those who want to unwind in style. The en-suite bathroom boasts a are perfect for thasmk ...With a king-size bed, separate seating area, larger work desk, and a 55-inch flat-screen TV, these rooms are perfect for those who want to unwind in style. The en-suite bathroom boasts a are perfect for thasmk ...With a king-size bed, separate seating area, larger work desk, and a 55-inch flat-screen TV, these rooms are perfect for those who want to unwind in style. The en-suite bathroom boasts a are perfect for thasmk ...</p>

          <a href="#" class="hidden-desktop">Read more</a>
      </section>

      <!-- Dates -->
      <section class="card listing-dates">
        <h2 class="heading-6">Dates</h2>
        <p>Jan 14, 2025 - Jan 14, 2026</p>
      </section>

        <!-- insurance -->
        <section class="card listing-insurance">
        <img src="@/assets/images/happicover-logo.png" alt="Happicover">

        <p>Every booking includes protection from accidents, cancellations, and other issues during renting.
          <img src="@/assets/svgs/chevron-right-dark.svg" alt="">
        </p>
      </section>

      <!-- Location -->
      <section class="card listing-location">
        <p>Toronto, Keel st. and Lakeshore blv.
        <span class="sub-info">Exact location is provided after booking.</span>
        </p>

        <div class="map-container">
          <img src="@/assets/images/listing-location-placeholder.png" alt="map">
        </div>

        <a href="#" class="view-more">
            <img src="@/assets/svgs/map.svg" alt="">
            View in a map
        </a>
      </section>

      <!-- Location Insights -->
      <section class="card listing-location-insights">
        <h2 class="heading-6">Location Insights</h2>

        <div class="info-tab">
            <div class="info-tab-header">
              <div>
                <img src="@/assets/svgs/shopping-cart.svg" alt="">
                <p>Groceries</p>
              </div>

              <img src="@/assets/svgs/chevron-up-dark.svg" alt="">
            </div>

            <div class="info-tab-content">
              <p>Supermarket No frills
                <span class="sub-info">285 GT8, 243 Dundas, Toronto</span>
                <span class="distance">500 m</span>
              </p>
            </div>

            <div class="info-tab-content">
              <p>Supermarket No frills
                <span class="sub-info">285 GT8, 243 Dundas, Toronto</span>
                <span class="distance">500 m</span>
              </p>
            </div>
        </div>

        <div class="info-tab">
            <div class="info-tab-header">
              <div>
                <img src="@/assets/svgs/school.svg" alt="school">
                <p>School</p>
              </div>

              <img src="@/assets/svgs/chevron-down-dark.svg" alt="">
            </div>
        </div>

        <div class="info-tab">
            <div class="info-tab-header">
              <div>
                <img src="@/assets/svgs/car.svg" alt="car">
                <p>Transit</p>
              </div>

              <img src="@/assets/svgs/chevron-down-dark.svg" alt="">
            </div>
        </div>
      </section>

      <!-- Rating -->
      <section class="card listing-rating">
        <h2 class="heading-6">Rating</h2>

        <div class="rating-summary">
          <div class="rating-header">
            <div>
              <p class="rating-score"><strong>4.5</strong></p>

              <img src="@/assets/svgs/star.svg" alt="">
            </div>

            <span class="rating-count sub-info">273 Reviews</span>
          </div>

          <div class="rating-bars">
            <div class="rating-bar">
              <p class="rating-label">5</p>

              <div class="bar-container">
                <div class="bar-fill" style="width: 100%;"></div>
              </div>
            </div>

            <div class="rating-bar">
              <p class="rating-label">4</p>

              <div class="bar-container">
                <div class="bar-fill" style="width: 75%;"></div>
              </div>
            </div>

            <div class="rating-bar">
              <p class="rating-label">3</p>

              <div class="bar-container">
                <div class="bar-fill" style="width: 50%;"></div>
              </div>
            </div>

            <div class="rating-bar">
              <p class="rating-label">2</p>

              <div class="bar-container">
                <div class="bar-fill" style="width: 25%;"></div>
              </div>
            </div>

            <div class="rating-bar">
              <p class="rating-label">1</p>

              <div class="bar-container">
                <div class="bar-fill" style="width: 10%;"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Reviews -->
      <section class="card listing-reviews">
        <h2 class="heading-6">Reviews</h2>

        <div class="review-card">
          <div class="review-header">
            <img src="@/assets/images/profile-user-placeholder.jpeg" alt="profile" class="review-avatar" />

            <div class="review-info">
              <p class="review-author"><strong>John Doe</strong></p>

              <div class="review-rating">
                <img src="@/assets/svgs/star.svg" alt="">
                <img src="@/assets/svgs/star.svg" alt="">
                <img src="@/assets/svgs/star.svg" alt="">
                <img src="@/assets/svgs/star.svg" alt="">
                <img src="@/assets/svgs/star.svg" alt="">
              </div>
            </div>
          </div>

          <p class="review-content">
            My wife and I had a dream of downsizing from our house in Cape Elizabeth into a small condo closer to where we work and play in Portland. My wife and I had a dream of downsizing from our house in Cape Elizabeth into a small condo closer to where we work and play in Portland.My wife and I had a dream of downsizing from our house in Cape Elizabeth into a small condo closer to where we work and play in Portland.

            <a href="#" class="hidden-desktop mt-2">Read more</a>
          </p>

          <a href="#" class="view-more">Show all 14 reviews
            <img src="@/assets/svgs/chevron-down-blue.svg" alt="">
          </a>
        </div>
      </section>

      <!-- Features -->
      <section class="card listing-features">
          <h2 class="heading-6">House/Condo Features</h2>

          <div class="features-container">
            <div class="column">
              <div class="additional-info-container">
                <p><strong>Bathroom</strong></p>

                <div class="additional-info">
                  <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
                  <p>Shared full Bath</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
                  <p>Shared full Bath</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/couple.svg" alt="couple" />
                  <p>Shared with 3 people</p>
                </div>
              </div>

              <div class="additional-info-container">
                <p><strong>Safety features</strong></p>

                <div class="additional-info">
                  <img src="@/assets/svgs/fire-extinguisher.svg" alt="fire extinguisher" />
                  <p>Fire extinguisher</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/security-alarm.svg" alt="security alarm" />
                  <p>Security alarm</p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="additional-info-container">
                <p><strong>Amenities</strong></p>

                <div class="additional-info">
                  <img src="@/assets/svgs/air-conditioner.svg" alt="air conditioner" />
                  <p>Air conditioning</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/tv.svg" alt="tv" />
                  <p>Television</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/washer.svg" alt="washer" />
                  <p>Washer</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/wifi.svg" alt="wifi" />
                  <p>Wi-Fi</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/kitchen.svg" alt="kitchenware" />
                  <p>Kitchenware</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/dryer.svg" alt="dryer" />
                  <p>Dryer</p>
                </div>
              </div>

              <div class="additional-info-container">
                <p><strong>Building facilities</strong></p>

                <div class="additional-info">
                  <img src="@/assets/svgs/elevator.svg" alt="elevator" />
                  <p>Elevator</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/onsite-management.svg" alt="onsite management" />
                  <p>Onsite management</p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="additional-info-container">
                <p><strong>Other shared rooms</strong></p>

                <div class="additional-info">
                  <img src="@/assets/svgs/kitchen.svg" alt="kitchen" />
                  <p>Kitchen</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/living-room.svg" alt="Living room" />
                  <p>Living room</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/patio.svg" alt="Patio" />
                  <p>Patio</p>
                </div>
              </div>

              <div class="additional-info-container">
                <p><strong>Provided for renter</strong></p>

                <div class="additional-info">
                  <img src="@/assets/svgs/dish-detergent.svg" alt="dish detergent" />
                  <p>Dish detergent</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/toilet-paper.svg" alt="Toilet paper" />
                  <p>Toilet paper</p>
                </div>
              </div>
            </div>
          </div>

          <a href="#" class="view-more mt-3">
            Show 3 more

            <img src="@/assets/svgs/chevron-down-blue.svg" alt="">
        </a>
      </section>

      <!-- Renter's bedroom -->
      <section class="card listing-features">
          <h2 class="heading-6">Renter’s bedroom</h2>

          <div class="features-container">
            <div class="column">
              <div class="additional-info-container">
                <p><strong>Size</strong></p>

                <div class="additional-info">
                  <img src="@/assets/svgs/size.svg" alt="size" />
                  <p>200 ft2</p>
                </div>
              </div>

              <div class="additional-info-container">
                <p><strong>Furniture</strong></p>

                <div class="additional-info">
                  <img src="@/assets/svgs/bed.svg" alt="Queen bed" />
                  <p>Queen bed</p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="additional-info-container">
                <div class="additional-info">
                  <img src="@/assets/svgs/table.svg" alt="Table" />
                  <p>Table</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/towels.svg" alt="Towels" />
                  <p>Towels</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/closet.svg" alt="Closet" />
                  <p>Closet</p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="additional-info-container">
                <div class="additional-info">
                  <img src="@/assets/svgs/safe.svg" alt="In-room safe" />
                  <p>In-room safe</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/drawers.svg" alt="Drawers" />
                  <p>Drawers</p>
                </div>

                <div class="additional-info">
                  <img src="@/assets/svgs/chair.svg" alt="Chair" />
                  <p>Chair</p>
                </div>
              </div>
            </div>
          </div>

          <a href="#" class="view-more mt-3">
            Show 3 more

            <img src="@/assets/svgs/chevron-down-blue.svg" alt="">
        </a>
      </section>

      <!-- Rules -->
      <section class="card listing-features">
        <h2 class="heading-6">House rules</h2>

        <div class="features-container">
          <div class="column">
            <div class="additional-info-container">
              <div class="additional-info">
                <img src="@/assets/svgs/no-smoking.svg" alt="smoking" />
                <p>Smoking only outside</p>
              </div>
            </div>
          </div>

          <div class="column">
            <div class="additional-info-container">
              <div class="additional-info">
                <img src="@/assets/svgs/no-animals.svg" alt="no-pets" />
                <p>No animals allowed</p>
              </div>
            </div>
          </div>

          <div class="column">
            <div class="additional-info-container">
              <div class="additional-info">
                <img src="@/assets/svgs/visitors.svg" alt="Notice on visitors" />
                <p>Notice on visitors</p>
              </div>
            </div>
          </div>
        </div>

        <p><strong>Additionally:</strong> Coin laundry can be used only in the mornings. Cleaning 2 times per week.</p>
      </section>

      <!-- Availability -->
      <section class="card">
        <h2 class="heading-6">Availability</h2>
        <p>calendar widget here</p>
      </section>

      <!-- Host -->
      <section class="card listing-host-profile">
        <h2 class="heading-6">Meet the Host</h2>

        <div class="host-profile">
          <div>
            <img src="@/assets/images/profile-user-placeholder.jpeg" alt="Host Image" class="host-image" />

            <p>
              John K.
              <span class="sub-info">Property owner</span>
            </p>
          </div>

          <img src="@/assets/svgs/chevron-right-dark.svg" alt="">
        </div>
      </section>

      <!-- Message Host -->
      <section class="card listing-message-host">
        <h2 class="heading-6">Message Host</h2>

        <div class="send-message">
          <form action="#" method="POST">
            <textarea id="message-input" placeholder="Hello John, I hope you're doing well. I’m interested in your property and wanted to check if it's still available. Looking forward to hearing from you!"
              rows="3"></textarea>

            <button class="primary-btn" type="submit" aria-label="Send Message">Send</button>
          </form>
        </div>
      </section>

      <!-- Preferences -->
      <section class="card listing-features">
          <h2 class="heading-6">Renting preference</h2>

          <div class="features-container">
            <div class="column">
              <div class="additional-info-container">
                <div class="additional-info">
                  <img src="@/assets/svgs/students.svg" alt="student" />
                  <p>Student</p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="additional-info-container">
                <div class="additional-info">
                  <img src="@/assets/svgs/desk.svg" alt="Professional" />
                  <p>Professional</p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="additional-info-container">
                <div class="additional-info">
                  <img src="@/assets/svgs/female.svg" alt="Female" />
                  <p>Female</p>
                </div>
              </div>
            </div>
          </div>
      </section>

      <!-- Cancellation Policy -->
      <section class="card listing-policy">
        <h2 class="heading-6">Cancellation policy</h2>

        <div class="policy-container">
          <div>
            <p>
              Free cancellation is applied
              <span class="sub-info">1 month prior to lease start</span>
            </p>
          </div>

          <img src="@/assets/svgs/chevron-right-dark.svg" alt="">
        </div>
      </section>

      <!-- Sharing With -->
      <section class="card listing-sharing-with">
        <h2 class="heading-6">Sharing with</h2>

        <div class="info-tab">
          <div class="info-tab-header">
            <div>
              <img src="@/assets/svgs/users.svg" alt="people">

              <div>
                <p>4 people</p>
                <span class="sub-info">Host, Other tenants</span>
              </div>
            </div>
          </div>
        </div>

        <div class="info-tab">
          <div class="info-tab-header">
            <div>
              <img src="@/assets/svgs/pet.svg" alt="dog">

              <div>
                <p>2 Dogs</p>
                <span class="sub-info">“We have 2 amazing cats and a dog.”</span>
              </div>
            </div>
          </div>
        </div>

        <div class="pet-imgs">
          <img src="@/assets/images/profile-pet-placeholder.jpeg" alt="map">
          <img src="@/assets/images/profile-pet-2-placeholder.jpeg" alt="map">
          <img src="@/assets/images/profile-pet-3-placeholder.jpeg" alt="map">
        </div>
      </section>
    </div>

    <aside class="hidden-mobile">
      <div class="card listing-price">
        <div class="listing-price-container">
          <div class="price-header">
            <p><strong>Price details</strong></p>
            <img src="@/assets/svgs/chevron-up-dark.svg" alt="">
          </div>

          <div class="listing-price-info hidden">
            <div class="price-details">
              <div class="pricing-item">
                <span class="label">Monthly rent</span>
                <span class="value">$1,555</span>
              </div>

              <div class="pricing-item">
                <span class="label">Happicover Insurance</span>
                <span class="value">$20</span>
              </div>

              <div class="pricing-item">
                <span class="label">Discount</span>
                <span class="value discount">-$19</span>
              </div>
            </div>

            <div class="total">
              <span class="label"><strong>Total monthly</strong></span>
              <span class="value"><strong>$1,621</strong></span>
            </div>
          </div>

          <div class="listing-price-info">
            <div class="listing-price-info-header">
              <p class="listing-price-amount"><strong>C$ 1500</strong><span>/month</span></p>
              <p class="listing-deposit">C$ 800 deposit</p>
            </div>

            <div class="additional-info">
              <img src="@/assets/svgs/checkmark-dark.svg" alt="checkmark" />
              <p>Utilities included</p>
            </div>
          </div>
        </div>

        <div class="listing-dates">
          <p><strong>Dates</strong></p>
          <p class="sub-info">Jan 14, 2025 - Jan 14, 2026</p>
        </div>

        <div class="message-host">
          <p><strong>Message</strong></p>

          <div class="send-message">
            <form action="#" method="POST">
              <textarea id="message-input" placeholder="Hello 😀 John, I hope you're doing well. I’m interested in your property. Let me know if it’s still available."
                rows="3"></textarea>

              <button class="primary-btn mt-2" type="submit" aria-label="Send to host">Send to host</button>
            </form>
          </div>
        </div>
      </div>

      <div class="card next-steps-container">
        <div class="next-steps">
          <!-- Step 1 -->
          <div class="next-steps-item active">
            <div class="next-steps-icon">
              <span>1</span>
            </div>

            <div class="next-steps-content">
              <p><strong>Pick a place and send inquiry</strong></p>

              <span class="sub-info">No payment required</span>
            </div>
          </div>

          <!-- Step 2 -->
          <div class="next-steps-item">
            <div class="next-steps-icon">
              <span>2</span>
            </div>

            <div class="next-steps-content">
              <p><strong>Book and pay deposit</strong></p>
              <span class="sub-info">C$ 800 deposit</span>
            </div>
          </div>

          <!-- Step 3 -->
          <div class="next-steps-item">
            <div class="next-steps-icon">
              <span>3</span>
            </div>

            <div class="next-steps-content">
              <p><strong>Move in and pay rent</strong></p>
              <span class="sub-info">C$ 1 500 rent</span>
            </div>
          </div>
        </div>
      </div>
    </aside>
  </div>

  <div class="sticky-btm">
    <div class="listing-price-info">
      <div class="listing-price-info-header">
        <p class="listing-price-amount"><strong>C$ 1500</strong><span>/month</span></p>
      </div>

      <div class="additional-info">
        <img src="@/assets/svgs/checkmark-dark.svg" alt="checkmark" />
        <p>Utilities included</p>
      </div>
    </div>

    <button class="primary-btn">Check availability</button>

    <div class="info-note mb-2">
      <img src="@/assets/svgs/info.svg" alt="Info">

      <p>Inquiry was sent for Jan 14, 2025 - Jan 14, 2026</p>

      <img src="@/assets/svgs/close-circle.svg" alt="close">
    </div>
  </div>
 </div>

 <RenterDashboardBottomNav />
</template>

<script>
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

export default {
 name: "SingleListing",
 components: {
   RenterDashboardBottomNav,
   RenterDashboardTopNav,
 },
 mounted(){
  const imageGallery = document.getElementById("imageGallery");
  const listingInfoHeadings = document.getElementById("listingInfoHeadings");

  function handleScroll() {
    const bounding = imageGallery.getBoundingClientRect();

    if (bounding.bottom <= 0) {
      listingInfoHeadings.classList.add("sticky");
    } else {
      listingInfoHeadings.classList.remove("sticky");
    }
  }

  window.addEventListener("scroll", handleScroll);
}
};

// document.addEventListener("DOMContentLoaded", () => {
//   const imageGallery = document.getElementById("imageGallery");
//   const listingInfoHeadings = document.getElementById("listingInfoHeadings");

//   function handleScroll() {
//     const bounding = imageGallery.getBoundingClientRect();

//     if (bounding.bottom <= 0) {
//       listingInfoHeadings.classList.add("sticky");
//     } else {
//       listingInfoHeadings.classList.remove("sticky");
//     }
//   }

//   window.addEventListener("scroll", handleScroll);
// });
</script>
