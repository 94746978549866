<template>
  <section aria-labelledby="email-heading" class="card" tabindex="0">
    <div class="email-section">
      <div class="edit-info">
        <h2 id="email-heading" class="heading-6">Email</h2>

        <a href="javascript:void(0)" class="edit-link" @click="openModal">
          <img src="@/assets/svgs/edit.svg" alt="edit">
          Edit
        </a>
      </div>

      <p>{{ formData.renter_email }}</p>
    </div>

    <div v-show="showModal" class="modal-overlay show" @click.self="closeModal">
      <div class="modal-container">
        <div class="modal-header">
          <p>Email</p>
          <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
        </div>

        <!-- Integrated Form in Modal -->
        <div class="form-container">
          <h2 class="heading-4">Email details</h2>

          <form id="screening-details-form" @submit.prevent="submitForm">
            <div class="form-group">
              <input 
                v-model="email"
                type="text" 
                name="email" 
                placeholder="Enter your email.." 
                id="email" 
                required />

                <div id="addressErrorText" class="validation-error">
                  <span v-if="errors.renter_email">{{ errors.renter_email[0] }}</span>
                </div>
            </div>

            <div class="form-actions sticky-btm">
              <button type="submit" class="primary-btn">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  

</template>

<script>
import { useRenterProfile } from "@/stores/renter/useRenterProfile";
import { mapState, mapActions } from "pinia";

export default {
  name: "ProfileEmail",
  data() {
    return {
      showModal: false,
      email: '',
    };
  },
  computed: {
    ...mapState(useRenterProfile, ['formData', 'errors'])
  },
  methods: {
    ...mapActions(useRenterProfile, ['validateEmail', 'clearErrors', 'capitalize']),

    openModal() {
      this.email = this.formData.renter_email;
      document.body.style.overflow = "hidden"; // Prevent background scroll
      this.showModal = true;
    },
    closeModal() {
      this.clearErrors();
      this.showModal = false;
      document.body.style.overflow = "";

      this.email = '';
    },
    submitForm() {const isValid = this.validateEmail(this.email);
      if(!isValid) return;
      //TODO: Save data
      
      this.formData.renter_email = this.email;
      this.closeModal()
    },
  },
};
</script>

<style scoped>

.custom-checkbox-container{
  flex-direction: column;
}
</style>