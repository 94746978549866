<template>
  <div class="form-step active">
    <div>
      <h2 class="heading-2">What describes your employment?</h2>
      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="employment-label">
        <div class="checkbox-container">
          <input 
            v-model="formData.employment"
            type="checkbox" 
            id="full-time-employee" 
            name="employment" 
            value="Full-time employee">

          <label for="full-time-employee">Full-time employee</label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.employment"
            type="checkbox" 
            id="part-time-employee" 
            name="employment" 
            value="part-time employee">

          <label for="part-time-employee">Part-time employee</label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.employment"
            type="checkbox" 
            id="student" 
            name="employment" 
            value="student">

          <label for="student">Student</label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.employment"
            type="checkbox" 
            id="work-study-home" 
            name="employment" 
            value="Work/study from home">

          <label for="work-study-home">Work/study from home</label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.employment"
            type="checkbox" 
            id="self-employed" 
            name="employment" 
            value="Self-employed/business owner">

          <label for="self-employed">Self-employed/business owner</label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.employment"
            type="checkbox" 
            id="retired" 
            name="employment" 
            value="Retired">

          <label for="retired">Retired</label>
        </div>

        <div class="checkbox-container">
          <input 
            v-model="formData.employment"
            type="checkbox" 
            id="other" 
            name="employment" 
            value="other">

          <label for="other">Other</label>
        </div>
      </div>

      <div v-if="otherEmploymentSelected" class="form-group mt-5" id="other-employment-container">
        <label class="heading-6" for="other-employment-description">Please provide information.</label>

        <input
          v-model="formData.other_employment"
          type="text" 
          name="other-employment-description" 
          placeholder="Start typing.."
          id="other-employment-description">
      </div>

      <div v-if="studentEmploymentSelected" class="form-group mt-5" id="student-container">
        <label class="heading-6" for="student-description">Add your Alma Mater.</label>
        <p>By adding your college or university, you can get more trust from potential hosts.</p>

        <input
          v-model="formData.school"
          type="text" 
          name="student-description" 
          placeholder="Start typing.." 
          id="student-description">
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
      <button type="button" class="primary-btn next-step"  @click="nextStep">Next</button>
    </div>
  </div>
</template>

<script>
import { useRenterProfile } from "@/stores/renter/profile/useRenterProfile";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterProfileStep7",
  data() {
    return {};
  },
  computed: {
    ...mapState(useRenterProfile, [
      'formData', 
      'otherEmploymentSelected', 
      'studentEmploymentSelected',
    ])
  },
  methods: {
    ...mapActions(useRenterProfile, ['nextStep', 'prevStep']),

    addLanguageClicked() {
      //TODO
    }
  }
};
</script>