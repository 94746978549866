import { defineStore } from 'pinia';

export const useRenterVerifyEmail = defineStore('verifyEmail', {
    state: () => ({
        email: 'Jenifer@gmail.com',
        time_left: 30,
        disable_button: true,
        countdown: null,
    }),
    getters: {
        getTime() {
            if (this.time_left <= 0) return '00';
            return this.time_left.toString().padStart(2, '0');
        }
    },
    actions: {
        resetTimer() {
            this.time_left = 30;
            this.disable_button = true;
            this.startTimer();
        },
        decrimentTime() {
            if (this.time_left <= 0) return;

            this.time_left--;
            if (this.time_left <= 0) this.disable_button = false;
        },
        startTimer() {
            this.countdown = setInterval(() => {
                this.decrimentTime();

                if (this.time_left <= 0) {
                    clearInterval(this.countdown);
                }
            }, 1000);
        }
    },
});