import { defineStore } from 'pinia';

export const useHostProfile = defineStore('profile', {
    state: () => ({
        formData: {
            first_name: 'John',
            // last_name: 'Doe',
            age: 54,
            picture: require('@/assets/images/profile-user-placeholder.jpeg'),
            story: 'As a single dad working full time. Looking for someone who would be interested in renting a basement with separate entrance. As a single dad working full time. Looking for someone who would be interested in renting a basement with separate entrance. As a single dad working full time. Looking for someone who would be interested in renting a basement with separate entrance.',
            id_verified: true,
            email_verified: true,
            police_check_completed: true,
            gender: 'female',
            languages: ['English', 'French'],
            city: 'Kelowna',
            marital_status: 'single',
            vaccination: 'full',
            employment: 'full-time',
            pets_count: 2,
            pets_types: ['dog'],
            pets_pictures: [
                require('@/assets/images/profile-pet-placeholder.jpeg'),
                require('@/assets/images/profile-pet-2-placeholder.jpeg'),
                require('@/assets/images/profile-pet-3-placeholder.jpeg'),
                require('@/assets/images/profile-pet-4-placeholder.jpeg'),
            ],
            prefers_gender: 'female',
            prefers_employment: 'students',
            habits: [
                'drinking socially',
                'cannabis friendly',
                'not pet friendly',
                'smoke/vape: never',
                'cooks 1-2 times per week',
                'cleaning: a few times per week',
                'organize only if necessary',
            ],
            hobbies: [
                'traveling',
                'gardening',
                'music',
            ],
            socializing: {
                friends_overnight: 'never',
                friends_daytime: 'never',
            },
            dietary_requirements: [
                'Lactose-free',
                'No allergies',
                'Vegetarian',
            ],
            friends_describe: [
                'Intelligent',
                'Kind',
                'Genuine',
            ],
            noise_tolerance: [
                'Requires quiet time often'
            ],
            schedule: [
                'Away business hours',
                'At home only to sleep',
                'On free time - Out most of the time',
                'When home - Prefer to be alone',
            ]
        },
        listings_list: [
            {
                listing_id: Math.random(),
                title: "Shared kitchen, private bathroom room in Corktown.",
                details: "Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities. Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities. Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities.",
                thumbnail: require('@/assets/images/listing-thumbnail.png'),
                accommodation_type: "house",
                shared_with: "couple",
                bath_type: "private",
                pet_friendly: true,
                price: 1200,
                status: "draft"
            },
            {
                listing_id: Math.random(),
                title: "Shared kitchen, private bathroom room in Corktown.",
                details: "Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities. Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities. Comfortable, affordable stay. Queen bed, en-suite bathroom, work desk, essential amenities.",
                thumbnail: require('@/assets/images/listing-thumbnail.png'),
                accommodation_type: "house",
                shared_with: "couple",
                bath_type: "shared",
                pet_friendly: true,
                price: 1200,
                status: "listed"
            },
        ]
    }),
    getters: {
        languagesText() {
            const languages = this.formData.languages;
            if (languages == null || languages.length === 0) return '';

            const length = languages.length;
            if (length === 1) {
                return languages[0];
            }

            if (length === 2) {
                return languages.join(' and ');
            }

            let results = '';
            languages.forEach((language, index) => {
                let separator = "";

                if (index > 0 && index + 1 < length) {
                    separator = ', ';
                }
                else if (index + 1 === length) {
                    separator = ', and ';
                }

                results += `${separator}${language}`;
            });

            return results;
        },
        getEmploymentText(){
            switch (this.formData.employment) {
                case 'full-time':
                    return 'Full-time employee';
                default:
                    return '';
            }
        },
        getVaccinatedText(){
            switch (this.formData.vaccination) {
                case 'full':
                    return 'Fully vaccinated';
                default:
                    return '';
            }
        }
    }
});