<template>
<section aria-labelledby="looking-for-heading" class="card" tabindex="0">
  <div class="looking-for-section">
    <div class="edit-info">
      <h2 id="looking-for-heading" class="heading-6">Looking for</h2>

      <a href="javascript:void(0)" class="edit-link" @click="openModal">
        <img src="@/assets/svgs/edit.svg" alt="edit">
        Edit
      </a>
    </div>

    <p>{{ formData.renter_looking_for_start }}-{{ formData.renter_looking_for_end }}</p>
  </div>

  <div v-show="showModal" class="modal-overlay show" @click.self="closeModal">
    <div class="modal-container">
      <div class="modal-header">
        <p>Looking for dates</p>
        <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
      </div>

      <!-- Integrated Form in Modal -->
      <div class="form-container">
        <h2 class="heading-4">Looking for dates details</h2>

        <form id="screening-details-form" @submit.prevent="submitForm">
          <div class="form-group ios-input-fix">
            <label for="start-date">Start date</label>

            <div class="input-container">
              <flat-pickr v-model="start_date" />
              <img class="input-icon calendar-icon" src="@/assets/svgs/calendar.svg" alt="calendar icon">
            </div>

            <!-- <div id="startDateErrorText" class="validation-error">
              <span v-if="errors.start_date">{{ errors.start_date[0] }}</span>
            </div> -->
          </div>

          <div class="form-group ios-input-fix">
            <label for="end-date">End date</label>

            <div class="input-container">
              <flat-pickr v-model="end_date" />
              <img class="input-icon calendar-icon" src="@/assets/svgs/calendar.svg" alt="calendar icon">
            </div>

            <!-- <div id="endDateErrorText" class="validation-error">
              <span v-if="errors.end_date">{{ errors.end_date[0] }}</span>
            </div> -->
          </div>

          <div class="form-actions sticky-btm">
            <button type="submit" class="primary-btn">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import { useRenterProfile } from "@/stores/renter/useRenterProfile";
import { mapState } from "pinia";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  name: "ProfileLookingForDates",
  components: {
    flatPickr
  },
  data() {
    return {
      showModal: false,
      start_date: '',
      end_date: ''
    };
  },
  computed: {
    ...mapState(useRenterProfile, ['formData'])
  },
  methods: {
    openModal() {
      this.start_date = this.formData.renter_looking_for_start;
      this.end_date = this.formData.renter_looking_for_end;
      document.body.style.overflow = "hidden"; // Prevent background scroll
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = "";
      this.start_date = "";
      this.end_date = "";
    },

    submitForm() {
      //TODO: Save data
      
      this.formData.renter_looking_for_start = this.start_date;
      this.formData.renter_looking_for_end = this.end_date;
      this.closeModal()
    },
  },
};
</script>