<template>
  <div class="container flex-container verification-link mb-6">
    <div class="content">
        <h1 class="heading-2">Get verified with secure link.</h1>
        <img class="mt-4" src="@/assets/svgs/verify-email.svg" alt="Email icon" width="160" height="160">
    </div>

    <p>
        We will email you with instructions as well as a secure link so you can safely submit the photos of your ID for verification.
        <br><br>
        Please check you email for further instructions.
    </p>

    <a href="#" class="primary-btn">Back to live verification</a>
  </div>
</template>


<script>
export default {
  name: "RenterVerificationLink",
};
</script>