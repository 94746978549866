import { defineStore } from 'pinia';

export const useRenterStay = defineStore('stay', {
    state: () => ({
        formData: {
            stay_first_name: 'John',
            stay_image: require('@/assets/images/mystay-placeholder.jpeg'),
            stay_from: '2024-11-02',
            stay_to: '2025-05-02',
            stay_price: 1500.00,
            stay_address: '565 Lakeshore blv, Toronto, ON, M6E 3F7',
            stay_payments: [
                {
                    date: '2024-11-02',
                    amount: 1500.00,
                    paid: true
                },
                {
                    date: '2024-12-02',
                    amount: 1500.00,
                    paid: false
                },
                {
                    date: '2025-01-02',
                    amount: 1500.00,
                    paid: false
                },
                {
                    date: '2025-02-02',
                    amount: 1500.00,
                    paid: false
                },
            ]
        }
    }),
    getters: {
        isActive() {
            const data = this.formData;
            return data.stay_address !== '' && data.stay_from !== '' && data.stay_to !== '';
        }
    },
});