<template>
<div class="container flex-container flex-col-2 mb-6 renter-registration-container">
    <div class="form-container">
        <form id="renter-form" @submit.prevent="submitForm">
            <div v-if="currentStep === 1" class="form-step active">
                <div>
                    <h2 class="heading-2">What’s your first and last name?</h2>
                    <p class="font-size-medium">Your last name will not be visible to renters.</p>
                </div>

                <div class="form-group">
                    <input 
                        v-model="formData.first_name"
                        type="text" 
                        name="firstName" 
                        id="firstName" 
                        placeholder="Enter your first name" 
                        maxlength="30" />
                    <div id="firstNameErrorText" class="validation-error">
                        <span v-if="errors.first_name">{{ errors.first_name[0] }}</span>
                    </div>

                    <div class="form-group">
                        <input 
                            v-model="formData.last_name"
                            type="text" 
                            name="lastName" 
                            id="lastName" 
                            placeholder="Enter your last name" 
                            maxlength="30" />
                        <div id="lastNameErrorText" class="validation-error">
                            <span v-if="errors.last_name">{{ errors.last_name[0] }}</span>
                        </div>
                    </div>
                </div>

                <div class="btns-container">
                    <button type="button" class="primary-btn next-step" @click="nextStep">Next</button>
                </div>
            </div>

            <div v-if="currentStep === 2" class="form-step active">
                <div>
                    <h2 class="heading-2">What’s your phone number?</h2>
                    <p class="font-size-medium">Our customer support will contact you if you require assistance.</p>
                </div>

                <div class="form-group">
                    <input 
                        v-model="formData.phone"
                        type="tel"
                        name="phone" 
                        id="phone" 
                        maxlength="11"
                        placeholder="xxxxxxxxxxx" 
                        title="Please enter a valid Canadian phone number with at least 10 digits."
                        @input="formData.phone = formData.phone.replace(/[^0-9]/g, '')" />
                    <div id="phoneErrorText" class="validation-error">
                        <span v-if="errors.phone">{{ errors.phone[0] }}</span>
                    </div>

                    <div class="form-group default-checkbox mt-4" tabindex="0">
                        <input 
                            v-model="formData.contact_method"
                            type="checkbox" 
                            id="contact-method" 
                            name="contact-method" />
                        <label for="contact-method">
                            <span>Preferred contact method</span>
                        </label>
                    </div>
                </div>

                <div class="btns-container">
                    <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
                    <button type="button" class="primary-btn next-step" @click="nextStep">Next</button>
                </div>
            </div>

            <div v-if="currentStep === 3" class="form-step active">
                <div>
                    <h2 class="heading-2">What’s your age?</h2>
                    <p class="font-size-medium">This information is used to determine eligibility for certain properties.</p>
                </div>

                <div class="form-group">
                    <input 
                        v-model="formData.birth_year"
                        type="number" 
                        name="birth_year" 
                        placeholder="1924" 
                        :min="1924" 
                        :max="currentYear" 
                        maxlength="4" />
                    <div id="ageErrorText" class="validation-error">
                        <span v-if="errors.birth_year">{{ errors.birth_year[0] }}</span>
                    </div>
                </div>

                <div class="btns-container">
                    <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
                    <button type="button" class="primary-btn next-step" @click="nextStep">Next</button>
                </div>
            </div>

            <div v-if="currentStep === 4" class="form-step active">
                <div>
                    <h2 class="heading-2">What’s your province?</h2>
                    <p class="font-size-medium">This information is critical to determine your location and local rental laws.</p>
                </div>
                <div class="form-group">
                    <DropdownComponent
                        :options="provinces"
                        @select="selectProvince"
                        :selected="formData.province" />
                    <div id="provinceErrorText" class="validation-error">
                        <span v-if="errors.province">{{ errors.province[0] }}</span>
                    </div>
                </div>

                <div class="btns-container">
                    <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
                    <button type="button" class="primary-btn next-step" @click="nextStep">Next</button>
                </div>
            </div>

            <div v-if="currentStep === 5" class="form-step active">
                <h2 class="heading-2">Let’s set your password.</h2>

                <div class="form-group">
                    <div class="form-field">
                        <input
                            v-model="formData.password"
                            :type="showPassword ? 'text' : 'password'"
                            name="password"
                            id="password"
                            placeholder="Secure123?!?@"
                            maxlength="30"/>

                        <a href="javascript:void(0)" class="toggle-password" @click="togglePasswordVisibility">
                            <img 
                                v-if="!showPassword" 
                                src="@/assets/svgs/show-password.svg" 
                                alt="Show password" >
                            <img 
                                v-if="showPassword" 
                                src="@/assets/svgs/hide-password.svg" 
                                alt="Hide password">
                        </a>
                    </div>

                    <div id="passwordError" class="validation-error">
                        <span v-if="errors.password">{{ errors.password[0] }}</span>
                    </div>

                    <PasswordRequirements :password="formData.password" />
                </div>

                <!-- <div id="password-strength-status"></div> -->

                <div class="btns-container">
                    <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
                    <button type="button" class="primary-btn next-step" @click="nextStep">Next</button>
                </div>
            </div>

            <div v-if="currentStep === 6" class="form-step active">
                <div>
                    <h2 class="heading-2">What’s your email?</h2>
                    <p class="font-size-medium">Let’s create your account.</p>
                </div>

                <div class="form-group">
                    <input
                        v-model="formData.email"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="john@acme.com"
                        maxlength="254"/>
                    <div id="emailErrorText" class="validation-error">
                        <span v-if="errors.email">{{ errors.email[0] }}</span>
                    </div>
                </div>

                <div class="form-group" tabindex="0">
                    <div class="default-checkbox">
                        <input
                            v-model="formData.agree_terms"
                            type="checkbox"
                            id="agree-to-terms"
                            name="agree-to-terms"/>

                        <label for="agree-to-terms">
                            <span>By signing up you agree to our <a href="https://happipad.com/privacy/">Privacy policy</a> and <a href="https://happipad.com/terms/">Terms of use</a>.<span class="required">*</span></span>
                        </label>
                    </div>

                    <div id="termsErrorText" class="validation-error">
                        <span v-if="errors.agree_terms">{{ errors.agree_terms[0] }}</span>
                    </div>
                </div>

                <div class="btns-container">
                    <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
                    <button type="submit" class="primary-btn">Agree and Sign up</button>
                </div>
            </div>
        </form>
    </div>

    <div class="illustration-container background-light-blue">
        <img src="@/assets/svgs/signup-illustration.svg" alt="Happipad Logo">
    </div>
</div>
</template>

<script>
import DropdownComponent from '@/components/DropdownComponent.vue';
import PasswordRequirements from '@/components/PasswordRequirements.vue';
import { useRenterCreateProfile } from "@/stores/renter/signup/useRenterCreateProfile";
import { mapState, mapActions } from "pinia";

export default {
    name: "RenterCreateProfile",

    components: {
        DropdownComponent,
        PasswordRequirements
    },
    data() {
        return {
            showPassword: false,
        };
    },
    computed: {
        ...mapState(useRenterCreateProfile, ['currentStep', 'formData', 'errors', 'provinces']),

        currentYear() {
            return new Date().getFullYear();
        },
    },
    methods: {
        ...mapActions(useRenterCreateProfile, ['nextStep', 'prevStep', 'selectProvince', 'validateAll']),

        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        async submitForm() {
            if (!this.validateAll()) {
                // If validation fails, prevent form submission
                return;
            }

            //Commented out due to not knowing endpoint and data shape required
            // try {
            //     // Clear previous errors
            //     this.store.errors = {};

            //     console.log('Renter registration in progress...');
            //     // Use http.js to send the form data to the backend
            //     const response = await http.post('/api/register', this.formData);

            //     this.$router.push({
            //         name: 'AwaitingVerification',
            //         query: { email: this.formData.email },
            //     });

            //     if (response.data.status === 'success') {
            //         alert('Form submitted successfully!');
            //         // Redirect or perform other actions as needed
            //     }
            // } catch (error) {
            //     if (error.response && error.response.status === 422) {
            //         // Validation failed
            //         this.store.errors = error.response.data.errors;
            //         // Determine the first step with an error and show it
            //         this.handleValidationErrors();
            //     } else {
            //         console.error('Error submitting form:', error);
            //         alert('There was an error submitting the form.');
            //     }
            // }
        },
    }
};
</script>