import { useValidation } from '@/stores/shared/useValidation';
import { defineStore } from 'pinia';

export const useRenterVerificationPaymentSetup = defineStore('payment', {
    state: () => ({
        step: 1,
        taxRate: 0.12,
        line_items: [
            {
                title: 'ID check',
                description: 'Enscripted, and safe end-to-end process',
                amount: 5.00,
            },
            {
                title: 'Background screening',
                description: 'Screening personal details for fraud, and scam behaviours.',
                amount: 18.00,
            },
        ],
        payment: {
            option: '',

            card_number: '',
            expiration_date: '',
            security_code: '',
            name_on_card: '',

            account_number: '',
            transit_number: '',
            institution_number: '',
            accountholder_name: '',
        },
        billing: {
            same_as_listing: false,
            listing_address: '600 Woodbine, Toronto, ON T5T53G',

            address: '',
            city: '',
            province: '',
            postal_code: '',
            country: '',
        },
        errors: {},
        disable_button: true,
    }),
    getters: {
        getSubTotal() {
            let total = 0.00;

            this.line_items.forEach(item => {
                total += item.amount;
            });

            return total;
        },
        getTaxTotal() {
            let total = 0.00;

            this.line_items.forEach(item => {
                total += item.amount * this.taxRate;
            });

            return total;
        },
        getGrandTotal(){
            return this.getSubTotal + this.getTaxTotal;
        },
    },
    actions: {
        nextStep(){
            const nextStep = this.step + 1;
            if(nextStep > 3) return;

            this.step = nextStep;
        },
        prevStep(){
            const nextStep = this.step - 1;
            if(nextStep < 1) return;

            this.step = nextStep;
        },
        paymentOptionChanged(){
            if(this.payment.option === 'credit-card'){
                this.payment.account_number = ''
                this.payment.transit_number = ''
                this.payment.institution_number = ''
                this.payment.accountholder_name = ''
            }
            else if (this.payment.option === 'direct-debit'){
                this.payment.card_number = '';
                this.payment.expiration_date = '';
                this.payment.security_code = '';
                this.payment.name_on_card = '';
            }

            this.validatePayment();
        },
        validatePayment(){
            const validate = useValidation();

            if(this.payment.option === 'credit-card'){
                this.disable_button = !(
                    validate.hasValue(this.payment.card_number)
                    && validate.hasValue(this.payment.expiration_date)
                    && validate.hasValue(this.payment.security_code)
                    && validate.hasValue(this.payment.name_on_card)
                );
            }
            else if (this.payment.option === 'direct-debit'){
                this.disable_button = !(
                    validate.hasValue(this.payment.account_number)
                    && validate.hasValue(this.payment.transit_number)
                    && validate.hasValue(this.payment.institution_number)
                    && validate.hasValue(this.payment.accountholder_name)
                );
            }
            else {
                this.disable_button = true;
            }
        },
        validateBilling(){
            const validate = useValidation();
            this.errors = {};

            if(this.billing.same_as_listing) return true;

            if(!validate.hasValue(this.billing.address)){
                this.errors.address = ['Address is required'];
            }
            if(!validate.hasValue(this.billing.city)){
                this.errors.city = ['City is required'];
            }
            if(!validate.hasValue(this.billing.province)){
                this.errors.province = ['Province is required'];
            }
            if(!validate.hasValue(this.billing.postal_code)){
                this.errors.postal_code = ['Postal code is required'];
            }
            else if(!validate.postalCode(this.billing.postal_code)){
                this.errors.postal_code = ['Invalid postal code'];
            }
            if(!validate.hasValue(this.billing.country)){
                this.errors.country = ['Country is required'];
            }

            return Object.keys(this.errors).length === 0;
        },
    }
});