<template>
  <HostDashboardTopNav />

  <div class="user-profile renter-profile renter-profile-edit">
    <HostDashboardSidebar />

    <div class="container flex-container profile-section">
      <h1 class="heading-2">Profile</h1>

      <!-- Profile Section -->
      <section class="profile-header" aria-labelledby="profile-heading">
        <div tabindex="0" aria-labelledby="profile-name">
          <div class="profile-header__bg"
          :style="{ backgroundImage: `url(${require('@/assets/images/profile-background-placeholder.jpeg')})` }">
            <img :src="formData.picture" alt="profile picture"
              class="profile-image">
          </div>

          <div class="profile-header__info">
            <h1 class="heading-2" id="profile-name">{{ formData.first_name }}, {{ formData.age }}</h1>

            <p class="verification-status">
              <span v-if="formData.id_verified" aria-label="ID Verified">
                <img src="@/assets/svgs/verified.svg" alt="verified">
                ID verified
              </span>

              <span v-if="formData.email_verified" aria-label="Email Verified">
                <img src="@/assets/svgs/verified.svg" alt="verified">
                Email verified
              </span>
            </p>

            <p class="profile-description" tabindex="0">
              {{ formData.story }}
            </p>

            <div v-if="formData.police_check_completed" class="info-note mt-4">
              <img src="@/assets/svgs/police-badge.svg" alt="police check">
              <p>Police check completed</p>
            </div>

            <div class="additional-info mt-3" tabindex="0">
              <p>
                <img src="@/assets/svgs/profile-gender-female.svg" alt="female">
                {{ capitalize(formData.gender) }}
              </p>

              <p>
                <img src="@/assets/svgs/profile-lang.svg" alt="languages">
                Speaks {{ languagesText }}
              </p>

              <p>
                <img src="@/assets/svgs/profile-location.svg" alt="location">
                Lives in {{ formData.city }}
              </p>

              <p>
                <img src="@/assets/svgs/profile-single.svg" alt="single">
                {{ capitalize(formData.marital_status) }}
              </p>

              <p>
                <img src="@/assets/svgs/profile-fully-vaccinated.svg" alt="Fully vaccinated">
                {{ getVaccinatedText }}
              </p>

              <p>
                <img src="@/assets/svgs/profile-works-full-time.svg" alt="Full-time employee">
                {{ getEmploymentText }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <!-- Message Host Section -->
      <section aria-labelledby="message-host-heading" class="card">
        <div class="send-message">
          <h2 id="message-host-heading" class="heading-6">Message Host</h2>

          <form action="#" method="POST">
            <textarea id="message-input" placeholder="Hello John, I'm interested in your listing. Is it available?"
              rows="3"></textarea>

            <button class="primary-btn" type="submit" aria-label="Send Message">Send</button>
          </form>
        </div>
      </section>

      <!-- Pets Section -->
      <section aria-labelledby="pets-heading" class="card" tabindex="0">
        <div class="pets-section">
          <h2 id="pets-heading" class="heading-6">{{ formData.first_name }}'s Pets</h2>

          <div class="additional-info">
            <p v-for="(pet, index) in formData.pets_types" :key="index">
              <img :src="getPetIcon(pet)" :alt="capitalize(pet)">
              {{ capitalize(pet) }}
            </p>
          </div>

          <p>{{ formData.first_name }} has {{ formData.pets_count }} pet<span v-if="formData.pets_count !== 1">s</span>.</p>

          <div class="pets-list">
            <img 
              v-for="(picture, index) in formData.pets_pictures" 
              :key="index" 
              :src="picture" 
              alt="pet image" 
              class="pet-image">
          </div>
        </div>
      </section>

      <!-- Host Preferences -->
      <section aria-labelledby="preferences-heading" class="card" tabindex="0">
        <div class="preferences-section">
          <h2 id="preferences-heading" class="heading-6">Prefers to Host</h2>

          <div class="additional-info" tabindex="0">
            <p>
              <img :src="getGenderIcon(formData.prefers_gender)" :alt="capitalize(formData.prefers_gender)">
              {{ capitalize(formData.prefers_gender) }}
            </p>

            <p>
              <img :src="getEmploymentIcon(formData.prefers_employment)" :alt="capitalize(formData.prefers_employment)">
              {{ capitalize(formData.prefers_employment) }}
            </p>
          </div>
        </div>
      </section>

      <!-- Habits Section -->
      <section aria-labelledby="habits-heading" class="card" tabindex="0">
        <div class="habits-section">
          <h2 id="habits-heading" class="heading-6">{{ formData.first_name }}'s Habits</h2>

          <div class="additional-info" tabindex="0">
            <p v-for="(habit, index) in formData.habits" :key="index">
              <img :src="getHabitsIcon(habit)" :alt="capitalize(habit)">
              {{ capitalize(habit) }}
            </p>
          </div>
        </div>
      </section>

      <!-- Hobbies Section -->
      <section aria-labelledby="hobbies-heading" class="card" tabindex="0">
        <div class="hobbies-section">
          <h2 id="hobbies-heading" class="heading-6">{{ formData.first_name }}'s Hobbies</h2>

          <div class="additional-info" tabindex="0">
            <p v-for="(hobby, index) in formData.hobbies" :key="index">
              <img :src="getHobbiesIcon(hobby)" :alt="capitalize(hobby)">
              {{ capitalize(hobby) }}
            </p>
          </div>
        </div>
      </section>

      <!-- Socializing Section -->
      <section aria-labelledby="socializing-heading" class="card" tabindex="0">
        <div class="socializing-section">
          <h2 id="socializing-heading" class="heading-6">{{ formData.first_name }}'s socializing</h2>

          <div class="additional-info" tabindex="0">
            <p>
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark">
              Bring friends overnight: {{ formData.socializing.friends_overnight }}
            </p>

            <p>
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark">

              Bring friends during daytime: {{ formData.socializing.friends_daytime }}
            </p>
          </div>
        </div>
      </section>

      <!-- dietary requirements Section -->
      <section aria-labelledby="dietary-requirements-heading" class="card" tabindex="0">
        <div class="dietary-requirements-section">
          <h2 id="dietary-requirements-heading" class="heading-6">{{ formData.first_name }}'s dietary requirements</h2>

          <div class="additional-info" tabindex="0">
            <p v-for="(requirement, index) in formData.dietary_requirements" :key="index">
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark">
              {{ requirement }}
            </p>
          </div>
        </div>
      </section>

      <!-- description Section -->
      <section aria-labelledby="dietary-requirements-heading" class="card" tabindex="0">
        <div class="dietary-requirements-section">
          <h2 id="dietary-requirements-heading" class="heading-6">Friends describe {{ formData.first_name }} as</h2>

          <div class="additional-info" tabindex="0">
            <p v-for="(description, index) in formData.friends_describe" :key="index">
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark">
              {{ description }}
            </p>
          </div>
        </div>
      </section>

      <!-- Noise Tolerance Section -->
      <section aria-labelledby="noise-tolerance-heading" class="card" tabindex="0">
        <div class="noise-tolerance-section">
          <h2 id="noise-tolerance-heading" class="heading-6">{{ formData.first_name }}'s Noise Tolerance</h2>

          <div class="additional-info" tabindex="0">
            <p v-for="(tolerance, index) in formData.noise_tolerance" :key="index">
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark">
              {{ tolerance }}
            </p>
          </div>
        </div>
      </section>

      <!-- Schedule Section -->
      <section aria-labelledby="schedule-heading" class="card" tabindex="0">
        <div class="schedule-section">
          <h2 id="schedule-heading" class="heading-6">{{ formData.first_name }}'s Schedule</h2>

          <div class="additional-info" tabindex="0">
            <p v-for="(schedule, index) in formData.schedule" :key="index">
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark">
              {{ schedule }}
            </p>
          </div>
        </div>
      </section>

      <!-- Listings Section -->
      <section v-if="listings_list.length > 0" class="listings-section" aria-labelledby="listings-heading" tabindex="0">
        <h2 id="listings-heading" class="heading-6">Available Listings</h2>

        <div class="listings">
          <div class="cards-container">
            <a href="" v-for="(listing, index) in listings_list" :key="listing.listing_id">
              <div class="listing-card" role="article" :aria-labelledby="`room-title-${index+1}`">

                <div class="listing-content">
                  <!-- Listing image -->
                  <img :src="listing.thumbnail" alt="">

                  <!-- Listing details -->
                  <div class="listing-details">
                    <p class="listing-title"><strong>{{ listing.title }}</strong></p>

                    <div class="listing-features">
                      <ul>
                        <li>
                          <img :src="getAccommodationIcon(listing.accommodation_type)" :alt="capitalize(listing.accommodation_type)">
                          {{ capitalize(listing.accommodation_type) }}
                        </li>

                        <li>
                          <img :src="getSharedWithIcon(listing.shared_with)" :alt="capitalize(listing.shared_with)">
                          {{ capitalize(listing.shared_with) }}
                        </li>
                      </ul>
                      <ul class="hidden-mobile">
                        <li>
                          <img src="@/assets/svgs/bath-tub.svg" alt="bathtub">
                          {{ capitalize(listing.bath_type) }} bath
                        </li>
                        <li v-if="listing.pet_friendly">
                          <img src="@/assets/svgs/pet.svg" alt="pet">
                          Animal friendly
                        </li>
                      </ul>
                    </div>

                    <p class="hidden-mobile">{{ listing.details }}</p>

                    <!-- Listing status & price -->
                    <div class="listing-status-price">
                      <p class="listing-price">
                        <strong>${{ listing.price }}</strong>
                        <span>/m</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>

            
          </div>
        </div>
      </section>
    </div>
  </div>

  <HostDashboardBottomNav />
</template>

<script>
import HostDashboardTopNav from "@/components/HostDashboardTopNav.vue";
import HostDashboardSidebar from "@/components/HostDashboardSidebar.vue";
import HostDashboardBottomNav from "@/components/HostDashboardBottomNav.vue";

import { useHostProfile } from "@/stores/host/dashboard/useHostProfile";
import { mapState } from "pinia";

export default {
  name: "HostDashboardProfile",

  components: {
    HostDashboardTopNav,
    HostDashboardSidebar,
    HostDashboardBottomNav
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useHostProfile, ['formData', 'languagesText', 'getEmploymentText', 'getVaccinatedText', 'listings_list'])
  },
  methods: {
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    getNormalizedText(text) {
      return text.replace(/[-| |/|\\]/g, '_').replace(/:/g, '');
    },
    getPetIcon(pet) {
      const images = {
        dog: require('@/assets/svgs/profile-dog.svg'),
      };

      return images[pet];
    },
    getGenderIcon(gender) {
      const images = {
        male: require('@/assets/svgs/profile-gender-male.svg'),
        female: require('@/assets/svgs/profile-gender-female.svg'),
      };

      return images[gender];
    },
    getEmploymentIcon(employment) {
      const images = {
        students: require('@/assets/svgs/profile-students.svg'),
        'full-time': require('@/assets/svgs/profile-works-full-time.svg'),
      };

      return images[employment];
    },
    getHabitsIcon(habit) {
      const habitNormalized = this.getNormalizedText(habit);

      const images = {
        drinking_socially: require('@/assets/svgs/profile-drinking.svg'),
        cannabis_friendly: require('@/assets/svgs/profile-cannabis.svg'),
        not_pet_friendly: require('@/assets/svgs/profile-not-pet-friendly.svg'),
        smoke_vape_never: require('@/assets/svgs/profile-non-smoker.svg'),
        cooks_1_2_times_per_week: require('@/assets/svgs/profile-cooks.svg'),
        cleaning_a_few_times_per_week: require('@/assets/svgs/profile-cleans.svg'),
        organize_only_if_necessary: require('@/assets/svgs/profile-organization.svg'),
      };

      return images[habitNormalized];
    },
    getHobbiesIcon(hobby) {
      const hobbyNormalized = this.getNormalizedText(hobby);

      const images = {
        traveling: require('@/assets/svgs/profile-traveling.svg'),
        gardening: require('@/assets/svgs/profile-gardening.svg'),
        music: require('@/assets/svgs/profile-music.svg'),
      };

      return images[hobbyNormalized];
    },
    getAccommodationIcon(type) {
      const images = {
        house: require('@/assets/svgs/house.svg'),
      };

      return images[type];
    },
    getSharedWithIcon(type) {
      const images = {
        couple: require('@/assets/svgs/couple.svg'),
      };

      return images[type];
    },
  }
};
</script>