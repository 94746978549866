<template>
<RenterDashboardTopNav />

<div class="renter-dashboard mystay-dashboard">
  <div class="main-dashboard container flex-container">
    <RenterDashboardSidebar />

    <div class="dashboard-content">
      <header class="mb-2">
        <h1 class="heading-2">My stay</h1>
      </header>

      <div class="cards-container flex-container">
        <a v-if="isActive" href="javascript:void(0)" class="card mystay-details">
          <div class="property-details">
            <div class="property-header">
              <img :src="formData.stay_image" alt="Property Image" class="property-image" />

              <div class="property-info">
                <div class="info-item">
                  <img src="@/assets/svgs/clock-outline.svg" alt="clock" class="icon" />

                  <div class="info-item__content">
                    <p>Dates</p>
                    <p><strong>{{ isoToFormattedShortDate(formData.stay_from) }} - {{ isoToFormattedShortDate(formData.stay_to) }}</strong></p>
                  </div>
                </div>

                <div class="info-item">
                  <img src="@/assets/svgs/money-outline.svg" alt="money" class="icon" />

                  <div class="info-item__content">
                    <p>Price</p>
                    <p><strong>${{ financial(formData.stay_price) }}/mo</strong></p>
                  </div>
                </div>

                <div class="info-item">
                  <img src="@/assets/svgs/address-outline.svg" alt="address" class="icon" />

                  <div class="info-item__content">
                    <p>Address</p>
                    <p><strong>{{ formData.stay_address }}</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="action-buttons">
            <button class="action-button" @click="manageInsurance">
              <div>
                <img src="@/assets/svgs/manage-outline.svg" alt="manage" class="icon" />
                Manage insurance
              </div>

              <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" class="icon" />
            </button>

            <button class="action-button" @click="viewContract">
              <div>
                <img src="@/assets/svgs/contract-outline.svg" alt="contract" class="icon" />
                View contract
              </div>

              <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" class="icon" />
            </button>

            <button class="action-button" @click="requestLatestReceipt">
              <div>
                <img src="@/assets/svgs/receipt-outline.svg" alt="receipt" class="icon" />
                Request receipt
              </div>

              <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" class="icon" />
            </button>
          </div>
        </a>

        <div v-if="isActive" class="card rental-payments">
          <div class="payments-header">
            <p><strong>Rental payments</strong></p>
          </div>

          <a href="javascript:void(0)" v-for="payment in formData.stay_payments" :key="payment.date" class="payment-item" @click="requestReceipt">
            <div class="info-item">
              <img src="@/assets/svgs/card-outline.svg" alt="card" class="icon" />

              <div class="info-item__content">
                <p>Rent for {{ isoToFormattedMonthYear(payment.date) }}</p>
                <p><strong>$ {{ financial(payment.amount) }}</strong></p>
              </div>
            </div>

            <span v-if="payment.paid" class="status-badge status-badge--green">Paid</span>
            <img v-else src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" class="icon" />
          </a>
        </div>

        <div v-if="!isActive" class="listing-card listing-card--empty text-align-center">
          <p>Hey {{ formData.stay_first_name }}! No stays booked</p>

          <img src="@/assets/svgs/no-listings.svg" alt="">

          <a href="javascript:void(0)" class="primary-btn">
            <img src="@/assets/svgs/house-outline.svg" alt="house">
            Find a place
          </a>
        </div>
      </div>
    </div>

    <RenterDashboardBottomNav />
  </div>
</div>
</template>


<script>
import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";
import { useRenterStay } from "@/stores/renter/useRenterStay";
import { mapState } from "pinia";

export default {
  name: "RenterMyStay",

  components: {
    RenterDashboardSidebar,
    RenterDashboardBottomNav,
    RenterDashboardTopNav
  },

  computed: {
    ...mapState(useRenterStay, ['formData', 'isActive'])
  },
  methods: {
    manageInsurance() {
      this.$router.push('/renter/insurance');
    },
    viewContract() {
      //TODO: View/download contract PDF
      alert("This should view/download contract PDF");
    },
    requestLatestReceipt() {
      //TODO: View/download latest receipt PDF
      alert("This should view/download latest receipt PDF");
    },
    requestReceipt() {
      //TODO: View/download receipt PDF
      alert("This should view/download receipt PDF");
    },
    isoToFormattedShortDate(isoDate) {
      const splitDate = isoDate.split('-');
      return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
    },
    isoToFormattedMonthYear(isoDate) {
      const splitDate = isoDate.split('-');
      const month = this.monthNumberToText(splitDate[1]);
      return `${month}, ${splitDate[0]}`;
    },
    monthNumberToText(value) {
      const monthNumber = parseInt(value);

      switch (monthNumber) {
        case 1:
          return 'Jan';
        case 2:
          return 'Feb';
        case 3:
          return 'Mar';
        case 4:
          return 'Apr';
        case 5:
          return 'May';
        case 6:
          return 'Jun';
        case 7:
          return 'Jul';
        case 8:
          return 'Aug';
        case 9:
          return 'Sep';
        case 10:
          return 'Oct';
        case 11:
          return 'Nov';
        case 12:
          return 'Dec';
        default:
          return '';
      }
    },
    financial(value) {
      return Number.parseFloat(value).toFixed(2);
    }
  },
};
</script>