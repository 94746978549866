<template>
  <div class="renter-verification-container renter-verification-payment-step-4-summary">
    <div class="breadcrumbs">
      <a href="javascript:void(0)" @click="prevStep">Back</a>
      <img src="@/assets/svgs/chevron-dark-right.svg" alt="chevron">
      <p>Payment set up</p>
    </div>

    <div class="container flex-container">
      <div class="btns-container mt-6 sticky-btm verification-payment-btns-container hidden-desktop">
        <p>
          <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 7.5H13.5V5.5C13.5 2.74 11.26 0.5 8.5 0.5C5.74 0.5 3.5 2.74 3.5 5.5V7.5H2.5C1.4 7.5 0.5 8.4 0.5 9.5V19.5C0.5 20.6 1.4 21.5 2.5 21.5H14.5C15.6 21.5 16.5 20.6 16.5 19.5V9.5C16.5 8.4 15.6 7.5 14.5 7.5ZM8.5 16.5C7.4 16.5 6.5 15.6 6.5 14.5C6.5 13.4 7.4 12.5 8.5 12.5C9.6 12.5 10.5 13.4 10.5 14.5C10.5 15.6 9.6 16.5 8.5 16.5ZM11.6 7.5H5.4V5.5C5.4 3.79 6.79 2.4 8.5 2.4C10.21 2.4 11.6 3.79 11.6 5.5V7.5Z" fill="#4969AF" />
          </svg>
          Secure payment
        </p>

        <a href="javascript:void(0)">
          <button type="button" class="primary-btn next-step" :class="{'btn-disabled': disable_button}" :disabled="disable_button" @click="continueToPayment">Pay now, CA$ {{ financial(getGrandTotal) }} a</button>
        </a>
      </div>

      <div class="verification-container mb-4">
        <div class="payment-card">
          <h1 class="heading-2">Payment set up.</h1>
          <p>All transactions are secure and encrypted</p>
        </div>

        <div class="verification-content mb-4">
          <div class="payment-container">
            <div class="payment-methods">
              <div class="payment-methods-intro mb-2">
                <p>Payment options</p>

                <img src="@/assets/images/paysafe-logo.png" alt="Paysafe Logo">
              </div>

              <form id="screening-payment-form" action="" method="POST">
                <div class="form-group">
                  <div class="default-radio-btns">
                    <div class="main-payment-selection">
                      <input
                        v-model="payment.option"
                        value="credit-card"
                        type="radio"
                        id="credit-card-option"
                        name="payment-method"
                        @change="paymentOptionChanged" />

                      <label for="credit-card-option">Credit card</label>
                    </div>

                    <div class="payment-info credit-card-info" :class="{'active': payment.option === 'credit-card'}">
                      <div class="credit-cards-imgs">
                        <img src="@/assets/images/visa-logo-sm.png" alt="Visa">
                        <img src="@/assets/images/mastercard-logo-sm.png" alt="Mastercard">
                        <img src="@/assets/images/amex-logo-sm.png" alt="Amex">
                        <img src="@/assets/images/discover-logo-sm.png" alt="Discover">
                        <p>and more...</p>
                      </div>

                      <div class="payment-selection">
                        <div class="input-container mt-4">
                          <input
                            v-model="payment.card_number"
                            type="text"
                            id="credidtCardNumber"
                            name="creditCardNumber"
                            placeholder="Card number"
                            @input="maskCardNumberField">

                          <img class="input-icon input-icon--lock" src="@/assets/svgs/lock.svg" alt="Lock">
                        </div>

                        <div class="input-container">
                          <input 
                            v-model="payment.expiration_date"
                            type="text"
                            id="expiry-date"
                            name="expiryDate"
                            placeholder="Expiration Date (MM/YY)"
                            @input="maskExpirationField">
                        </div>

                        <div class="input-container">
                          <input
                            v-model="payment.security_code"
                            type="text"
                            id="cvv"
                            name="cvv"
                            placeholder="Security code"
                            @input="maskNumberOnlyField('security_code')">

                          <img class="input-icon question-tooltip" src="@/assets/svgs/question.svg" alt="question" @click="toggleCvvTooltip">

                          <!-- Tooltip content -->
                          <div class="tooltip" id="cvv-tooltip" :class="{'show': showCvvTooltip}">
                            A security code (CSC; also known as CVC, CVV) is a series of numbers that, in addition to
                            the bank card number.

                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.9995 0L5.99951 6L-0.000488281 9.53674e-07L11.9995 0Z" fill="#3C3F4C" />
                            </svg>
                          </div>
                        </div>

                        <div class="input-container">
                          <input
                            v-model="payment.name_on_card"
                            type="text"
                            placeholder="Name on card"
                            id="name" 
                            name="cardHolderName"
                            @input="validatePayment">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="default-radio-btns">
                    <div class="main-payment-selection">
                      <input
                        v-model="payment.option"
                        value="direct-debit"
                        type="radio"
                        id="direct-debit-option"
                        name="payment-method"
                        @change="paymentOptionChanged" />
                      <label for="direct-debit-option">Direct debit / EFT</label>
                    </div>

                    <div class="payment-info direct-debit-info" :class="{'active': payment.option === 'direct-debit'}">
                      <div class="payment-selection">
                        <div class="input-container mt-4">
                          <input
                            v-model="payment.account_number"
                            type="text"
                            id="accountNumber"
                            name="accountNumber"
                            placeholder="Account number"
                            @input="maskNumberOnlyField('account_number')">

                          <img class="input-icon input-icon--lock" src="@/assets/svgs/lock.svg" alt="Lock">
                        </div>

                        <div class="input-container">
                          <input
                            v-model="payment.transit_number"
                            type="text"
                            id="transitNumber"
                            name="transitNumber"
                            placeholder="Transit number"
                            @input="maskNumberOnlyField('transit_number')">
                        </div>

                        <div class="input-container">
                          <input
                            v-model="payment.institution_number"
                            type="text"
                            id="instit-number"
                            name="instit-number"
                            placeholder="Institution number"
                            @input="maskNumberOnlyField('institution_number')">
                        </div>

                        <div class="input-container">
                          <input
                            v-model="payment.accountholder_name"
                            type="text"
                            id="accountName"
                            name="accountName"
                            placeholder="Accountholder name "
                            @input="validatePayment">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="payment-card billing-details">
          <div class="billing-details-intro">
            <p>Billing details</p>
          </div>

          <div class="form-container">
            <form method="POST">

              <div class="form-group" tabindex="0">
                <div class="default-checkbox">
                  <input
                    v-model="billing.same_as_listing"
                    type="checkbox"
                    id="keep-me-signed"
                    name="keep-me-signed">

                  <label for="keep-me-signed">
                    <span>Billing address is same as listing address: <strong>{{ billing.listing_address }}</strong>
                    </span>
                  </label>
                </div>
              </div>

              <div v-if="!billing.same_as_listing" class="form-group">
                <label for="current-address">Current address <span class="required">*</span></label>
                <input
                  v-model="billing.address"
                  type="text"
                  name="current-address"
                  placeholder="Start writing your address.."
                  id="current-address"
                  required>

                <div v-if="errors.address" id="currentAddressErrorText" class="validation-error">{{ errors.address[0] }}</div>
              </div>

              <div v-if="!billing.same_as_listing" class="form-group">
                <label for="city">City <span class="required">*</span></label>
                <input
                  v-model="billing.city"
                  type="text"
                  name="city"
                  placeholder="Start writing your city.."
                  id="city"
                  required>
                <div v-if="errors.city" id="cityErrorText" class="validation-error">{{ errors.city[0] }}</div>
              </div>

              <div v-if="!billing.same_as_listing" class="form-group">
                <label for="province">Province <span class="required">*</span></label>
                <input
                  v-model="billing.province"
                  type="text"
                  name="province"
                  placeholder="Start writing your province.."
                  id="province"
                  required>
                <div v-if="errors.province" id="provinceErrorText" class="validation-error">{{ errors.province[0] }}</div>
              </div>

              <div v-if="!billing.same_as_listing" class="form-group">
                <label for="postal-code">Postal code <span class="required">*</span></label>
                <input 
                  v-model="billing.postal_code"
                  type="text" 
                  name="postal-code" 
                  placeholder="Start writing your postal code.." 
                  id="postal-code"
                  required>
                <div v-if="errors.postal_code" id="postalCodeErrorText" class="validation-error">{{ errors.postal_code[0] }}</div>
              </div>

              <div v-if="!billing.same_as_listing" class="form-group">
                <label for="country">Country <span class="required">*</span></label>
                <input
                  v-model="billing.country"
                  type="text"
                  name="country"
                  placeholder="Start writing your country.."
                  id="country"
                  required>
                <div v-if="errors.country" id="countryErrorText" class="validation-error">{{ errors.country[0] }}</div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="payment-summary">
        <div class="payment-summary-container">
          <h3 class="summary-title heading-6" @click="toggleSummary">
            Summary

            <svg class="hidden-desktop" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C-0.0976311 0.683417 -0.0976312 1.31658 0.292893 1.70711L6.29289 7.70711C6.68342 8.09763 7.31658 8.09763 7.70711 7.70711L13.7071 1.70711C14.0976 1.31658 14.0976 0.683418 13.7071 0.292894C13.3166 -0.0976305 12.6834 -0.0976305 12.2929 0.292894L7 5.58579L1.70711 0.292893C1.31658 -0.097631 0.683418 -0.0976311 0.292893 0.292893Z" fill="white" />
            </svg>
          </h3>

          <div v-show="showSummary" class="summary-content">
            <div class="summary-item mt-3">
              <span class="summary-label">Subtotal</span>
              <span class="summary-value">${{ financial(getSubTotal) }}</span>
            </div>

            <div class="summary-item">
              <span class="summary-label">Taxes
              </span>

              <span class="summary-value">${{ financial(getTaxTotal) }}</span>
            </div>

            <div class="summary-total mb-2">
              <span class="total-label">Total</span>
              <span class="total-value"><strong>${{ financial(getGrandTotal) }}</strong></span>
            </div>

            <div class="credit-cards-imgs mt-2">
              <img src="@/assets/images/masterCard.png" alt="Mastercard">
              <img src="@/assets/images/maestro.png" alt="maestro">
              <img src="@/assets/images/visa.png" alt="Interac">
              <img src="@/assets/images/americanExpress.png" alt="American Express">
              <img src="@/assets/images/discover.png" alt="discover">
              <img src="@/assets/images/direct-dept.png" alt="direct-dept">
            </div>
          </div>
        </div>

        <div class="payment-btn-container hidden-mobile">
          <a class="payment-btn mt-3" href="javascript:void(0)">
            <button type="button" class="primary-btn" :class="{'btn-disabled': disable_button}" :disabled="disable_button" @click="continueToPayment">Continue to payment</button>
          </a>

          <p class="text-align-center">Happipad <a href="https://happipad.com/privacy/" target="_blank">Privacy&nbsp;Policy</a> and <a href="https://happipad.com/termsofuse/" target="_blank">Terms of use</a> apply.</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>


import { useRenterVerificationPaymentSetup } from "@/stores/renter/verification/useRenterVerificationPayment";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterVerificationPaymentStep2",
  data() {
    return {
      showCvvTooltip: false,
      showSummary: true,
    };
  },
  computed: {
    ...mapState(useRenterVerificationPaymentSetup, [
      'getSubTotal',
      'getTaxTotal',
      'getGrandTotal',
      'payment',
      'billing',
      'disable_button',
      'errors',
    ])
  },
  methods: {
    ...mapActions(useRenterVerificationPaymentSetup, ['nextStep', 'prevStep', 'paymentOptionChanged', 'validatePayment', 'validateBilling']),

    financial(value) {
      return Number.parseFloat(value).toFixed(2);
    },
    toggleCvvTooltip(){
      this.showCvvTooltip = !this.showCvvTooltip;
    },
    toggleSummary(){
      this.showSummary = !this.showSummary;
    },

    maskCardNumberField() {
      let value = this.payment.card_number.replace(/\D/g, ''); // Remove non-digit characters
      if (value.length > 19) value = value.slice(0, 19); // Limit to 19 digits
      this.payment.card_number = value;
      this.validatePayment();
    },
    maskExpirationField() {
      let value = this.payment.expiration_date.replace(/\D/g, ''); // Remove non-digit characters
      if (value.length > 4) value = value.slice(0, 4); // Limit to 4 digits
      this.payment.expiration_date = this.formatDateField(value);
      this.validatePayment();
    },
    formatDateField(value) {
        const month = value.slice(0, 2);
        const year = value.slice(2, 4);

        if (value.length > 2) {
          return `${month}/${year}`;
        } 

        return value;
    },

    maskNumberOnlyField(key) {
      this.payment[key] = this.payment[key].replace(/\D/g, ''); // Remove non-digit characters
      this.validatePayment();
    },


    continueToPayment(){
      const isValid = this.validateBilling();
      if(!isValid) return;

      this.nextStep();
      //TODO
    },
  }
};
</script>
