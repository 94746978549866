<template>
<HostDashboardTopNav />

<div class="host-dashboard">
  <div class="invoices container flex-container">
    <HostDashboardSidebar />

    <div class="dashboard-content">
      <header class="mb-2">
        <h1 class="heading-2">Invoices</h1>

        <p class="hidden-desktop" id="table-title">Showing {{ getinvoices.length }} of {{ invoice_list.length }}</p>
      </header>

      <a href="#" class="view-more mb-4 hidden-desktop">
        <img :src="getAsset('wallet.svg')" alt="wallet">
        Change payout
      </a>

      <div class="cards-container card">
        <!-- Table Section -->
        <section class="mb-4" aria-labelledby="table-title">
          <div class="mb-4 hidden-mobile">
            <h2 class="heading-3">Your invoices
              <span v-if="new_count > 0" class="notification-badge" aria-hidden="true"><strong>{{ new_count }} new</strong></span>
            </h2>

            <p class="hidden-mobile" id="table-title">Showing {{ getinvoices.length }} of {{ invoice_list.length }}</p>
          </div>

          <div class="dashboard-table-container">
            <table>
              <thead>
                <tr>
                  <th scope="col">
                    <div class="default-checkbox" tabindex="0">
                      <input v-model="select_all" type="checkbox" id="select-all" name="select-all" @change="toggleSelectAll"/>

                      <label for="select-all"></label>
                    </div>
                  </th>
                  <th class="hidden-desktop" scope="col">Invoice</th>
                  <th scope="col">Renter</th>
                  <th scope="col">Room</th>
                  <th scope="col">Payment</th>
                  <th scope="col">Date</th>
                  <th scope="col">Purpose</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in getinvoices" :key="item.invoice_id">
                  <td class="hidden-desktop">
                    <div :data-label="`Invoice ${item.invoice_id}`"></div>
                    <a href="javascript:void(0)" @click="detailsClicked(item.invoice_id)">
                      <img :src="getAsset('eye-white.svg')" alt="view">
                      View
                    </a>
                  </td>

                  <td class="hidden-mobile">
                    <div class="default-checkbox" tabindex="0">
                      <input v-model="item.selected" type="checkbox" :id="`select-${item.invoice_id}`" :name="`select-${item.invoice_id}`" @change="toggleIndividualSelect"/>
                      <label :for="`select-${item.invoice_id}`"></label>
                    </div>
                  </td>

                  <td data-label="Renter">
                    <div>
                      <img :src="item.renter_picture" alt="Renter profile" />
                      {{ item.renter_name }}
                    </div>
                  </td>

                  <td data-label="Room">
                    <div>
                      <img :src="item.room_picture" alt="Room A thumbnail" />
                      {{ item.room_name }}
                    </div>
                  </td>

                  <td data-label="Payment">${{ item.payment }}</td>

                  <td data-label="Date">
                    <div>
                      {{ getFormattedDate(item.date) }}
                    </div>
                  </td>

                  <td data-label="Purpose">
                    <div>
                      {{ capitalize(item.purpose) }}
                    </div>
                  </td>

                  <td data-label="Status">
                    <span class="status-badge" :class="getStatusClass(item.status)" :aria-label="`Listing status: ${capitalize(item.status)}`" role="status">{{ capitalize(item.status) }}</span>
                  </td>

                  <td class="hidden-mobile">
                    <div>
                      <button class="tertiary-btn" aria-label="details" @click="detailsClicked(item.invoice_id)">
                        <img :src="getAsset('eye.svg')" alt="" />
                      </button>

                      <a href="#" class="tertiary-btn" @click="downloadClicked(item.invoice_id)">Download</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <a v-if="!show_full_list && getMoreCount > 0" href="javascript:void(0)" class="view-more mt-4" @click="toggleShowFullList()">Show {{ getMoreCount }} more
            <img src="@/assets/svgs/chevron-down-blue.svg" alt="chevron down">
          </a>
          <a v-if="show_full_list" href="javascript:void(0)" class="view-more mt-4" @click="toggleShowFullList()">Show less
            <img src="@/assets/svgs/chevron-up-blue.svg" alt="chevron up">
          </a>
        </section>

        <div class="listing-card listing-card--empty hidden text-align-center">
          <p>Hey {{ host_name }}! No transactions to display.<br>
            If you have any transactions in the future they will be displayed here.</p>

          <img :src="getAsset('no-transactions.svg')" alt="">

          <a href="javascript:void(0)" class="tertiary-btn" @click="readQA()">
            <img :src="getAsset('document.svg')" alt="document">
            Read Q & A
          </a>
        </div>
      </div>
    </div>

    <HostDashboardBottomNav />
  </div>
</div>
</template>

<script>
import HostDashboardTopNav from "@/components/HostDashboardTopNav.vue";
import HostDashboardSidebar from "@/components/HostDashboardSidebar.vue";
import HostDashboardBottomNav from "@/components/HostDashboardBottomNav.vue";
import { useHostInvoices } from "@/stores/host/dashboard/useHostInvoices";
import { mapState, mapActions } from "pinia";

export default {
  name: "HostInvoices",

  components: {
    HostDashboardTopNav,
    HostDashboardSidebar,
    HostDashboardBottomNav
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState(useHostInvoices, ['show_full_list', 'select_all', 'host_name', 'new_count', 'invoice_list', 'getinvoices', 'getMoreCount'])
  },
  methods: {
    ...mapActions(useHostInvoices, ['toggleShowFullList', 'toggleSelectAll', 'toggleIndividualSelect']),

    getAsset(filename) {
      return require(`@/assets/svgs/${filename}`);
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    getStatusClass(status) {
      const classNames = {
        paid: 'status-badge--green',
        unpaid: 'status-badge--yellow',
        cancelled: 'status-badge--red',
      };

      return classNames[status];
    },
    getFormattedDate(isoDate) {
      const splitDate = isoDate.split('-');
      const year = splitDate[0];
      const month = this.monthNumberToText(splitDate[1]);
      const day = parseInt(splitDate[2]);
      return `${month} ${day}, ${year}`;
    },
    monthNumberToText(value) {
      const monthNumber = parseInt(value);

      switch (monthNumber) {
        case 1:
          return 'Jan';
        case 2:
          return 'Feb';
        case 3:
          return 'Mar';
        case 4:
          return 'Apr';
        case 5:
          return 'May';
        case 6:
          return 'Jun';
        case 7:
          return 'Jul';
        case 8:
          return 'Aug';
        case 9:
          return 'Sep';
        case 10:
          return 'Oct';
        case 11:
          return 'Nov';
        case 12:
          return 'Dec';
        default:
          return '';
      }
    },

    detailsClicked() {
      //TODO
      alert("This should view invoice PDF");
    },
    downloadClicked() {
      //TODO
      alert("This should download invoice PDF");
    },
    readQA() {
      //TODO
      alert("This should direct to Q & A page");
    },
  }
};
</script>