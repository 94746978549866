<template>
  <RenterDashboardTopNav />

  <div class="renter-dashboard insurance-dashboard">
    <div class="main-dashboard container flex-container">
      <RenterDashboardSidebar />

      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-2">Manage insurance</h1>
        </header>

        <div class="cards-container flex-container">
          <div class="card">
            <form action="">
              <div class="form-group">
                <div class="default-radio-btns">
                  <input
                    v-model="insurance_type"
                    value="happicover"
                    type="radio"
                    id="happicover"
                    name="insurance-option"
                    @change="setInsuranceType('happicover')" />

                  <label for="happicover">Happi cover for $ 20.00 </label>
                  <p>Covering break-ins, fires, and more.
                    <br>
                    <a href="#">Cancel any time.</a>
                  </p>
                </div>

                <div class="default-radio-btns mt-3">
                  <input
                    v-model="insurance_type"
                    value="private"
                    type="radio"
                    id="private"
                    name="insurance-option"
                    @change="setInsuranceType('private')" />

                  <label for="private">Private insurance</label>
                  <p>Any private insurance of your choice.</p>
                </div>
              </div>
            </form>

            <div class="info-note mt-2 mb-3">
              <img src="@/assets/svgs/info.svg" alt="Info">

              <p>Rental insurance is required. You can use any private one. If you chose Happicover, it will be quoted
                when you pay rent.</p>
            </div>
          </div>

          <div v-if="insurance_type === 'private'" class="card">
            <h2 class="heading-6">Insurance</h2>
            <p>Download your private insurance here.</p>

            <form action="">
              <div class="form-group">
                <div class="file-upload" id="file-upload" tabindex="0">
                  <input
                    type="file"
                    id="file"
                    name="file"
                    class="file-input"
                    multiple
                    @change="setDocument">

                  <label for="file" class="file-label">
                    <div class="file-upload-container">
                      <img src="@/assets/svgs/upload-blue.svg" alt="Upload Icon" class="upload-icon">
                      <div class="file-upload-content">
                        <p class="upload-text">png, pdf, jpg, docx accepted</p>
                        <a href="">Browse insurance</a>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </form>

            <div class="info-note info-note-red mt-2 mb-3">
              <img src="@/assets/svgs/info-note-red.svg" alt="Info">

              <p>Upload private insurance before you can save changes.</p>
            </div>
          </div>

          <!-- Modal Popup -->
          <div v-show="showModal" class="modal-overlay show">
            <div class="modal-container">
              <div class="modal-header">
                <button class="close-btn" @click="closeModal">
                  <img src="@/assets/svgs/close-big.svg" alt="close" />
                </button>
              </div>

              <!-- Modal content -->
              <div class="modal-content-container">
                <div class="card">
                  <h2 class="heading-6">Your changes were not saved.</h2>
                  <p>If you want to use private insurance, you are required to provide a confirmation agreement.</p>
                </div>

                <div class="modal-content-container__btns">
                  <button class="secondary-btn" @click="stayWithHappicover">OK, I stay with Happicover</button>
                  <button class="primary-btn mt-2" @click="closeModal">Go back, and upload confirmation</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RenterDashboardBottomNav />
    </div>
  </div>
</template>

<script>
import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

import { useRenterInsurance } from "@/stores/renter/useRenterInsurance";
import { mapActions, mapState } from "pinia";

export default {
  name: "RenterInsurance",

  components: {
    RenterDashboardSidebar,
    RenterDashboardBottomNav,
    RenterDashboardTopNav
  },
  data() {
    return {
      showModal: false,
      navigateTo: null
    };
  },
  computed: {
    ...mapState(useRenterInsurance, ['insurance_type', 'document'])
  },
  methods: {
    ...mapActions(useRenterInsurance, ['setInsuranceType', 'setDocument']),

    closeModal(){
      this.showModal = false;
    },
    stayWithHappicover(){
      this.setInsuranceType('happicover');

      if(this.navigateTo != null){
        this.$router.push({ name: this.navigateTo.name });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if(this.insurance_type === 'private' && this.document == null){
      next(false);
      this.navigateTo = to;
      this.showModal = true;
    }
    else {
      next();
    }
  },

};
</script>