<template>
  <div class="form-step active">
    <div>
      <h1 class="heading-2">Let’s make your profile special.</h1>
    </div>

    <div class="form-group">
      <h3 class="heading-6">Upload your photo.</h3>

      <p class="font-size-medium">Formats excepted: png, jpeg, jpg</p>

      <div class="file-upload dropzone" id="profile-file-upload" tabindex="0">
        <input type="file" id="file" name="file[]" class="file-input" multiple>

        <label for="file" class="file-label">
          <div class="file-upload-container">
            <img src="@/assets/svgs/profile-upload.svg" alt="Upload Icon" class="upload-icon">
            <div class="file-upload-content">
              <p class="upload-text">Drop your files here or click <strong>browse</strong></p>
              <p class="upload-size">Maximum size: 100MB</p>
            </div>
          </div>
        </label>
      </div>

      <!-- Hidden preview template -->
      <div id="preview-template" style="display: none;">
        <div class="dz-preview dz-file-preview">
          <div class="dz-image">
            <img data-dz-thumbnail />
            <!-- Rotate icon overlay -->
            <div class="rotate-icon-overlay">
              <img src="@/assets/svgs/rotate-camera.svg" alt="Rotate" class="rotate-icon" />
            </div>
            <!-- Delete icon overlay -->
            <div class="delete-icon-overlay">
              <img src="@/assets/svgs/delete.svg" alt="Delete" class="delete-icon" />
            </div>
          </div>
          <div class="dz-details">
            <div class="dz-filename"><span data-dz-name></span></div>
            <div class="dz-size" data-dz-size></div>
          </div>
          <div class="dz-progress">
            <span class="dz-upload" data-dz-uploadprogress></span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group" id="story-container">
      <label class="heading-6" for="story-description">Tell us your story.</label>

      <input
        v-model="formData.story"
        type="text" 
        name="story-description" 
        placeholder="Start typing.." 
        id="story-description">
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
      <button type="button" class="primary-btn next-step">Next</button>
    </div>
  </div>
</template>

<script>
import { useRenterProfile } from "@/stores/renter/profile/useRenterProfile";
import { mapState, mapActions } from "pinia";
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';

export default {
  name: "RenterProfileStep10",
  data() {
    return {};
  },
  computed: {
    ...mapState(useRenterProfile, ['formData']),
  },
  mounted() {
    this.initializeDropzone();
  },
  methods: {
    ...mapActions(useRenterProfile, ['updateFormData', 'nextStep', 'prevStep']),

    initializeDropzone() {
      // Clear existing previews and destroy Dropzone instance
      const dropzoneElement = document.querySelector('#profile-file-upload');
      if (dropzoneElement) {
        dropzoneElement.innerHTML = '';
      }

      if (this.dropzone) {
        this.dropzone.destroy();
        this.dropzone = null;
      }

      const previewTemplate = document.querySelector('#preview-template').innerHTML;

      this.dropzone = new Dropzone('#profile-file-upload', {
        url: '#',
        maxFilesize: 100,
        maxFiles: 10,
        acceptedFiles: 'image/*',
        dictDefaultMessage: 'Drop your files here or click to browse',
        autoProcessQueue: false,
        previewTemplate: previewTemplate,
      });

      // Set initializing flag
      this.isInitializing = true;

      // Handle uploaded_photos (photos already uploaded to the server)
      this.addUploadedPhotosToDropzone();

      // Handle photos added by the user (not yet uploaded)
      if (this.formData.profile_picture && this.formData.profile_picture.length > 0) {
        // Exclude photos that are already uploaded
        const newPhotos = this.formData.profile_picture.filter((fileData) => !fileData.isUploaded);

        newPhotos.forEach((fileData) => {
          const dataURL = fileData.originalDataURL;

          if (!dataURL) {
            console.error('No originalDataURL found for file:', fileData.name);
            return; // Skip this file
          }

          // Convert Data URL back to Blob
          const byteString = atob(dataURL.split(',')[1]);
          const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ia], { type: mimeString });

          // Create a new File object using the blob data
          const file = new File([blob], fileData.name, {
            type: fileData.type,
            lastModified: fileData.lastModified,
          });

          file.uploadUuid = fileData.uploadUuid;
          file.rotation = fileData.rotation;
          file.originalDataURL = dataURL; // Use Data URL as originalData

          this.dropzone.emit('addedfile', file);
          this.dropzone.emit('thumbnail', file, dataURL);
          this.dropzone.emit('complete', file);

          // Attach event listeners to the file's preview element
          this.attachEventListeners(file);
        });
      }

      // Unset initializing flag
      this.isInitializing = false;

      // Update the store when files are added or removed
      this.dropzone.on('addedfile', (file) => {
        this.attachEventListeners(file);
      });

      this.dropzone.on('thumbnail', (file) => {
        if (!file.originalDataURL && !file.isUploaded) {
          file.originalDataURL = file.dataURL; // Set the original Data URL
          this.onFileAdded(file); // Now call onFileAdded
        }
      });

      this.dropzone.on('removedfile', (file) => {
        if (file.isUploaded) {
          this.onUploadedFileRemoved(file);
        } else {
          this.onFileRemoved(file);
        }
      });
    },

    addUploadedPhotosToDropzone() {
      if (this.formData.uploaded_photos && this.formData.uploaded_photos.length > 0) {
        this.formData.uploaded_photos.forEach((photoData) => {
          const { url, name, serverId } = photoData;

          // Create a mock File object (size and lastModified are arbitrary)
          const mockFile = {
            name: name,
            size: 123456, // You can provide actual size if available
            type: 'image/jpeg', // Adjust according to your file type
            uploadUuid: this.generateUUID(),
            isUploaded: true,
            serverId: serverId,
            accepted: true,
          };

          // Use Dropzone's 'addedfile' and 'thumbnail' events to add the file
          this.dropzone.emit('addedfile', mockFile);
          this.dropzone.emit('thumbnail', mockFile, url);
          this.dropzone.emit('complete', mockFile);

          // Attach event listeners to the file's preview element
          this.attachEventListeners(mockFile);

          // Update formData.photos
          const updatedPhotos = (this.formData.profile_picture || []).filter(
              (f) => f.uploadUuid !== mockFile.uploadUuid
          );

          const fileData = {
            uploadUuid: mockFile.uploadUuid,
            name: mockFile.name,
            lastModified: mockFile.lastModified || Date.now(),
            size: mockFile.size,
            type: mockFile.type,
            rotation: mockFile.rotation || 0,
            originalDataURL: url, // Use the URL from the server
            isUploaded: true, // Photo is already uploaded
            serverId: serverId, // Server ID of the photo
          };

          updatedPhotos.push(fileData);

          this.updateFormData({ photos: updatedPhotos });
        });
      }
    },

    refreshDropzonePreviews() {
      if (this.dropzone) {
        // Remove all files from Dropzone without triggering 'removedfile' event
        this.dropzone.removeAllFiles(true);

        // Re-add uploaded photos
        this.addUploadedPhotosToDropzone();

        // Handle any new photos (if necessary)
        // In this case, since photos are uploaded, formData.photos should be empty
      }
    },

    onFileAdded(file) {
      if (this.isInitializing) return;

      // Assign a unique identifier if not already assigned
      if (!file.uploadUuid) {
        file.uploadUuid = this.generateUUID();
      }

      // Remove any existing file with the same uploadUuid
      const updatedPhotos = (this.formData.profile_picture || []).filter(
          (f) => f.uploadUuid !== file.uploadUuid
      );

      // Store the file data, including the rotated image data
      const fileData = {
        uploadUuid: file.uploadUuid,
        name: file.name,
        lastModified: file.lastModified,
        size: file.size,
        type: file.type,
        rotation: file.rotation || 0,
        originalDataURL: file.originalDataURL, // Rotated image data as Data URL
        isUploaded: false, // Indicates the photo has not been uploaded yet
        serverId: null, // Will be set after successful upload
      };

      updatedPhotos.push(fileData);

      this.updateFormData({ photos: updatedPhotos });
    },

    attachEventListeners(file) {
      // Attach the rotate icon event
      const rotateIcon = file.previewElement.querySelector('.rotate-icon-overlay');
      if (rotateIcon) {
        rotateIcon.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();
          this.rotateImage(file);
        });
      }

      // Attach the delete icon event
      const deleteIcon = file.previewElement.querySelector('.delete-icon-overlay');
      if (deleteIcon) {
        deleteIcon.addEventListener('click', async (event) => {
          event.preventDefault();
          event.stopPropagation();

          // Optional: Confirm deletion with the user
          const confirmed = confirm('Are you sure you want to delete this photo?');
          if (!confirmed) return;

          // Remove the file from Dropzone
          this.dropzone.removeFile(file);

          if (file.isUploaded) {
            await this.onUploadedFileRemoved(file);
          } else {
            this.onFileRemoved(file);
          }
        });
      }
    },

    async onUploadedFileRemoved(file) {
      // Remove from uploaded_photos
      this.formData.uploaded_photos = this.formData.uploaded_photos.filter(
          (photo) => photo.serverId !== file.serverId
      );

      // Remove from formData.photos
      this.formData.profile_picture = this.formData.profile_picture.filter(
          (photo) => photo.uploadUuid !== file.uploadUuid
      );

      // Update formData
      this.updateFormData({
        uploaded_photos: this.formData.uploaded_photos,
        photos: this.formData.profile_picture,
      });

      // Optionally, save the draft to update the backend
      await this.saveDraft(this.navigationState);
    },

    onFileRemoved(file) {
      // Remove the file from the formData store
      const updatedPhotos = (this.formData.profile_picture || []).filter(
          (f) => f.uploadUuid !== file.uploadUuid
      );
      this.updateFormData({ photos: updatedPhotos });
    },

    rotateImage(file) {
      if (typeof file.rotation === 'undefined') {
        file.rotation = 0;
        // file.originalDataURL should already be set
      }

      // Increment rotation by 90 degrees
      file.rotation = (file.rotation + 90) % 360;

      const img = new Image();

      // Add crossOrigin attribute if loading from URL
      if (file.isUploaded) {
        img.crossOrigin = 'anonymous';
      }

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set canvas dimensions based on rotation
        if (file.rotation % 180 === 0) {
          canvas.width = img.width;
          canvas.height = img.height;
        } else {
          canvas.width = img.height;
          canvas.height = img.width;
        }

        // Move the origin to the center
        ctx.translate(canvas.width / 2, canvas.height / 2);

        // Rotate the canvas
        ctx.rotate((file.rotation * Math.PI) / 180);

        // Draw the image
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        // Convert the canvas to a Data URL
        const dataURL = canvas.toDataURL(file.type);
        file.originalDataURL = dataURL; // Store the rotated Data URL

        // Update the thumbnail
        const thumbnailElement = file.previewElement.querySelector('img[data-dz-thumbnail]');
        if (thumbnailElement) {
          thumbnailElement.src = dataURL;
        }

        // After rotating the image
        file.isUploaded = false; // Mark the file as not uploaded
        file.serverId = null; // Clear the serverId since the file has changed

        // Update the file in formData.photos
        this.onFileRemoved(file);
        this.onFileAdded(file);

        // Clean up
        URL.revokeObjectURL(img.src);
      };

      img.onerror = (error) => {
        console.error('Error loading image for rotation:', error);
        alert('Unable to rotate image.');
      };

      // Load the image from the current originalDataURL
      img.src = file.originalDataURL;
    },

    generateUUID() {
      return 'xxxx-xxxx-xxxx-xxxx'.replace(/[x]/g, function() {
        return ((Math.random() * 16) | 0).toString(16);
      });
    },
  }
};
</script>

<style scoped>
/* Styles for the image container */
.dz-preview .dz-image {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

/* Styles for the preview image */
.dz-preview .dz-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Rotate icon overlay */
.rotate-icon-overlay {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10; /* Ensure it's above other elements */
}
.rotate-icon-overlay img {
  cursor: pointer;
}
/* Style for the rotate icon image */
.rotate-icon-overlay .rotate-icon {
  width: 24px !important;
  height: 24px !important;
}

/* Adjust the hover overlay */
.dz-preview .dz-details {
  pointer-events: none; /* Clicks pass through */
  z-index: 1; /* Below the rotate icon */
}

/* Optional: Style the dz-details overlay if needed */
.dz-preview .dz-details {
  display: none;
}

.dz-preview .dz-details {
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
}
.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(0px);
}
.dz-progress {
  display: none;
}
.dropzone .dz-preview.dz-image-preview {
  background: none !important;
}

/* Delete icon overlay */
.delete-icon-overlay {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10; /* Ensure it's above other elements */
}

.delete-icon-overlay img {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

/* Adjust positioning if necessary */
.dz-preview .dz-image {
  position: relative;
}

/* Optional: Add hover effects */
.delete-icon-overlay:hover img {
  opacity: 0.8;
}
.delete-icon {
  width:18px !important;
  height:18px !important;
}

</style>