<template>
  <Step1 v-if="step === 1" />
  <Step2 v-if="step === 2" />
  <Step3 v-if="step === 3" />
  <Step4 v-if="step === 4" />
  <Step5 v-if="step === 5" />
</template>


<script>
import { useRenterVerification } from "@/stores/renter/verification/useRenterVerification";
import { mapState } from "pinia";

import Step1 from "@/views/renter/verification/steps/Step1.vue";
import Step2 from "@/views/renter/verification/steps/Step2.vue";
import Step3 from "@/views/renter/verification/steps/Step3.vue";
import Step4 from "@/views/renter/verification/steps/Step4.vue";
import Step5 from "@/views/renter/verification/steps/Step5.vue";

export default {
  name: "RenterVerificationSteps",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  computed: {
    ...mapState(useRenterVerification, ['step'])
  },
};
</script>