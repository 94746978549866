import { useValidation } from '@/stores/shared/useValidation';
import { defineStore } from 'pinia';

export const useRenterVerification = defineStore('verification', {
    state: () => ({
        step: 1,
        maxSteps: 5,
        personal_info: {
            email: '',
            first_name: '',
            last_name: '',
            phone_number: '',
            date_of_birth: '',
            gender: '',

            same_as_listing: false,
            listing_address: '600 Woodbine, Toronto, ON T5T53G',

            address: '',
            city: '',
            province: '',
            postal_code: '',

            additional_address: [],
        },
        gender_options: {
            male: 'Male',
            female: 'Female',
            other: 'Other',
        },
        errors: {},
        document_type: '',
    }),
    getters: {
        hasDocumentType(){
            const validate = useValidation();
            return validate.hasValue(this.document_type);
        },
    },
    actions: {
        resetStep(){
            this.step = 1;
        },
        nextStep(){
            const nextStep = this.step + 1;
            if(nextStep > this.maxSteps) return;

            this.step = nextStep;
        },
        prevStep(){
            const nextStep = this.step - 1;
            if(nextStep < 1) return;

            this.step = nextStep;
        },
        getGenderText(key){
            return this.gender_options[key];
        },
        addAdditionalAddress(){
            this.personal_info.additional_address.push({
                address: '',
                city: '',
                province: '',
                postal_code: '',
                country: '',
            });
        },
        hasAddress(){
            const validate = useValidation();

            return (
                validate.hasValue(this.personal_info.address)
                && validate.hasValue(this.personal_info.city)
                && validate.hasValue(this.personal_info.province)
                && validate.hasValue(this.personal_info.postal_code)
            );
        },
    
        validateAll(){
            const validate = useValidation();
            this.errors = {};

            //Email address
            if(!validate.hasValue(this.personal_info.email)){
                this.errors.email = ['Email address is required'];
            }
            else if(!validate.email(this.personal_info.email)){
                this.errors.email = ['Invalid email address'];
            }

            //First name
            if(!validate.hasValue(this.personal_info.first_name)){
                this.errors.first_name = ['Legal first name is required'];
            }
            else if(!validate.name(this.personal_info.first_name)){
                this.errors.first_name = ['Invalid legal first name'];
            }

            //Last name
            if(!validate.hasValue(this.personal_info.last_name)){
                this.errors.last_name = ['Legal last name is required'];
            }
            else if(!validate.name(this.personal_info.last_name)){
                this.errors.last_name = ['Invalid legal last name'];
            }

            //Phone number
            if(!validate.hasValue(this.personal_info.phone_number)){
                this.errors.phone_number = ['Phone number is required'];
            }
            else if(!validate.phone(this.personal_info.phone_number)){
                this.errors.phone_number = ['Invalid phone number'];
            }

            //Date of birth
            if(!validate.hasValue(this.personal_info.date_of_birth)){
                this.errors.date_of_birth = ['Date of birth is required'];
            }
            else if(!validate.dateFormatDDMMYYYY(this.personal_info.date_of_birth)){
                this.errors.date_of_birth = ['Invalid date of birth'];
            }
            else if(!validate.datePastDDMMYYYY(this.personal_info.date_of_birth)){
                this.errors.date_of_birth = ['Date of birth must be in the past'];
            }

            //Gender
            if(!validate.hasValue(this.personal_info.gender)){
                this.errors.gender = ['Gender is required'];
            }

            //Current address
            if(!this.personal_info.same_as_listing && !this.hasAddress()){
                this.errors.current_address = ['Current address is required'];
            }

            return Object.keys(this.errors).length === 0;
        },
        changeDocumentType(value){
            this.document_type = value;
        }
    }
});