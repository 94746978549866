<template>
  <RenterDashboardTopNav />

  <div class="renter-dashboard">
    <div class="main-dashboard container flex-container">
      <RenterDashboardSidebar />

      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-2">Dashboard</h1>

          <div>
            <p>Your next steps</p>
            <span v-if="formData.steps.length > 0" class="notification-badge" aria-hidden="true">
              <strong>{{ formData.steps.length }}</strong>
            </span>
          </div>
        </header>

        <div class="cards-container flex-container">
          <section>
            <router-link  class="card" v-for="(step, index) in formData.steps" :key="index" :to="getCardLink(step.type)">
              <div class="card-left-col">
                <img :src="getCardIcon(step.status)" :alt="getCardAltText(step.status)" />

                <div class="card-content">
                  <p><strong>{{ capitalize(step.status) }}</strong></p>

                  <p class="mt-2">
                    <a>{{ getCardText(step.type) }}</a> before the {{ isoToFormattedDayMonth(step.date) }}.
                  </p>
                </div>
              </div>
            </router-link>
          </section>
        </div>
      </div>

      <RenterDashboardBottomNav />
    </div>
  </div>
</template>

<script>
import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";
import { useRenterDashboard } from "@/stores/renter/useRenterDashboard";
import { mapState } from "pinia";

export default {
  name: "RenterDashboard",

  components: {
    RenterDashboardSidebar,
    RenterDashboardBottomNav,
    RenterDashboardTopNav
  },
  data() {
    return {
      cards: [
        {
          icon: "warning.svg",
          iconAlt: "Warning",
          text: "Pay monthly rent",
          link: "#",
        },
        {
          icon: "warning.svg",
          iconAlt: "Warning",
          text: "Upload your insurance",
          link: "#",
        },
      ],
    };
  },
  computed: {
    ...mapState(useRenterDashboard, ['formData'])
  },
  methods: {
    getAsset(filename) {
      return require(`@/assets/svgs/${filename}`);
    },
    getCardIcon(status) {
      const images = {
        required: require('@/assets/svgs/warning.svg'),
      };

      return images[status];
    },
    getCardAltText(status) {
      const text = {
        required: 'Warning',
      };

      return text[status];
    },
    getCardLink(type) {
      const links = {
        pay_rent: '/renter/mystay',
        upload_insurance: '/renter/insurance',
      };

      return links[type];
    },
    getCardText(type) {
      const links = {
        pay_rent: 'Pay monthly rent',
        upload_insurance: 'Upload your insurance',
      };

      return links[type];
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    isoToFormattedDayMonth(isoDate) {
      const splitDate = isoDate.split('-');
      const month = this.monthNumberToText(splitDate[1]);
      const day = parseInt(splitDate[2]);
      return `${day}${this.getOrdinalSuffix(day)} of ${month}`;
    },
    monthNumberToText(value) {
      const monthNumber = parseInt(value);

      switch (monthNumber) {
        case 1:
          return 'January';
        case 2:
          return 'February';
        case 3:
          return 'March';
        case 4:
          return 'April';
        case 5:
          return 'May';
        case 6:
          return 'June';
        case 7:
          return 'July';
        case 8:
          return 'August';
        case 9:
          return 'September';
        case 10:
          return 'October';
        case 11:
          return 'November';
        case 12:
          return 'December';
        default:
          return '';
      }
    },
    getOrdinalSuffix(number) {
      if (number > 3 && number < 21) return "th";

      switch (number % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }
  }
};
</script>