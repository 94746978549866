<template>
  <HostDashboardTopNav />

  <div class="renter-dashboard security-dashboard">
    <div class="main-dashboard container flex-container">
      <HostDashboardSidebar />

      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-2">Security</h1>
        </header>

        <div class="cards-container flex-container">
          <div v-if="newPassword.show" class="card">
            <h2 class="heading-6">Login</h2>

            <form action="">
              <div class="form-group">
                <div class="form-field">
                  <!-- needs the requiremnts for the password that exists in signup -->
                  <label for="password">New password</label>
                  <input :type="newPassword.showPlainText ? 'text' : 'password'" v-model="newPassword.password"
                    name="password" placeholder="Secure123?!?@" id="password" maxlength="30" required>
                  <a href="javascript:void(0)" class="toggle-password" @click="togglePasswordVisibility">
                    <img :src="newPassword.showPlainText ? hideIcon : showIcon"
                      :alt="newPassword.showPlainText ? 'Hide password' : 'Show password'">
                  </a>
                </div>
              </div>

              <div class="form-group mt-4">
                <input type="text" name="OTP" placeholder="enter OTP" v-model="newPassword.otp" />
              </div>

              <div class="form-group mt-5">
                <button class="primary-btn" @click="updatePassword">Update</button>
              </div>

              <div class="form-group">
                <div class="form-group default-checkbox mt-4" tabindex="0">
                  <input type="checkbox" id="keep-log-in" name="keep-log-in" v-model="newPassword.keepMeLoggedIn" />
                  <label for="keep-log-in">
                    Keep me logged in.
                  </label>
                </div>
              </div>
            </form>
          </div>

          <div v-if="!newPassword.show" class="card">
            <h2 class="heading-6">Login</h2>

            <div class="edit-info mt-4">
              <p>Password</p>

              <a href="javascript:void(0)" class="edit-link" @click="editPassword">
                <img src="@/assets/svgs/edit.svg" alt="edit">
                Edit
              </a>
            </div>

            <div class="info-note mt-5">
              <img src="@/assets/svgs/info.svg" alt="info" />
              <p>To change your password you are required to enter OTP sent to your email.</p>
            </div>

            <form action="">
              <div class="form-group">
                <div class="form-group default-checkbox mt-4" tabindex="0">
                  <input type="checkbox" id="keep-log-in-password-update" name="keep-log-in-password-update"
                    v-model="newPassword.keepMeLoggedIn" />
                  <label for="keep-log-in-password-update">
                    Keep me logged in.
                  </label>
                </div>
              </div>
            </form>
          </div>

          <div v-if="deleteAccount.show" class="card">
            <h2 class="heading-6">Account</h2>

            <form action="">
              <div class="form-group mt-5">
                <p><strong>What prompted you to delete?</strong></p>

                <div class="flex-container custom-checkbox-container mt-4" role="group"
                  aria-labelledby="deletion-reason">
                  <div class="checkbox-container">
                    <input type="checkbox" id="rented-room" value="I’ve rented a room"
                      @change="updateDeleteReasons('rented-room')">

                    <label for="rented-room">
                      I’ve rented a room
                    </label>
                  </div>

                  <div class="checkbox-container">
                    <input type="checkbox" id="not-needed" value="No longer need Happipad’s services"
                      @change="updateDeleteReasons('not-needed')">

                    <label for="not-needed">
                      No longer need Happipad’s services
                    </label>
                  </div>

                  <div class="checkbox-container">
                    <input type="checkbox" id="other" value="Other" @change="updateDeleteReasons('other')">

                    <label for="other">
                      Other
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group mt-5">
                <button class="primary-btn" @click="deleteAccountClick">Delete</button>
              </div>
            </form>
          </div>

          <div v-if="!deleteAccount.show" class="card">
            <h2 class="heading-6">Account</h2>

            <div class="edit-info mt-4 mb-3">
              <p>Delete your account</p>

              <a href="javascript:void(0)" class="edit-link" @click="openDeleteAccount">
                <img src="@/assets/svgs/delete-blue.svg" alt="delete">
                Delete
              </a>
            </div>
          </div>
        </div>
      </div>

      <HostDashboardBottomNav />
    </div>
  </div>
</template>


<script>
import HostDashboardSidebar from "@/components/HostDashboardSidebar.vue";
import HostDashboardBottomNav from "@/components/HostDashboardBottomNav.vue";
import HostDashboardTopNav from "@/components/HostDashboardTopNav.vue";
import { useDashboardSecurity } from "@/stores/shared/useDashboardSecurity";
import { mapState, mapActions } from "pinia";

export default {
  name: "RenterSecurity",

  components: {
    HostDashboardSidebar,
    HostDashboardBottomNav,
    HostDashboardTopNav
  },
  data() {
    return {
      passwordError: '',
      showIcon: require('@/assets/svgs/show-password.svg'), // Path to show icon
      hideIcon: require('@/assets/svgs/hide-password.svg'), // Path to hide icon
    };
  },
  computed: {
    ...mapState(useDashboardSecurity, ['newPassword', 'deleteAccount'])
  },
  methods: {
    ...mapActions(useDashboardSecurity, ['updateDeleteReasons']),

    // Toggle password visibility
    togglePasswordVisibility() {
      this.newPassword.showPlainText = !this.newPassword.showPlainText;
    },

    editPassword() {
      this.newPassword.show = !this.newPassword.show;
      //TODO: Send OTP
    },
    updatePassword() {
      this.newPassword.show = !this.newPassword.show;

      //TODO

      this.newPassword.password = '';
      this.newPassword.otp = '';
    },
    openDeleteAccount() {
      this.deleteAccount.show = !this.deleteAccount.show;
    },
    deleteAccountClick() {
      this.deleteAccount.show = !this.deleteAccount.show;
      //TODO
    },
  },
};
</script>